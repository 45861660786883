import { defineStore } from 'pinia'

import * as actions from './actions'
import * as getters from './getters'

import type { Actions, Getters, Name, State } from './types'
import { useToken } from '@/shared/composable'

export default defineStore<Name, State, Getters, Actions>('shared.useUser', {
  actions: {
    ...actions
  },

  getters: {
    ...getters
  },

  state ():State {
    return {
      user: null,
      isAuthenticated: useToken().hasToken(),

      messages: [],
      events: [],
      permissions: {
        assessment: [],
        individual: [],
        module: []
      },
      new_inbox_messages: [],
      verified_status: {
        id: 0,
        title: ''
      }
    }
  }
})
