<template>
  <div class="d-flex justify-content-between mb-3">
    <div class="d-flex align-items-center">
      <span class="fs-1 fw-bold">
        {{ $t('domains.editor.curricula.tabs.reference.title') }}
      </span>
    </div>
  </div>
  <pms-card
    v-if="emptyReferences"
    :header="false"
    class="mb-3"
  >
    <pms-no-data />
  </pms-card>
  <template
    v-for="(ref) in referenceTypes"
    :key="ref"
  >
    <pms-card
      v-if="curricula.references[ref].length > 0"
      class="mb-4"
      :title="`domains.editor.curricula.tabs.references-titles.${ref}-title`"
      :description="`domains.editor.curricula.tabs.references-titles.${ref}-description`"
      :options="{ body: {class: 'pt-2' }}"
      :expandable="true"
    >
      <pms-smart-table
        :headers="headers"
        :items="curricula.references[ref]"
      >
        <template #no="{idx}">
          {{ idx + 1 }}
        </template>
        <template #icon>
          <div class="text-center">
            <pms-kt-icon
              :icon-name="'arrow-mix'"
              class="text-success fs-1"
            />
          </div>
        </template>
        <template #reference="{ item }">
          {{ item.reference_code }} - {{ item.reference_title }}
        </template>
        <template #action>
          <div
            :data-bs-original-title="$t('domains.editor.curricula.readonly.disabled-click-edit-to-enable')"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-trigger="hover"
          >
            <pms-button
              :variant="'tonal'"
              :size="'sm'"
              :icon="'bi bi-chevron-down p-0 m-0'"
              :icon-placement="'end'"
              :text="'common.action'"
              :disabled="true"
            />
          </div>
        </template>
      </pms-smart-table>
    </pms-card>
  </template>
</template>
<script setup lang="ts">
import { PropType, computed } from 'vue'
import { referenceHeader as headers } from '../values'
import { AcademicSingleStudentCurriculumGetResponse } from '@/domains/AcademicSingleStudent/store/api/methods/getAcademicSingleStudentCurriculum/types'

const props = defineProps({
  curricula: {
    type: Object as PropType<AcademicSingleStudentCurriculumGetResponse[0]>,
    required: true
  },
  tab: {
    type: String,
    required: true
  }
})

const referenceTypes = [
  'one_to_one',
  'one_to_many',
  'many_to_one'
]

const emptyReferences = computed(() => {
  const empties: Array<boolean> = []

  for (const ref of referenceTypes) {
    props.curricula.references[ref].length === 0 && empties.push(true)
  }

  return empties.length === referenceTypes.length
})
</script>
