import { ServiceManageLockResourcesResponse } from '@/shared/swagger'
import { DepartmentsMapped } from '../types'

export default (departments: ServiceManageLockResourcesResponse['departments']| string[]): DepartmentsMapped[] => {
  const departmentMapped: DepartmentsMapped[] = []
  departments.forEach(department => {
    departmentMapped.push({
      id: department.department_id,
      label: department.title,
      key: department.department_code,
      selectable: false,
      children: departmentsToOption(department.children)
    })
  })
  return departmentMapped
}

const departmentsToOption = (departmentsChildren: ServiceManageLockResourcesResponse['departments']| string[]): DepartmentsMapped[] => {
  const departmentMapped: DepartmentsMapped[] = []
  departmentsChildren.forEach(department => {
    departmentMapped.push({
      id: department.department_id,
      label: department.title,
      key: department.department_code,
      selectable: true,
      children: departmentsToOption(department.children)
    })
  })
  return departmentMapped
}
