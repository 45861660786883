import { useRequests } from '@shared/composable'
import type { AxiosResponse } from '@shared/types'
import type {
  UnknownRequest
} from '@shared/swagger'
import SectionManagementSentRequestCoursesGetRequest from '@/domains/SectionManagement/features/SentRequests/store/api/types/SectionManagementSentRequestCoursesGetRequest'
import SectionManagementSentRequestCoursesGetResponse from '@/domains/SectionManagement/features/SentRequests/store/api/types/SectionManagementSentRequestCoursesGetResponse'

export default async function getSectionManagementRequests (params: SectionManagementSentRequestCoursesGetRequest): Promise<AxiosResponse<SectionManagementSentRequestCoursesGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, SectionManagementSentRequestCoursesGetResponse>({
    url: 'academic/manage/sections/requests',
    body: { params: params },
    loader: 'academic/manage/sections/requests'
  })

  return response
}
