import { router } from '@/router'
import type { StoreType } from '../types'
import { SignInResponse } from '@/shared/swagger'
import wrong_verifications from '@/shared/values/wrong_verifications'

export default async function setVerifiedStatus (this: StoreType, status: SignInResponse['verifiedStatus']) {
  this.verified_status = status
  localStorage.setItem('verified_status', JSON.stringify(status))
  if (wrong_verifications.includes(this.verified_status.id)) {
    router.push({ name: 'SettingsPasswordPage' })
    // disable all routes except SettingsPasswordPage
    // store it in a router guard so that I can reenable them later
    this.routerGuard = router.beforeEach((to, from, next) => {
      const route_name = 'SettingsPasswordPage'
      if (to.name !== route_name &&
        !['SignOutPage', 'SignInPage'].includes((to.name as string).toString())
      ) {
        return next({ name: route_name })
      } else {
        return next()
      }
    })
  }
}
