<template>
  <div
    id="change-password"
    class="col-12"
  >
    <div
      v-if="is_user_verified"
      class="d-flex align-items-center justify-content-between"
    >
      <span class="text-dark fw-bold fs-2 pb-0">
        {{ $t('domains.profile.settings.user-card.header') }}
      </span>
    </div>
    <pms-card
      v-else
      :header="false"
      class="position-relative border border-warning bg-light-warning mt-4 min-height"
    >
      <i class="position-absolute bi bi-exclamation-circle text-warning fs-big" />
      <div class="d-flex justify-content-center text-center">
        <div class="w-75">
          {{ userStore.getVerifiedStatus.title }}
        </div>
      </div>
    </pms-card>
    <pms-card
      :header="false"
      class="mt-4 pb-5"
      :options="{
        body: {
          class: 'pb-3'
        }
      }"
    >
      <div
        class="flex-row-fluid mt-4"
      >
        <PasswordModes />
        <pms-smart-form
          :fields="[formFields]"
          @handleSubmit="onSubmit"
        >
          <template #formControls>
            <div class="d-flex justify-content-end mb-3 mt-6">
              <button
                class="btn btn-light btn-sm btn-active-light-primary me-2"
                type="button"
                @click="discardChanges()"
              >
                {{ $t('common.discard') }}
              </button>
              <button
                class="btn btn-primary btn-sm"
                type="submit"
              >
                {{ !passwordLoader ? $t('phrases.save-changes') : $t('phrases.saving') }}
                <span
                  v-if="passwordLoader"
                  class="spinner-border spinner-border-sm align-middle ms-2"
                />
              </button>
            </div>
          </template>
        </pms-smart-form>
      </div>
    </pms-card>
  </div>
</template>
<script setup lang="ts">
import { useI18n } from 'vue-i18n'

import { useLoader, useAlert } from '@shared/composable'
import { FormField } from '@shared/types'

import { useProfile } from '../../../store'
import { passwordMode } from '../values'

import PasswordModes from './PasswordModes.vue'
import { useRouter } from 'vue-router'
import { useUser } from '@/shared/stores'
import { is_user_verified } from '@/domains/Profile/values'

const { t } = useI18n()
const { isActive } = useLoader()
const router = useRouter()
const { error, success } = useAlert()

const userStore = useUser()

const store = useProfile()

const passwordLoader = isActive('profile/settings/passwords-edit')

const discardChanges = () => {
  passwordMode.value = 'system'
}

const onSubmit = async ({ values }) => {
  if (!values.current_password) {
    error({ text: 'domains.authenticator.form.current-password-error' })
    return
  }
  if (!values.new_password) {
    error({ text: 'domains.authenticator.form.new-password-error' })
    return
  }
  if (!values.confirm_password) {
    error({ text: 'domains.authenticator.form.confirm-password-error' })
    return
  }
  const { status, message } = await store.editPassword({ reset: passwordMode.value, ...values })

  if (status) {
    success({ text: t('domains.profile.settings.password-success') })

    if (passwordMode.value === 'system') {
      if (userStore.routerGuard) {
      // re-enable all routes that were disabled when verification status was wrong
        userStore.routerGuard()
      }
      router.push({ path: '/auth/sign-out' })
    }
  } else error({ text: message })
}

const formFields: Array<FormField> = [
  {
    type: 'password',
    name: 'current_password',
    group: {
      class: 'mb-4'
    },
    label: {
      text: t('domains.authenticator.form.current-password'),
      class: 'fs-6 fw-semibold text-dark mb-1'
    },
    binding: {
      autocomplete: 'current-password'
    },
    placeholder: t('domains.authenticator.form.current-password-placeholder'),
    validator: {
      schema: 'yup',
      rules: [
        'string',
        'required',
        { func: 'label', value: t('domains.authenticator.form.current-password') },
        { func: 'min', value: 6 },
        { func: 'max', value: 30 }
      ]
    }
  },
  {
    type: 'password',
    name: 'new_password',
    group: {
      class: 'mb-4'
    },
    label: {
      text: t('domains.authenticator.form.new-password'),
      class: 'fs-6 fw-semibold text-dark mb-1'
    },
    binding: {
      autocomplete: 'new-password'
    },
    placeholder: t('domains.authenticator.form.new-password-placeholder'),
    validator: {
      schema: 'yup',
      rules: [
        'string',
        'required',
        { func: 'label', value: t('domains.authenticator.form.new-password') },
        { func: 'min', value: 6 },
        { func: 'max', value: 30 }
      ]
    }
  },
  {
    type: 'password',
    name: 'confirm_password',
    group: {
      class: 'mb-4'
    },
    label: {
      text: t('domains.authenticator.form.confirm-password'),
      class: 'fs-6 fw-semibold text-dark mb-1'
    },
    binding: {
      autocomplete: 'confirm-password'
    },
    placeholder: t('domains.authenticator.form.confirm-password-placeholder'),
    validator: {
      schema: 'yup',
      rules: [
        'string',
        'required',
        { func: 'label', value: t('domains.authenticator.form.confirm-password') },
        { func: 'min', value: 6 },
        { func: 'max', value: 30 }
      ]
    }
  }
]
</script>
<style scoped>
.fs-big{
  font-size: 4em;
}
.min-height{
  min-height: 8em;
}
</style>
