import ServiceAdvisorAssignmentStudentsGetRequest from '@/domains/AdvisorAssignment/store/api/types/ServiceAdvisorAssignmentStudentsGetRequest'
import { useRequests } from '@/shared/composable'

import type {
  UnknownRequest
} from '@/shared/swagger'
import { ServiceAdvisorAssignmentStudentsGetResponse } from '@/shared/swagger/entities/AdvisoryAssignment/Response'
import type { AxiosResponse } from '@shared/types'

export default async function getServiceAdvisorAssignmentStudents (params: ServiceAdvisorAssignmentStudentsGetRequest): Promise<AxiosResponse<ServiceAdvisorAssignmentStudentsGetResponse>> {
  const { get } = useRequests()

  const response = get<UnknownRequest, ServiceAdvisorAssignmentStudentsGetResponse>({
    url: 'service/manage/advisors/students',
    body: { params },
    loader: 'get-service/manage/advisors/students'
  })

  return response
}
