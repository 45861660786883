<template>
  <BackToRequestBlock class="mb-5" />
  <ProgramDetails />
  <SuggestedCoursesTable v-if="newRequestStore.isFetched" />
</template>

<script setup lang="ts">
import ProgramDetails from '@domains/SectionManagement/features/NewRequest/ui/ProgramDetails.vue'
import BackToRequestBlock from '@domains/SectionManagement/features/NewRequest/ui/BackToRequestBlock.vue'

import { useNewRequest } from '@domains/SectionManagement/features/NewRequest/store'
import { onMounted } from 'vue'
import SuggestedCoursesTable from '@domains/SectionManagement/features/NewRequest/ui/SuggestedCoursesTable/SuggestedCoursesTable.vue'
import { useSectionManagement } from '@/domains/SectionManagement/store'

const newRequestStore = useNewRequest()
const sectionManagementStore = useSectionManagement()

onMounted(async () => {
  newRequestStore.isFetched = true
  if (!newRequestStore.selectedProgram) return
  await newRequestStore.fetchSectionManagementNewRequestSuggestedCourses({
    program_code: newRequestStore.selectedProgram.code,
    year: sectionManagementStore.currentYearTerm.year,
    term: sectionManagementStore.currentYearTerm.term
  })
})
</script>
