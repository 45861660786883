import type { StoreType } from '../types'
import { getSectionManagementCoursesStatistics } from '@shared/api'
import SectionManagementCourseSectionStatisticsGetRequest from '../api/types/SectionManagementCourseSectionStatisticsGetRequest'

export default async function fetchCourseStatistics (this: StoreType, params: SectionManagementCourseSectionStatisticsGetRequest): Promise<void> {
  const { data, ok } = await getSectionManagementCoursesStatistics(params)

  if (ok) {
    this.setCourseStatistics(data)
  } else {
    this.setCourseStatistics(null)
  }
}
