<template>
  <div
    v-if="curriculums"
  >
    <Tabs :value="curriculums[0]?.information.program.code">
      <TabList>
        <Tab
          v-for="(curriculum) in curriculums"
          :key="curriculum.information.program.code"
          :value="curriculum.information.program.code"
        >
          {{ curriculum.information.program.title }}
        </Tab>
      </TabList>
      <TabPanels>
        <TabPanel
          v-for="(curriculum) in curriculums"
          :key="curriculum.information.program.code"
          :value="curriculum.information.program.code"
        >
          <ReadonlyView :curriculum="curriculum" />
          <p class="m-0">
            {{ curriculum.information.program.title }}
          </p>
        </TabPanel>
      </TabPanels>
    </Tabs>
  </div>
  <template v-else>
    <pms-card :header="false">
      <pms-no-data />
    </pms-card>
  </template>
</template>

<script setup lang="ts">
import Tabs from 'primevue/tabs'
import TabList from 'primevue/tablist'
import Tab from 'primevue/tab'
import TabPanels from 'primevue/tabpanels'
import TabPanel from 'primevue/tabpanel'

import { useSingleStudent } from '@/domains/AcademicSingleStudent/store'
import { storeToRefs } from 'pinia'
import ReadonlyView from '../ReadonlyView.vue'

const store = useSingleStudent()
const { curriculums } = storeToRefs(store)
</script>
