import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type { UnknownRequest } from '@shared/swagger'
import { ServiceQuestionaryDepartmentsGetResponse } from './types'

/**
 * API /1.0.0/service/questionaries/departments
 * @param void
 * @returns ServiceQuestionaryDepartmentsGetResponse
 */
export default async function getDeparments (): Promise<AxiosResponse<ServiceQuestionaryDepartmentsGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, ServiceQuestionaryDepartmentsGetResponse>({
    url: 'service/questionaries/departments',
    body: {},
    loader: 'api-service/questionaries/departments'
  })

  return response
}
