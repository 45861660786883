<template>
  <pms-card :title="$t('domains.survey.questions.filter.title')">
    <pms-smart-form
      :fields="formFields"
      @handleSubmit="onSubmit"
      @handleChange="onHandleChange"
    >
      <template #department_code>
        <div
          v-if="showDepartmentCodeSelect"
          class="card"
        >
          <span class="mb-2 mt-1"> {{ t('common.department') }}:</span>
          <TreeSelect
            v-model="selectedDepartment"
            :options="departmentOptions"
            placeholder=""
            class="p-1"
          />
        </div>
      </template>
    </pms-smart-form>
  </pms-card>
</template>
<script lang="ts" setup>
import TreeSelect from 'primevue/treeselect'
import useFilterFormModel from './composable/useFilterFormModel'
import { useI18n } from 'vue-i18n'

const { departmentOptions, formFields, onSubmit, selectedDepartment, onHandleChange, showDepartmentCodeSelect } = useFilterFormModel()
const { t } = useI18n()
</script>
