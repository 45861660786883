import { computed } from 'vue'
import type { FormField } from '@shared/types'
import { useAlert, useTeleportModal } from '@shared/composable'
import { useI18n } from 'vue-i18n'
import { TEditCourseFormModalData } from '../types'
import { useMyCourses } from '@/domains/SectionManagement/features/MyCourses/store'
import { storeToRefs } from 'pinia'
import UseCoursesFetcher from '@/domains/SectionManagement/features/MyCourses/ui/CoursesFilter/composable/UseCoursesFetcher/UseCoursesFetcher'

export default function useEditCourseOffer () {
  const { t } = useI18n()
  const { error, success } = useAlert()
  const { close, modifier, unsetModifier } = useTeleportModal()
  const modalData = (modifier.value?.data as TEditCourseFormModalData)

  const myCoursesStore = useMyCourses()
  const { courses } = storeToRefs(myCoursesStore)
  const { fetchCourses } = UseCoursesFetcher()

  const course = computed(() => {
    const course = courses.value.find(i => i.offer_id === modalData.offer_id)
    return course || null
  })

  const formFields = computed<Array<FormField>>(() => [
    {
      group: {
        class: 'fv-row mb-4 fv-plugins-icon-container'
      },
      label: {
        text: `${t('domains.academic.manage.sections.requested-course-note')}`,
        class: 'fs-6 fw-semibold mb-2'
      },
      name: 'note',
      type: 'textarea',
      value: course.value?.note
    }
  ])

  const onSubmit = async ({ values }: {values: {note: string}}) => {
    if (!course.value) return

    const { status, message } = await myCoursesStore.editCourseOffer(course.value.offer_id, {
      note: values.note
    })
    if (status) {
      success({ text: message })
      if (myCoursesStore.selectedDepartment) { fetchCourses({ departmentCode: myCoursesStore.selectedDepartment.code }) }
    } else {
      error({ text: message })
    }
  }

  const closeModal = () => {
    close({ name: 'my-courses-modal' })
    unsetModifier()
  }
  return {
    formFields,
    onSubmit,
    closeModal
  }
}
