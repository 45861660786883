import type { StoreType } from '../types'
import type { StatusMessageResponse } from '@shared/types'
import { postSectionManagementOffer } from '@shared/api'
import SectionManagementCreateOfferPostRequest from '../api/types/SectionManagementCreateOfferPostRequest'

export default async function createCourseOffer (this: StoreType, params: SectionManagementCreateOfferPostRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await postSectionManagementOffer(params)

  return {
    status: ok,
    message
  }
}
