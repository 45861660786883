<template>
  <pms-grid
    :col="12"
    class="d-flex gap-5 flex-column"
  >
    <pms-card
      :title="'domains.schedule-management.course-search-title'"
      :description="'domains.schedule-management.course-search-description'"
      :options="{ description: { class: 'mt-1 fs-7 text-muted' }, body: { class: 'pt-0'}, header: {class: 'mt-2'}}"
    >
      <pms-loader v-if="resourceLoader" />
      <pms-smart-form
        v-else
        :fields="formFields"
        @handleSubmit="onSubmit"
        @handleChange="onHandleChange"
      >
        <template #programCode="{ setValue }">
          <label
            class="mb-3 required"
          >{{ $t('common.programs') }}:</label>
          <treeselect
            v-model="selectedProgramCode"
            :multiple="false"
            :options="programs"
            :disable-branch-nodes="true"
            placeholder=""
            class="styled"
            @select="(value)=>setValue(value.id)"
          />
        </template>
        <template #formControls>
          <div class="form-group mt-4">
            <pms-button
              type="submit"
              :loader="searchLoader"
              :text="$t('common.search')"
            />
          </div>
        </template>
      </pms-smart-form>
    </pms-card>
  </pms-grid>
</template>
<script setup lang="ts">
import { ref, computed, onMounted, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { getYearTermLabel } from '@/shared/utils'
import type { FormGroup, SelectField } from '@shared/types'
import { useLoader, usePagination } from '@shared/composable'
import { useScheduleManagement } from '@domains/ScheduleManagement/store'
import { useResource } from '@shared/stores'
import Treeselect from 'vue3-treeselect'

const resource = useResource()

const { t } = useI18n()
const { isActive } = useLoader()

const resourceLoader = isActive('')
const searchLoader = isActive('academic/manage/schedules/sections')

const store = useScheduleManagement()
const pagination = usePagination()

const selectedProgramCode = ref<number>()

const tempValues = ref<{
  yearTerm: null | number
  programCode: null | number
  courseCode: null | string
  section: null | string
}>({
  yearTerm: null,
  programCode: null,
  courseCode: null,
  section: null
})

onMounted(() => {
  if (store.formValues?.yearTerm) tempValues.value.yearTerm = +store.formValues?.yearTerm
  if (store.formValues?.programCode) tempValues.value.programCode = store.formValues?.programCode
})

const yearTerms = computed<SelectField['options']>(() => {
  return resource.getResourceYearTerms.map(yearTerm => {
    return {
      label: getYearTermLabel(yearTerm, t),
      value: yearTerm.id
    }
  })
})

const programs = computed(() => {
  const programs = store.getPrograms
  const faculties:Record<string, typeof programs> = {}
  programs.forEach(prog => {
    if (!faculties[prog.faculty.title]) {
      faculties[prog.faculty.title] = [prog]
    } else {
      faculties[prog.faculty.title].push(prog)
    }
  })
  return Object.keys(faculties).map(faculty => ({
    id: faculty,
    label: faculty,
    children: faculties[faculty].map(program => ({
      label: `${program.code} - ${program.language.code} : ${program.cipher ?? ''} ${program.title} [${program.terms}]`,
      id: program.code
    }))
  }))
})

const formFields = computed(():Array<FormGroup> => [
  {
    class: 'row mb-3',
    fields: [
      {
        group: {
          class: 'col-12 col-md-2'
        },
        label: {
          text: `${t('common.year-terms')}:`,
          class: 'mb-1 mt-2 required'
        },
        required: true,
        name: 'yearTerm',
        type: 'select',
        value: tempValues.value.yearTerm || null,
        options: yearTerms.value
      },
      {
        group: {
          class: 'col-12 col-md-6'
        },
        name: 'programCode',
        type: 'slot'
      },
      {
        group: {
          class: 'col-12 col-md-3'
        },
        label: {
          text: `${t('common.course-code')}:`,
          class: 'mb-1 mt-2'
        },
        name: 'courseCode',
        type: 'text',
        value: tempValues.value.courseCode || null
      },
      {
        group: {
          class: 'col-12 col-md-1'
        },
        label: {
          text: `${t('common.section')}:`,
          class: 'mb-1 mt-2'
        },
        name: 'section',
        type: 'text',
        value: tempValues.value.section || null
      }
    ]
  }
])

const onHandleChange = ({ name, newValue }) => {
  if (!store.formValues) store.formValues = {}
  store.formValues[name] = newValue
  tempValues.value[name] = newValue
}

const onSubmit = ({ values }) => {
  const yearTerm = resource.getResourceYearTerms.find((yearTerm) => +yearTerm.id === +values.yearTerm)
  if (!yearTerm) return
  store.fetchSections({
    program_code: selectedProgramCode.value,
    course_code: values.courseCode ? values.courseCode.trim() : undefined,
    section: values.section ? values.section.trim() : undefined,
    year: yearTerm.year,
    term: yearTerm.term,
    per_page: pagination.perPage.value,
    offset: 1
  })
}
</script>
