<template>
  <div>
    <div
      :key="'information.programs-on-available'"
      class="fs-2 fw-bold text-danger text-center mb-6 mt-10"
    >
      {{ getMessage('information.programs-on-available') }}
    </div>
  </div>

  <pms-smart-table
    :headers="programsTable"
    :items="programs"
    :sortable="true"
    :options="{ design: 'bordered-dashed' }"
  />
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { getMessage } from '../utils'

import { programsTable } from '../values'
import { useStore } from '../features/EditorSyllabus/store'

const { programs } = storeToRefs(useStore())
</script>
