import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type { UnknownResponse } from '@shared/swagger'
import { ServiceManageLanguagesStudentsBulkPutRequest } from './type'

export default async function putServiceManageLanguagesStudents (params: ServiceManageLanguagesStudentsBulkPutRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { put } = useRequests()

  const response = await put<ServiceManageLanguagesStudentsBulkPutRequest, UnknownResponse>({
    url: 'service/manage/languages/students/bulk',
    body: params,
    loader: 'service/manage/languages/students[put]'
  })

  return response
}
