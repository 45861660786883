import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type {
  UnknownRequest
} from '@shared/swagger'
import SectionManagementReceivedRequestCoursesGetRequest from '@/domains/SectionManagement/features/ReceivedRequests/store/api/types/SectionManagementReceivedRequestCoursesGetRequest'
import SectionManagementReceivedRequestCoursesGetResponse from '@/domains/SectionManagement/features/ReceivedRequests/store/api/types/SectionManagementReceivedRequestCoursesGetResponse'

/**
 * Path api /1.0.0/academic/manage/sections/courses
 */
export default async function getSectionManagementReceivedRequestCourses (params: SectionManagementReceivedRequestCoursesGetRequest): Promise<AxiosResponse<SectionManagementReceivedRequestCoursesGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, SectionManagementReceivedRequestCoursesGetResponse>({
    url: 'academic/manage/sections/courses',
    body: { params: params },
    loader: 'get-academic/manage/sections/courses'
  })

  return response
}
