import { useUser } from '@/shared/stores'
import { AxiosResponse } from '@/shared/types'
import type { StoreType } from '../types'
import { ForgotPasswordPostRequest, ForgotPasswordPostResponse } from '../api/methods/forgotPassword/types'

export default async function forgotPassword (this: StoreType, credentials: ForgotPasswordPostRequest): Promise<AxiosResponse<ForgotPasswordPostResponse>> {
  const user = useUser()

  return await user.forgotPassword(credentials)
}
