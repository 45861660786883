import type { StoreType } from '../types'

import { useAlert, usePagination } from '@shared/composable'
import { StatusMessageResponse } from '@/shared/types'
import { ServiceManageLanguagesStudentsGetRequest } from '../methods/getStudents/type'
import getServiceManageLanguagesStudents from '../methods/getStudents'

export default async function fetchStudents (this: StoreType, params: ServiceManageLanguagesStudentsGetRequest): Promise<StatusMessageResponse> {
  const { error } = useAlert()
  const { setPaginate, setTriggerFunction } = usePagination()

  setTriggerFunction(async (perPage: number, offset: number) => {
    const { ok, message, data } = await getServiceManageLanguagesStudents({ ...params, per_page: perPage, offset: offset })
    if (ok) {
      this.setStudents(data.students)
      setPaginate(data.per_page, data.current_page, data.last_page, data.total)
    } else {
      error({ text: message })
    }
  })

  const { ok, message, data } = await getServiceManageLanguagesStudents(params)
  if (ok) {
    this.setStudents(data.students)
    setPaginate(data.per_page, data.current_page, data.last_page, data.total)
  } else {
    error({ text: message })
  }

  return {
    status: ok,
    message: message
  }
}
