import { SmartTableHeader } from '@/shared/types'

export const programTableHeader: Array<SmartTableHeader> = [
  {
    title: 'common.table.no',
    field: 'no',
    slotCellName: 'no'
  },
  {
    title: 'common.table.code',
    slotCellName: 'department',
    field: 'department',
    cellClass: 'text-start',
    headerClass: 'text-start'
  },
  {
    title: 'common.table.new',
    field: 'new_count',
    cellClass: 'text-center',
    headerClass: 'text-center'
  },
  {
    title: 'common.table.met',
    field: 'met_count',
    cellClass: 'text-center',
    headerClass: 'text-center'
  },
  {
    title: 'common.table.reserved',
    field: 'reserved_count',
    cellClass: 'text-center',
    headerClass: 'text-center'
  },
  {
    title: 'common.table.rejected',
    field: 'rejected_count',
    cellClass: 'text-center',
    headerClass: 'text-center'
  },
  {
    title: 'common.table.action',
    field: 'action',
    slotCellName: 'action',
    cellClass: 'text-center',
    headerClass: 'text-center'
  }
]

export const courseTableHeader: Array<SmartTableHeader> = [
  {
    title: 'common.table.no',
    field: 'no',
    slotCellName: 'no'
  },
  {
    title: 'common.table.course-code',
    field: 'code',
    cellClass: 'text-center',
    headerClass: 'text-center'
  },
  {
    title: 'common.table.equicode',
    field: 'equicode'
  },
  {
    title: 'common.table.course-title',
    field: 'title',
    cellClass: 'text-center',
    headerClass: 'text-center'
  },
  {
    title: 'common.table.credits',
    field: 'credits',
    cellClass: 'text-center',
    headerClass: 'text-center'
  },
  {
    title: 'common.table.ects',
    field: 'ects',
    cellClass: 'text-center',
    headerClass: 'text-center'
  },
  {
    title: 'common.table.quota',
    field: 'quota_sum',
    cellClass: 'text-center',
    headerClass: 'text-center'
  },
  {
    title: 'common.table.new',
    field: 'new_count',
    cellClass: 'text-center',
    headerClass: 'text-center'
  },
  {
    title: 'common.table.met',
    field: 'met_count',
    cellClass: 'text-center',
    headerClass: 'text-center'
  },
  {
    title: 'common.table.reserved',
    field: 'reserved_count',
    cellClass: 'text-center',
    headerClass: 'text-center'
  },
  {
    title: 'common.table.rejected',
    field: 'reject_count',
    cellClass: 'text-center',
    headerClass: 'text-center'
  },
  {
    title: 'common.table.action',
    field: 'action',
    slotCellName: 'action',
    cellClass: 'text-center',
    headerClass: 'text-center'
  }
]

export const singleCourseTableHeader: Array<SmartTableHeader> = [
  {
    title: 'common.table.no',
    field: 'no',
    slotCellName: 'no'
  },
  {
    title: 'common.table.program',
    field: 'program',
    slotCellName: 'program',
    cellClass: 'text-center',
    headerClass: 'text-center'
  },
  {
    title: 'common.table.quota',
    field: 'quota',
    cellClass: 'text-center',
    headerClass: 'text-center'
  },
  {
    title: 'common.table.met',
    field: 'met',
    slotCellName: 'met',
    cellClass: 'text-center',
    headerClass: 'text-center'
  },
  {
    title: 'common.table.date',
    field: 'create_date',
    cellClass: 'text-center',
    headerClass: 'text-center',
    slotCellName: 'create_date'
  },
  {
    title: 'common.table.note',
    field: 'note',
    cellClass: 'text-center',
    headerClass: 'text-center',
    slotCellName: 'note'
  },
  {
    title: 'common.table.status',
    field: 'status',
    slotCellName: 'status',
    cellClass: 'text-center',
    headerClass: 'text-center'
  },
  {
    title: 'common.table.action',
    field: 'action',
    slotCellName: 'action',
    cellClass: 'text-center',
    headerClass: 'text-center'
  }
]

export const sectionsZoomTableHeader: Array<SmartTableHeader> = [
  {
    title: 'common.table.no',
    field: 'no',
    slotCellName: 'no'
  },
  {
    title: 'common.table.course-code',
    field: 'course_code',
    slotCellName: 'course_code',
    cellClass: 'text-center',
    headerClass: 'text-center'
  },
  {
    title: 'common.table.teacher',
    field: 'teacher',
    slotCellName: 'teacher',
    cellClass: 'text-center',
    headerClass: 'text-center'
  },
  {
    title: 'common.table.grade-submitter',
    field: 'grade_submitter',
    slotCellName: 'grade_submitter',
    cellClass: 'text-center',
    headerClass: 'text-center'
  },
  {
    title: 'common.table.type',
    field: 'type',
    slotCellName: 'type',
    slotName: 'typeHeader',
    cellClass: 'text-center',
    headerClass: 'text-center'
  },
  {
    title: 'common.table.note',
    field: 'note',
    cellClass: 'text-center',
    headerClass: 'text-center'
  },
  {
    title: 'common.table.students',
    field: 'students',
    slotCellName: 'students',
    cellClass: 'text-center',
    headerClass: 'text-center'
  },
  {
    title: 'common.table.action',
    field: 'action',
    slotCellName: 'action',
    cellClass: 'text-center',
    headerClass: 'text-center'
  }
]

export const sectionTable: Array<SmartTableHeader> = [
  {
    title: 'common.select',
    field: 'checkbox',
    slotCellName: 'checkbox'
  },
  {
    title: 'common.table.section',
    field: 'section_no',
    slotCellName: 'section'
  },
  {
    title: 'common.table.teacher',
    field: 'teacher',
    slotCellName: 'teacher'
  },
  {
    title: 'common.note',
    field: 'note',
    slotCellName: 'note'
  }
]

export const receivedZoomDetailsHeader: Array<SmartTableHeader> = [
  {
    title: 'common.table.no',
    field: 'no',
    slotCellName: 'no'
  },
  {
    title: 'common.table.program-code',
    field: 'program_code',
    slotCellName: 'programCode'
  },
  {
    title: 'common.table.course-code',
    field: 'course_code',
    slotCellName: 'courseCode'
  },
  {
    title: 'common.table.theory',
    field: 'theory',
    slotCellName: 'theory'
  },
  {
    title: 'common.table.practice',
    field: 'practice',
    slotCellName: 'practice'
  },
  {
    title: 'common.table.laboratory',
    field: 'laboratory',
    slotCellName: 'laboratory'
  },
  {
    title: 'common.table.met',
    field: 'met',
    slotCellName: 'met'
  },
  {
    title: 'common.table.students',
    field: 'students',
    slotCellName: 'students'
  },
  {
    title: 'common.table.action',
    field: 'action',
    slotCellName: 'action'
  }
]
