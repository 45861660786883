import { defineStore } from 'pinia'
import type { Name, State, Getters, Actions } from './types'

import * as actions from './actions'
import * as getters from './getters'

export const useNewRequest = defineStore<Name, State, Getters, Actions>('SectionManagement.features.NewRequest', {
  actions: {
    ...actions
  },

  getters: {
    ...getters
  },

  state () {
    return {
      year: 0,
      term: 0,
      program_request_year: null,
      program_request_term: null,
      selectedProgram: null,
      requestType: null,
      isFetched: false,
      requestCourses: [],
      core_courses: [],
      elective_courses: [],
      program_request_type: null,
      requestedCourseStatisticResponse: null,
      selectedYearTerm: {},
      suggested_courses: [],
      search_courses: []
    }
  }
})
