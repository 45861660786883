/* eslint-disable camelcase */
import { StatusMessageResponse } from '@/shared/types'
import type { StoreType } from '../types'
import { getServiceAdvisorAssignmentStudents } from '@/shared/api/AdvisorAssignment'
import ServiceAdvisorAssignmentStudentsGetRequest from '../api/types/ServiceAdvisorAssignmentStudentsGetRequest'
import { usePagination } from '@/shared/composable'

export default async function fetchStudents (this: StoreType, payload: ServiceAdvisorAssignmentStudentsGetRequest): Promise<StatusMessageResponse> {
  const { setPaginate, setTriggerFunction } = usePagination()

  setTriggerFunction(async (perPage: number, currentPage: number) => {
    const { data, ok } = await getServiceAdvisorAssignmentStudents({ ...payload, per_page: perPage, offset: currentPage })

    if (ok) {
      this.setStudents(data.students)
      setPaginate(data.per_page, data.current_page, data.last_page, data.total)
    }
  })

  const { ok, data, message } = await getServiceAdvisorAssignmentStudents(payload)

  if (ok) {
    this.setStudents(data.students)
    setPaginate(data.per_page, data.current_page, data.last_page, data.total)
  }

  return {
    status: ok,
    message
  }
}
