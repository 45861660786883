import { useAlert } from '@/shared/composable'
import type { StoreType } from '../types'
import getAcademicSingleStudentTranscript from '../api/methods/getAcademicSingleStudentTranscript'

export default async function fetchTranscript (this: StoreType, values: string): Promise<void> {
  const { error } = useAlert()
  const { data, ok, message } = await getAcademicSingleStudentTranscript(values)

  if (ok) {
    this.setTranscript(data)
  } else {
    error({ text: message, isToast: true })
  }
}
