import type { StoreType } from '../types'
import { StatusMessageResponse } from '@/shared/types'
import SectionManagementNewRequestSearchCoursesGetRequest from '../api/types/SectionManagementNewRequestSearchCoursesGetRequest'
import getSectionManagementNewRequestSearchCourses from '@/shared/api/SectionManagement/getSectionManagementNewRequestSearchCourses'

export default async function fetchSectionManagementNewRequestSearchCourses (this: StoreType, params: SectionManagementNewRequestSearchCoursesGetRequest): Promise<StatusMessageResponse> {
  const { data, ok, message } = await getSectionManagementNewRequestSearchCourses(params)

  if (ok) {
    this.setSearchCourses(data)
  } else {
    this.setSearchCourses([])
  }
  return {
    status: ok,
    message: message
  }
}
