import type { StoreType } from '../types'

import { useResource } from '@shared/stores'
import { useToken } from '@shared/composable'

export default async function bootstrap (this: StoreType): Promise<void> {
  const year = useToken().getYear()
  const term = useToken().getTerm()
  const resource = useResource()

  Promise.all([
    this.setCurrentYearTerm({ year, term, id: 0 }),
    resource.fetchResource({ modules: ['year-terms'] })
  ])
}
