import { ref } from 'vue'
import { defineStore } from 'pinia'
import { ThemeModeComponent } from '@/assets/ts/layout'

export const THEME_MODE_LS_KEY = 'kt_theme_mode_value'
export const THEME_MENU_MODE_LS_KEY = 'kt_theme_mode_menu'

type ThemeItem = {
  theme: 'light' | 'dark' | 'system'
  icon: string
}

function changePrimeVueTheme (next_theme:'light'|'dark') {
  const element = document.querySelector('html')
  next_theme === 'dark' ? element?.classList.add('app-dark') : element?.classList.remove('app-dark')
}

export const useThemeStore = defineStore('theme', () => {
  const mode = ref<'light' | 'dark' | 'system'>(localStorage.getItem(THEME_MODE_LS_KEY) as 'light' | 'dark' | 'system' || 'light')

  const themes: Array<ThemeItem> = [
    { theme: 'light', icon: 'night-day' },
    { theme: 'dark', icon: 'moon' },
    { theme: 'system', icon: 'screen' }
  ]

  function setThemeMode (payload: 'light' | 'dark' | 'system') {
    let currentMode = payload
    localStorage.setItem(THEME_MODE_LS_KEY, currentMode)
    localStorage.setItem(THEME_MENU_MODE_LS_KEY, currentMode)
    mode.value = currentMode

    if (currentMode === 'system') {
      currentMode = ThemeModeComponent.getSystemMode()
    }

    changePrimeVueTheme(currentMode as 'light'|'dark')

    document.documentElement.setAttribute('data-bs-theme', currentMode)
    ThemeModeComponent.init()
  }

  function setThemeByTime () {
    const now = new Date()
    const hour = now.getHours()
    const isDayTime = hour > 6 && hour < 20

    isDayTime
      ? setThemeMode('light')
      : setThemeMode('dark')
  }

  return {
    themes,
    theme: mode,
    setThemeByTime,
    setTheme: setThemeMode
  }
})
