<template>
  <Teleport to="#sent-requests-content">
    <pms-row v-if="loader && loaderReserve">
      <pms-page-shimmer
        :shimmer="'table'"
        :full-width="true"
      />
    </pms-row>
    <pms-row
      v-else
      class="mb-4"
    >
      <pms-grid :col="12">
        <pms-smart-table
          :items="quotas"
          :headers="quotaTableHeader"
          :class="'mt-4'"
        >
          <template #no="{ idx }">
            {{ idx + 1 }}
          </template>
          <template #total_quota="{item}">
            {{ item.total_quota }}
          </template>
          <template
            v-for="header in ['N', 'P', 'L']"
            :key="header"
            #[`type-${header}`]="{ item }"
          >
            <template
              v-for="(item1, index) in item.sections"
              :key="index"
            >
              <div v-if="item1.type.id === header">
                <p class="m-0 p-0">
                  {{ item1.title }}
                </p>
                <p class="m-0 p-0">
                  {{ item1.instructor.full_name }}
                </p>
              </div>
            </template>
          </template>
          <template #reserved="{ item }">
            {{ item.reserves_count }}
          </template>
          <template #students="{item}">
            {{ item.registered_count }}
          </template>
          <template #action="{ item }">
            <div
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              data-bs-trigger="hover"
            >
              <a
                href="javascript:void(0)"
                class="btn btn-sm btn-icon btn-light-primary"
                @click="addReserveStudent(item.id, item.reserves)"
              >
                <pms-kt-icon
                  :icon-name="store.quota_id === item.id ? 'minus' : 'plus'"
                  class="fs-3 m-0 p-0"
                />
              </a>
            </div>
          </template>
        </pms-smart-table>
      </pms-grid>
    </pms-row>
    <StudentReservationComponent
      v-if="store.quota_id !== -1"
      :request-id="Number(modifier?.data?.id)"
    />
  </Teleport>
</template>
<script setup lang="ts">
import { watch, computed, onMounted } from 'vue'

import { useSentRequests } from '../store'
import { SectionManagementRequestsQuotasReservesGetResponse } from '@shared/swagger'
import { useTeleportModal, useLoader } from '@/shared/composable'

import { quotaTableHeader, newStudents } from '../values'
import StudentReservationComponent from './StudentReservationComponent.vue'

const { isActive } = useLoader()
const { show, modifier } = useTeleportModal()

const store = useSentRequests()

const loader = isActive('academic/manage/sections/requests/quotas')
const loaderReserve = isActive('academic/manage/sections/requests/quotas/reserves')
const quotas = computed(() => store.getSentRequestQuotas)

const addReserveStudent = (id: number, reserves: SectionManagementRequestsQuotasReservesGetResponse) => {
  newStudents.value = reserves.map(el => ({
    id: el.id, student: el.student.id
  }))
  store.quota_id = id
}

watch(
  () => modifier.value,
  (newValue) => {
    if (newValue && newValue.data && newValue.data.id) {
      show()
    }
  }, { immediate: true }
)
onMounted(() => {
  store.quota_id = -1
})
</script>
