import type { User } from '@shared/types'
import type { StoreType } from '../types'

import { getUserMe } from '@shared/api'
import { useToken } from '@/shared/composable'

export default async function authMe (this: StoreType): Promise<void> {
  const { ok, status, data } = await getUserMe()

  if (status === 400) {
    this.setUser(null)
    useToken().clearTokens()
    return
  }

  if (ok) {
    const { information, messages, events, permissions, new_inbox_messages } = data

    this.setUser(information as unknown as User)
    this.setMessages(messages)
    this.setEvents(events)
    this.setPermissions(permissions)
    this.setNewInboxMessages(new_inbox_messages)
    this.setAuthenticated(true)
    const verified_status = localStorage.getItem('verified_status')
    if (verified_status) { this.setVerifiedStatus(JSON.parse(verified_status)) }
  }
}
