<template>
  <BackToRequestBlock class="mb-5" />
  <ProgramDetails />
  <SearchRequestSearchSelectors />
  <SearchCoursesTable v-if="newRequestStore.isFetched" />
</template>

<script setup lang="ts">
import ProgramDetails from './ProgramDetails.vue'
import BackToRequestBlock from './BackToRequestBlock.vue'
import SearchRequestSearchSelectors from './SearchRequestSearchSelectors.vue'
import SearchCoursesTable from './SearchCoursesTable/SearchCoursesTable.vue'

import { useNewRequest } from '@domains/SectionManagement/features/NewRequest/store'

const newRequestStore = useNewRequest()
</script>
