import { SmartTableHeader } from '@shared/types'

export const statusConfirmation: Array<SmartTableHeader> = [
  {
    title: 'table-fields.status',
    slotName: 'statusTitle',
    field: 'status'
  },
  {
    title: 'table-fields.authority',
    slotName: 'authorityTitle',
    field: 'authority'
  },
  {
    title: 'table-fields.last-modified',
    slotName: 'lastModifiedTitle',
    field: 'last_modified'
  }
]

export const teachingMethods: Array<SmartTableHeader> = [
  {
    title: 'table-fields.title',
    slotName: 'title',
    field: 'title',
    sortable: true
  }
]

export const readingList: Array<SmartTableHeader> = [
  {
    title: 'table-fields.type',
    slotName: 'type',
    field: 'type',
    sortable: true
  },
  {
    title: 'table-fields.title',
    slotName: 'title',
    field: 'title',
    sortable: true
  },
  {
    title: 'table-fields.author',
    slotName: 'author',
    field: 'authors',
    sortable: true
  },
  {
    title: 'table-fields.isbn',
    slotName: 'isbn',
    field: 'isbn'
  },
  {
    title: 'table-fields.published',
    slotName: 'published',
    field: 'publish_year',
    sortable: true
  },
  {
    title: 'table-fields.publisher',
    slotName: 'publisher',
    field: 'publisher'
  }
]

export const assessment: Array<SmartTableHeader> = [
  {
    title: 'table-fields.assessment',
    slotName: 'assessment',
    field: 'title',
    sortable: true
  },
  {
    title: 'table-fields.description',
    slotName: 'description',
    field: 'description'
  },
  {
    title: 'table-fields.quantity',
    slotName: 'quantity',
    field: 'quantity',
    sortable: true
  },
  {
    title: 'table-fields.percentage',
    slotName: 'percentage',
    field: 'percentage',
    sortable: true
  }
]

export const activities: Array<SmartTableHeader> = [
  {
    title: 'table-fields.activity',
    slotName: 'activity',
    field: 'title',
    sortable: true
  },
  {
    title: 'table-fields.quantity',
    slotName: 'quantity',
    field: 'quantity',
    sortable: true
  },
  {
    title: 'table-fields.hours',
    slotName: 'hours',
    field: 'hour',
    sortable: true
  }
]

export const academicSkills: Array<SmartTableHeader> = [
  {
    title: 'table-fields.no',
    slotName: 'no',
    field: 'no'
  },
  ...teachingMethods
]

export const weeklyPlan: Array<SmartTableHeader> = [
  {
    title: 'table-fields.week',
    slotName: 'week',
    field: 'week_no',
    cellClass: 'text-center',
    headerClass: 'text-center w-40px',
    sortable: true
  },
  {
    title: 'table-fields.topics',
    slotName: 'topics',
    field: 'subject',
    sortable: true
  },
  {
    title: 'table-fields.activity',
    slotName: 'activity',
    field: 'activity',
    sortable: true
  }
]

export const learningOutcomes: Array<SmartTableHeader> = [
  {
    title: 'table-fields.no',
    slotName: 'no',
    field: 'no'
  },
  {
    title: 'table-fields.active-verb',
    slotName: 'action',
    field: 'action',
    sortable: true
  },
  {
    title: 'table-fields.what-will-be-done-produced',
    slotName: 'subject',
    field: 'subject',
    sortable: true
  },
  {
    title: 'table-fields.how-this-learning-outcome-will-be-achieved',
    slotName: 'outcome',
    field: 'outcome',
    sortable: true
  }
]

export const overview: Array<Array<{ label: string, value: Array<string> | string, optional?: string }>> = [
  [
    {
      label: 'table-fields.year-term',
      value: ['year', 'term'],
      optional: 'code'
    },
    {
      label: 'table-fields.course-code',
      value: ['course_code', 'equicode'],
      optional: 'code'
    },
    {
      label: 'table-fields.course-title',
      value: ['title'],
      optional: 'language'
    },
    {
      label: 'table-fields.department',
      value: ['department_title', 'department_code'],
      optional: 'code'
    },
    {
      label: 'table-fields.faculty',
      value: ['faculty_title', 'faculty_code'],
      optional: 'code'
    },
    {
      label: 'table-fields.language',
      value: ['language_title', 'language_code'],
      optional: 'code'
    }
  ],
  [
    {
      label: 'table-fields.type',
      value: ['type_title', 'type'],
      optional: 'code'
    },
    {
      label: 'table-fields.grading',
      value: ['grading_type', 'grading'],
      optional: 'code'
    },
    {
      label: 'table-fields.credits',
      value: ['credits', 'ects', 'theory', 'practice', 'lab'],
      optional: 'credits'
    }
  ]
]

export const instructors: Array<SmartTableHeader> = [
  {
    title: 'table-fields.no',
    slotName: 'no',
    field: 'no'
  },
  {
    title: 'table-fields.full-name',
    slotName: 'fullName',
    field: 'full_name',
    sortable: true
  },
  {
    title: 'table-fields.degree',
    slotName: 'degree',
    field: 'degree',
    sortable: true
  },
  {
    title: 'table-fields.contacts',
    slotName: 'contacts',
    field: 'contacts'
  }
]

export const requisitesTable: SmartTableHeader[] = [
  {
    title: 'common.table.program_code',
    field: 'program_code'
  },
  {
    title: 'common.table.educational_program',
    field: 'educational_program'
  },
  {
    title: 'common.common.table.course-title',
    field: 'course_title'
  },
  {
    title: 'common.common.table.type',
    field: 'type'
  },
  {
    title: 'common.table.consent_status',
    field: 'consent_status'
  }
]

export const programsTable: SmartTableHeader[] = [
  {
    title: 'common.table.program_code',
    field: 'program_code'
  },
  {
    title: 'common.table.program_title',
    field: 'program_title'
  },
  {
    title: 'common.degree',
    field: 'degree'
  }
]
