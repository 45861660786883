import { computed, ref } from 'vue'
import type { FormField } from '@shared/types'
import { useAlert, useTeleportModal } from '@shared/composable'
import { useI18n } from 'vue-i18n'
import UseCoursesFetcher from '@/domains/SectionManagement/features/MyCourses/ui/CoursesFilter/composable/UseCoursesFetcher/UseCoursesFetcher'
import SectionManagementCreateOfferPostRequest from '@/domains/SectionManagement/features/MyCourses/store/api/types/SectionManagementCreateOfferPostRequest'
import { useMyCourses } from '@/domains/SectionManagement/features/MyCourses/store'

export default function useCreateCourseOffer () {
  const { t } = useI18n()
  const { error, success } = useAlert()
  const { close, modifier, unsetModifier } = useTeleportModal()
  const modalData = (modifier.value?.data as Omit<SectionManagementCreateOfferPostRequest, 'note'>)

  const myCoursesStore = useMyCourses()
  const tempValues = ref({
    note: ''
  })

  const formFields = computed<Array<FormField>>(() => [
    {
      group: {
        class: 'fv-row mb-4 fv-plugins-icon-container'
      },
      label: {
        text: `${t('domains.academic.manage.sections.requested-course-note')}`,
        class: 'fs-6 fw-semibold mb-2'
      },
      name: 'note',
      type: 'textarea',
      value: tempValues.value.note
    }
  ])

  const { fetchCourses } = UseCoursesFetcher()

  const onSubmit = async ({ values }) => {
    const { status, message } = await myCoursesStore.createCourseOffer({
      note: values.note,
      course_code: modalData.course_code,
      year: modalData.year,
      term: modalData.term
    })
    if (status) {
      success({ text: message })
      if (myCoursesStore.selectedDepartment) { fetchCourses({ departmentCode: myCoursesStore.selectedDepartment.code }) }
    } else {
      error({ text: message })
    }
  }

  const closeModal = () => {
    close({ name: 'my-courses-modal' })
    unsetModifier()
  }

  return {
    formFields,
    onSubmit,
    closeModal
  }
}
