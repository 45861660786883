import type { StatusMessageResponse } from '@shared/types'
import { studentsSearchValues } from '../../values'
import type { StoreType } from '../types'
import putServiceManageLanguagesStudents from '../methods/putLanguageStudents'
import { ServiceManageLanguagesStudentsBulkPutRequest } from '../methods/putLanguageStudents/type'

export default async function putStudents (this: StoreType, values: ServiceManageLanguagesStudentsBulkPutRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await putServiceManageLanguagesStudents(values)

  if (ok) {
    this.fetchStudents(studentsSearchValues.value)
  }

  return {
    status: ok,
    message
  }
}
