<template>
  <pms-card
    :header="false"
  >
    <syllabus-course-information
      :overview="overview"
    />

    <syllabus-information-delivery-types
      :delivery-types="deliveryTypes"
    />

    <syllabus-information-description
      :description="description"
    />

    <syllabus-information-instructors
      :instructors="instructors"
    />

    <syllabus-information-requisites />
    <syllabus-information-programs />
  </pms-card>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { useSingleCourse } from '../../../store'

import SyllabusCourseInformation from './SyllabusCourseInformation.vue'
import SyllabusInformationDeliveryTypes from './SyllabusInformationDeliveryTypes.vue'
import SyllabusInformationDescription from './SyllabusInformationDescription.vue'
import SyllabusInformationInstructors from './SyllabusInformationInstructors.vue'
import SyllabusInformationRequisites from './SyllabusInformationRequisites.vue'
import SyllabusInformationPrograms from './SyllabusInformationPrograms.vue'

const store = useSingleCourse()

const overview = computed(() => store.getSyllabusOverview)
const deliveryTypes = computed(() => store.getSyllabusDeliveryTypes)
const description = computed(() => store.getSyllabusDescription)
const instructors = computed(() => store.getSyllabusInstructors)

</script>
