<template>
  <pms-page-shimmer
    v-if="loader"
    :shimmer="'list'"
    :full-width="true"
  />
  <pms-card
    v-else
    id="staff-list-search"
    :title="'common.search'"
    :description="$t('domains.administrative.staff-list.search.description')"
    :options="{ body: { class: 'pt-0'}}"
  >
    <pms-smart-form
      :fields="formFields"
      @handleSubmit="onSubmit"
      @handleChange="onChangeInput"
    >
      <template #department>
        <span class="mt-2"> {{ `${t('common.department')}:` }}</span>
        <treeselect
          v-model="department"
          :multiple="false"
          :options="sortedDep"
          value-format="object"
          placeholder=""
          class="mt-1 styled"
        />
      </template>
      <template #formControls>
        <div class="form-group d-flex justify-content-end mt-4">
          <pms-button
            type="submit"
            class="w-150px"
            :disabled="searchLoader"
            :loader="searchLoader"
            :text="$t('common.search')"
          />
        </div>
      </template>
    </pms-smart-form>
  </pms-card>
</template>
<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import Treeselect from 'vue3-treeselect'
import { FormGroup } from '@/shared/types'
import { useAlert, useLoader, usePagination, useToggler } from '@shared/composable'
import { useResource } from '@/shared/stores'
import { useStaffList } from '../store'
import { positionsToPositionOptions, getPositionsByDepartment, organizedDepartments } from '../utils'

const { t } = useI18n()
const { isActive } = useLoader()
const { error } = useAlert()
const { perPage } = usePagination()
const { toggle } = useToggler()

const store = useStaffList()
const resource = useResource()

const loader = isActive('administrative/resources')
const searchLoader = isActive('administrative/staffs')

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const department = ref<any>()

const defaultTempValue = {
  name: null,
  surname: null,
  department: null,
  position: null,
  sub_department: false
}
const tempValues = ref({ ...defaultTempValue })

const departments = computed(() => resource.getAdministrativeResourceDepartments)
const positions = ref(resource.getAdministrativeResourcePositions)
const sortedDep = computed(() => organizedDepartments(departments.value))

watch(() => [department.value, tempValues.value.sub_department, resource.getAdministrativeResourcePositions], () => {
  if (resource.getAdministrativeResourcePositions) {
    positions.value = getPositionsByDepartment(resource.getAdministrativeResourcePositions, department.value?.department, tempValues.value.sub_department)
  }
}, {
  deep: true,
  immediate: true
})

const formFields = computed<Array<FormGroup>>(() => [
  {
    class: 'row',
    fields: [
      {
        label: {
          text: `${t('common.name')}:`,
          class: 'mb-1'
        },
        group: {
          class: 'col-6'
        },
        name: 'name',
        type: 'text',
        value: tempValues.value.name || '',
        validator: {
          schema: 'yup',
          rules: [
            'string',
            { func: 'label', value: t('common.table.name') },
            'trim'
          ]
        }
      },
      {
        label: {
          text: `${t('common.surname')}:`,
          class: 'mb-1'
        },
        group: {
          class: 'col-6'
        },
        name: 'surname',
        type: 'text',
        value: tempValues.value.surname || '',
        validator: {
          schema: 'yup',
          rules: [
            'string',
            { func: 'label', value: t('common.table.surname') },
            'trim'
          ]
        }
      }
    ]
  },
  {
    class: 'row',
    fields: [
      {
        name: 'department',
        type: 'slot',
        group: {
          class: 'mb-1 mt-2 col-6'
        }
      },
      {
        label: {
          text: `${t('common.position')}:`,
          class: 'mb-1 mt-2'
        },
        group: {
          class: 'col-6'
        },
        name: 'position',
        type: 'select',
        value: tempValues.value.position || null,
        options: positionsToPositionOptions(positions.value)
      }
    ]
  },
  {
    class: 'row',
    fields: [
      {
        label: {
          text: `${t('common.sub-department')}`
        },
        group: {
          class: 'mb-1 mt-4 d-flex justify-content-start align-items-center'
        },
        name: 'sub_department',
        type: 'switch',
        value: tempValues.value.sub_department
      }
    ]
  }
])

const onChangeInput = ({ name, newValue }) => {
  tempValues.value[name] = newValue
}

const onSubmit = async ({ values }) => {
  if (!values.department) values.department = tempValues.value.department

  values.sub_department = values.sub_department ? 1 : values.department ? 0 : undefined

  const { status, message } = await store.fetchEmployees({ ...values, department: department.value?.id, per_page: perPage.value, offset: 1 })
  if (status) toggle()
  if (!status) error({ text: message })
}

watch(() => department.value, () => {
  department.value === null ? tempValues.value.sub_department = false : tempValues.value.sub_department = true
})
</script>
