import { useRequests } from '@shared/composable'
import type { AxiosResponse } from '@shared/types'
import type {
  UnknownRequest
} from '@shared/swagger'
import SectionManagementCourseSectionStatisticsGetRequest from '@/domains/SectionManagement/features/NewRequest/store/api/types/SectionManagementCourseSectionStatisticsGetRequest'
import SectionManagementCourseSectionStatisticsGetResponse from '@/domains/SectionManagement/features/NewRequest/store/api/types/SectionManagementCourseSectionStatisticsGetResponse'

export default async function getSectionManagementCoursesStatistics (params: SectionManagementCourseSectionStatisticsGetRequest): Promise<AxiosResponse<SectionManagementCourseSectionStatisticsGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, SectionManagementCourseSectionStatisticsGetResponse>({
    url: 'academic/manage/sections/courses/statistics',
    body: { params: params },
    loader: 'academic/manage/sections/courses/statistics'
  })

  return response
}
