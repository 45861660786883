import { defineStore } from 'pinia'
import type { Name, State, Getters, Actions } from './types'

import * as actions from './actions'

export const useMyCourses = defineStore<Name, State, Getters, Actions>('SectionManagement.features.useMyCourses', {
  actions: {
    ...actions
  },

  getters: {
  },

  state () {
    return {
      courses: [],
      selectedDepartment: null
    }
  }
})
