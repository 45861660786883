import type { StoreType } from '../types'
import { StatusMessageResponse } from '@/shared/types'
import SectionManagementNewRequestSuggestedCoursesGetRequest from '../api/types/SectionManagementNewRequestSuggestedCoursesGetRequest'
import getSectionManagementNewRequestSuggestedCourses from '@/shared/api/SectionManagement/getSectionManagementNewRequestSuggestedCourses'

export default async function fetchSectionManagementNewRequestSuggestedCourses (this: StoreType, params: SectionManagementNewRequestSuggestedCoursesGetRequest): Promise<StatusMessageResponse> {
  const { data, ok, message } = await getSectionManagementNewRequestSuggestedCourses(params)

  if (ok) {
    this.setSuggestedCourses(data)
  } else {
    this.setSuggestedCourses([])
  }
  return {
    status: ok,
    message: message
  }
}
