import { SelectField } from '@shared/types'
import { ServiceManageLanguagesResourcesResponse } from '@shared/swagger'

export default (data: ServiceManageLanguagesResourcesResponse['language_levels']): SelectField['options'] => {
  const options: SelectField['options'] = []

  data.forEach((item) => {
    options.push({
      label: `[${item.code}] - ${item.code}`,
      value: item.id
    })
  })

  return options
}
