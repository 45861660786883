import { SelectField } from '@/shared/types'
import { ReferencesSelects } from '../types'

export default (data: ReferencesSelects[]): SelectField['complexOption'] => {
  const options: SelectField['complexOption'] = []

  data.forEach((references) => {
    options.push({
      label: `${references.course_title}`,
      value: {
        course_code: references.course_code,
        course_title: references.course_title
      }
    })
  })

  return options
}
