<template>
  <pms-page-toolbar
    v-if="toolbar"
    v-bind="header"
    @launch="launch"
  >
    <template #action>
      <div class="d-flex gap-3">
        <pms-driver-button />
        <pms-feedback-modal :options="feedback" />
        <pms-page-tour
          :launched="status"
          :steps="steps"
          @launch="launch"
        />
      </div>
    </template>
  </pms-page-toolbar>
  <pms-container>
    <pms-column class="mt-6">
      <pms-grid
        :col="12"
      >
        <LanguagesFilter />
      </pms-grid>
      <pms-grid
        :order-mb="1"
        class="mt-5"
        :col="12"
      >
        <StudentsTable v-if="store.view === 'student'" />
        <AllApplicantsTable v-if="store.view === 'applicant'" />
      </pms-grid>
    </pms-column>
  </pms-container>
  <pms-teleport-modal
    :name="'language-modal'"
    :teleport-name="'language-modal-content'"
    :title="$t(`domains.service.manage.language.modal.set-all`)"
    :description="$t('domains.service.manage.language.modal.description')"
    :keyboard="false"
    :backdrop="false"
    :options="{
      content: {class: 'rounded'},
      header: {class: 'pb-0 border-0 justify-content-between'},
      body: {class: 'scroll-y pt-0'}
    }"
  />
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { useToolbar, useTour } from '../../shared/composable'
import { feedback, tour, header } from './values'
import AllApplicantsTable from './ui/ApplicantsView/AllApplicantsTable.vue'
import StudentsTable from './ui/StudentsView/StudentsTable.vue'
import LanguagesFilter from './ui/LanguagesFilter.vue'
import { useAllStudents } from './store'

const { toolbar, toggleToolbar } = useToolbar()
const { status, steps, launch, setSteps } = useTour([])
const store = useAllStudents()

onMounted(async () => {
  setSteps(await tour())
  toggleToolbar()
})
</script>
