<template>
  <pms-card
    :title="$t( 'domains.services.surveys.table.title')"
    :description="$t('domains.services.surveys.table.description')"
    class="mt-5"
    :options="{
      body: {
        class: 'pt-1'
      }
    }"
  >
    <pms-smart-table
      :headers="surveysTableHeaders"
      :items="surveys"
    >
      <template #title="{item}">
        {{ item.title }}
      </template>
      <template #year-terms="{item}">
        {{ item.year_term }}
      </template>
      <template #question="{item}">
        {{ item.question }}
      </template>
      <template #participation="{item}">
        {{ getParticipation(item.participation) }}
      </template>
      <template #actions="{item}">
        <template v-if="item.analysis">
          <pms-button
            class="btn"
            :onclick="() => goToSurveyPage(item.id)"
          >
            {{ t('common.results') }}
          </pms-button>
        </template>
      </template>
    </pms-smart-table>
  </pms-card>
</template>
<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { surveysTableHeaders } from './values'
import { useServiceSurveys } from '@domains/ServicesSurveys/store'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

const { t } = useI18n()
const router = useRouter()

const getParticipation = (participation: boolean) => {
  return participation ? t('common.open') : t('common.closed')
}
const goToSurveyPage = (surveyId: number) => {
  router.push({
    name: 'Survey',
    params: {
      id: surveyId
    }
  })
}

const store = useServiceSurveys()
const { surveys } = storeToRefs(store)
</script>
