import type { StatusMessageResponse } from '@shared/types'
import { studentsSearchValues } from '../../values'

import type { StoreType } from '../types'
import putServiceManageLanguagesSingleStudent from '../methods/putLanguageSingleStudent'
import { ServiceManageLanguagesSingleStudentPutRequest } from '../methods/putLanguageSingleStudent/type'

export default async function putSingleStudent (this: StoreType, id: string, values: ServiceManageLanguagesSingleStudentPutRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await putServiceManageLanguagesSingleStudent(id, values)

  if (ok) {
    this.fetchStudents(studentsSearchValues.value)
  }

  return {
    status: ok,
    message
  }
}
