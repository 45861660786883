import { useRequests } from '@shared/composable'
import type { AxiosResponse } from '@shared/types'
import type {
  UnknownResponse
} from '@shared/swagger'
import SectionManagementDeleteOfferRequest from '@/domains/SectionManagement/features/MyCourses/store/api/types/SectionManagementDeleteOfferRequest'

export default async function deleteSectionManagementOffer (id: SectionManagementDeleteOfferRequest['offerId']): Promise<AxiosResponse<UnknownResponse>> {
  const { destroy } = useRequests()

  const response = await destroy<UnknownResponse, UnknownResponse>({
    url: `academic/manage/sections/offers/${id}`,
    body: {},
    loader: 'delete-academic/manage/sections/offers'
  })

  return response
}
