import type { StoreType } from '../types'
import { postSectionManagementRequestQuotas } from '@shared/api'
import { SectionManagementRequestsQuotasPostRequest } from '@shared/swagger'
import { StatusMessageResponse } from '@shared/types'

export default async function createReceivedRequestQuota (this: StoreType, requestId: number, params: SectionManagementRequestsQuotasPostRequest): Promise<StatusMessageResponse> {
  const { message, ok } = await postSectionManagementRequestQuotas(requestId, params)

  return {
    status: ok,
    message
  }
}
