import SectionManagementReceivedRequestsQuotasSectionGetRequest from '../api/types/SectionManagementReceivedRequestsQuotasSectionGetRequest'
import type { StoreType } from '../types'

import { getSectionManagementRequestQuotas } from '@shared/api'

export default async function fetchRequestsQuotas (this: StoreType, params: SectionManagementReceivedRequestsQuotasSectionGetRequest): Promise<void> {
  const { data, ok } = await getSectionManagementRequestQuotas({
    request_id: params.request_id,
    program_code: params.program_code,
    equicode: params.equicode
  })

  if (ok) {
    this.selectedRequestsQuotas = data
    this.quotaStatistics = data.statistics
  } else {
    this.selectedRequestsQuotas = null
  }
}
