import type { StoreType } from '../types'
import { StatusMessageResponse } from '@/shared/types'
import { getSectionManagementRequestElective } from '@shared/api'
import SectionManagementRequestedElectiveCoursesGetRequest from '../api/types/SectionManagementRequestedElectiveCoursesGetRequest'

export async function fetchRequestElectiveCourses (this: StoreType, params: SectionManagementRequestedElectiveCoursesGetRequest): Promise<StatusMessageResponse> {
  const { data, ok, message } = await getSectionManagementRequestElective(params)

  if (ok) {
    this.setElectiveCourses(data)
  } else {
    this.setElectiveCourses([])
  }
  return {
    status: ok,
    message: message
  }
}
