import { AcademicCoursesStudentsResponse } from '@shared/swagger'
import * as XLSX from 'xlsx'

const excelDownloadAction = (data: AcademicCoursesStudentsResponse): void => {
  const worksheet = XLSX.utils.json_to_sheet(generateStudentRows(data))
  const csvData = XLSX.utils.sheet_to_csv(worksheet)

  const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' })
  const link = document.createElement('a')
  link.href = URL.createObjectURL(blob)
  link.setAttribute('download', 'students.csv')
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

const generateStudentRows = (data: AcademicCoursesStudentsResponse) => {
  return data.map((student) => ({
    ID: student.id,
    Student: `${student.name} ${student.surname}`,
    Speciality: `${student.speciality_name}`,
    'Speciality Code': `${student.speciality_code}`,
    Sections: student.enrolled_sections.map(enrolled_section => enrolled_section.section).join(', ')
  }))
}

export default excelDownloadAction
