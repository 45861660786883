import type { StoreType } from '../types'

import getSectionManagementReceivedRequestCourses from '@/shared/api/SectionManagement/getSectionManagementReceivedRequestCourses'
import SectionManagementReceivedRequestCoursesGetRequest from '../api/types/SectionManagementReceivedRequestCoursesGetRequest'

export default async function fetchSectionsCourses (this: StoreType, values: SectionManagementReceivedRequestCoursesGetRequest): Promise<void> {
  const { data, ok } = await getSectionManagementReceivedRequestCourses(values)

  if (ok) {
    this.setSectionsCourses(data)
  } else {
    this.setSectionsCourses([])
  }
}
