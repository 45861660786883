/* eslint-disable @typescript-eslint/no-explicit-any */
import { ref } from 'vue'
import { ServiceManageLanguagesApplicantsGetRequest, ServiceManageLanguagesApplicantsGetResponse } from '../store/methods/getApplicants/type'
import { ServiceManageLanguagesStudentsGetRequest, ServiceManageLanguagesStudentsGetResponse } from '../store/methods/getStudents/type'

export interface ModalTableData{
    id: number,
    full_name: string
}

export const selectedStudents = ref<ServiceManageLanguagesStudentsGetResponse['students']>([])
export const selectedDatasForModal = ref<any>([])
export const selectedApplicants = ref<ServiceManageLanguagesApplicantsGetResponse['applicants']>([])
export const student_language_code = ([] as any)
export const applicant_language_code = ([] as any)
export const applicantsSearchValues = ref<ServiceManageLanguagesApplicantsGetRequest>({
  iin: undefined,
  language_code: '',
  department_code: '',
  entrance_year: new Date().getFullYear(),
  name: undefined,
  surname: undefined,
  no_level: undefined,
  per_page: 20,
  offset: 1
})
export const studentsSearchValues = ref<ServiceManageLanguagesStudentsGetRequest>({
  id: undefined,
  language_code: '',
  department_code: '',
  entrance_year: new Date().getFullYear(),
  name: undefined,
  surname: undefined,
  no_level: undefined,
  per_page: 20,
  offset: 1
})
