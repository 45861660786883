import type { AcademicSingleStudentTranscriptGetResponse } from '@shared/swagger'
import type { TranscriptStore } from '../types'

export const formatTranscriptForStore = (data: AcademicSingleStudentTranscriptGetResponse): TranscriptStore => {
  let yearTerms: Array<string> | Array<{year: number, term: number}> = [
    ...new Set(data.courses.map((course) => `${course.year}-${course.term}`))
  ]

  yearTerms = yearTerms.map((yearTerm) => {
    const [year, term] = yearTerm.split('-')
    return { year: parseInt(year), term: parseInt(term) }
  })

  yearTerms = yearTerms.sort((a, b) => {
    if (a.year === b.year) {
      return a.term - b.term
    }
    return a.year - b.year
  })

  return {
    information: [
      [
        { title: 'common.table.student-id', value: data.information?.id },
        { title: 'common.table.student-fullname', value: data.information?.full_name },
        { title: 'common.table.edu-level', value: data.information?.education_level },
        { title: 'common.table.entry-date', value: data.information?.entry_date }
      ],
      [
        { title: 'common.table.faculty', value: data.information?.faculty },
        { title: 'common.table.speciality', value: data.information?.speciality },
        { title: 'common.table.edu-language', value: data.information?.education_language },
        { title: 'common.table.graduation-date', value: data.information?.graduation_date }
      ]
    ],
    transcript: yearTerms.map((yearTerms) => {
      return {
        year: yearTerms.year,
        term: yearTerms.term,
        courses: data.courses.filter((course) => course.year === yearTerms.year && course.term === yearTerms.term),
        gpa: data.gpa.filter((gpa) => gpa.year === yearTerms.year && gpa.term === yearTerms.term)
      }
    })
  }
}
