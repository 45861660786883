import { StatusMessageResponse } from '@/shared/types'
import type { StoreType } from '../types'
import getServiceSurveyById from '../api/methods/getSurvey'

export default async function fetchSurvey (this: StoreType, id: number): Promise<StatusMessageResponse> {
  const { ok, data, message } = await getServiceSurveyById(id)

  if (ok) {
    this.survey = data
  }

  return {
    status: ok,
    message
  }
}
