import type { StoreType } from '../types'
import type { StatusMessageResponse } from '@shared/types'
import deleteSectionManagementOffer from '@/shared/api/SectionManagement/deleteSectionManagementOffer'
import SectionManagementDeleteOfferRequest from '../api/types/SectionManagementDeleteOfferRequest'

export default async function deleteCourseOffer (this: StoreType, id: SectionManagementDeleteOfferRequest['offerId']): Promise<StatusMessageResponse> {
  const { ok, message } = await deleteSectionManagementOffer(id)

  return {
    status: ok,
    message
  }
}
