import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type {
  UnknownResponse
} from '@shared/swagger'
import SectionManagementSectionsPostRequest from '@/domains/SectionManagement/features/ReceivedRequests/store/api/types/SectionManagementSectionsPostRequest'

export default async function postSectionManagementSections (params: SectionManagementSectionsPostRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { post } = useRequests()

  const response = await post<UnknownResponse, UnknownResponse>({
    url: 'academic/manage/sections',
    body: params,
    loader: 'academic/manage/sections-post'
  })

  return response
}
