import { useResource } from '@/shared/stores'
import { ServiceMessageBroadcastResourceResponse, ServiceMessageBroadcastEmployeeGetResponse } from '@/shared/swagger'
import { storeToRefs } from 'pinia'
import { ref, computed } from 'vue'
import { useMessageBroadcastCompose } from '../store'
import { MultiselectOptionsType, TreeType } from '@/shared/types'

export function usePersonalByDepartment () {
  const resourceStore = useResource()
  const store = useMessageBroadcastCompose()
  const { serviceMessageBroadcastResource } = storeToRefs(resourceStore)
  const { selectedEmployee, employee_by_department } = storeToRefs(store)

  const departments_value = ref<TreeType>([])
  const departments_request = ref<string[]>([])
  const employee_groups = ref<MultiselectOptionsType>([])
  const departments_options = computed(():TreeType => mapDepartmentsOptions(serviceMessageBroadcastResource.value?.departments || []))

  function mapDepartmentsOptions (departments:ServiceMessageBroadcastResourceResponse['departments']):TreeType {
    return departments.map(department => {
      const res = {
        id: department.code,
        label: department.title,
        children: mapDepartmentsOptions(department.children as unknown as ServiceMessageBroadcastResourceResponse['departments'])
      }
      return res
    })
  }

  function addDepartmentToRequest (department:TreeType[0]) {
    if (department.children && department.children.length > 0) {
      department.children.forEach(addDepartmentToRequest)
    } else {
      departments_request.value.push(department.id)
    }
  }
  async function setDepartments () {
    departments_request.value = []
    departments_value.value.forEach(addDepartmentToRequest)
    if (departments_request.value.length > 0) {
      await store.getEmployees({ departments: departments_request.value })
    }
  }

  function setEmployees () {
    employee_groups.value = []
    const departments:Record<string, {items:ServiceMessageBroadcastEmployeeGetResponse, title:string}> = {}
    employee_by_department.value.forEach(emp => {
      if (departments[emp.department_code]) {
        departments[emp.department_code].items.push(emp)
      } else {
        departments[emp.department_code] = {
          title: emp.department_title,
          items: [emp]
        }
      }
    })
    for (const key in departments) {
      employee_groups.value.push({
        label: departments[key].title,
        code: key,
        items: departments[key].items.map(emp => ({
          label: `${emp.speciality} - ${emp.full_name}`,
          value: emp.username
        }))
      })
    }
  }

  return {
    selectedEmployee,
    employee_groups,
    departments_value,
    departments_options,
    setDepartments,
    setEmployees
  }
}
