import type { StoreType } from '../types'
import SectionManagementReceivedRequestsGetRequest from '../api/types/SectionManagementReceivedRequestsGetRequest'
import getSectionManagementReceivedRequests from '@/shared/api/SectionManagement/getSectionManagementReceivedRequests'

export default async function fetchReceivedRequests (this: StoreType, values: SectionManagementReceivedRequestsGetRequest): Promise<void> {
  const { data, ok } = await getSectionManagementReceivedRequests(values)

  if (ok) {
    this.setReceivedRequests([...data])
  } else {
    this.receivedRequests = []
  }
}
