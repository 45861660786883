import { ServiceManageLanguagesResourcesResponse } from '@shared/swagger'
import { TreeSelectDepartments } from '../types'

export default (data: ServiceManageLanguagesResourcesResponse['departments']): TreeSelectDepartments[] => {
  const options: TreeSelectDepartments[] = []
  data.forEach((item) => {
    options.push({
      id: item.department_id,
      key: item.department_code,
      selectable: false,
      label: item.title,
      children: setValues(item.children)
    })
  })
  return options
}

const setValues = (data) => {
  const res : TreeSelectDepartments[] = []
  if (data.length > 0) {
    data.forEach((item) => {
      res.push({
        id: item.department_id,
        key: item.department_code,
        selectable: true,
        label: item.title,
        children: setValues(item.children)
      })
    })
  }
  return res
}
