import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type {
  UnknownRequest,
  SectionManagementRequestElectiveGetRequest
} from '@shared/swagger'
import SectionManagementRequestedElectiveCoursesGetRequest from '@/domains/SectionManagement/features/NewRequest/store/api/types/SectionManagementRequestedElectiveCoursesGetRequest'
import SectionManagementRequestedElectiveCoursesGetResponse from '@/domains/SectionManagement/features/NewRequest/store/api/types/SectionManagementRequestedElectiveCoursesGetResponse'

export default async function getSectionManagementRequestElective (params: SectionManagementRequestedElectiveCoursesGetRequest): Promise<AxiosResponse<SectionManagementRequestedElectiveCoursesGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, SectionManagementRequestedElectiveCoursesGetResponse>({
    url: 'academic/manage/sections/courses/new-request-elective',
    body: { params: params },
    loader: 'academic/manage/sections/courses/new-request-elective'
  })

  return response
}
