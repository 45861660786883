import { useAlert } from '@/shared/composable'
import type { StoreType } from '../types'
import getAcademicSingleStudentCurriculum from '../api/methods/getAcademicSingleStudentCurriculum'

export default async function fetchCurriculum (this: StoreType, id: string): Promise<void> {
  const { error } = useAlert()
  const { data, ok, message } = await getAcademicSingleStudentCurriculum(id)

  if (ok) {
    this.setCurriculum(data)
  } else {
    error({ text: message, isToast: true })
  }
}
