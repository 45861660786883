<template>
  <pms-modal-sidebar
    v-model:current-width="currentWidth"
    v-model:state="sideBarState"
    :title="sideBarTitle"
    :description="sidebarDescription"
    :state="sideBarState"
    :modal="'AccountingDetails'"
    :width="'450px'"
    :position="'end'"
    :scroll-out="false"
    :resize="true"
    :backdrop="false"
  >
    <pms-loader
      v-if="detailsLoader"
      :options="{
        body: { class: 'p-0 ps-2 pt-0 pe-2' }
      }"
      :items="6"
    />
    <template v-else>
      <div v-if="sideBarData === null">
        <pms-no-data class="mt-20 fs-4 fw-bold" />
      </div>
      <div
        v-else
        class="row"
      >
        <span class="fw-bold fs-4 mb-3">
          {{ $t('domains.academic.single-student.accounting.payments') }}
        </span>
        <pms-smart-table
          :headers="accountingTableHeaders.payments"
          :items="sideBarData?.payments.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()) || []"
        >
          <template #date="{ value }">
            <span class="fs-5">{{ dateFormatter(value, dateOptions) }}</span>
          </template>
          <template #remainder="{ item }">
            {{ item.remainder }}
          </template>
        </pms-smart-table>
        <div class="separator separator-dashed my-4" />

        <span class="fw-bold fs-4 mb-3">
          {{ $t('domains.academic.single-student.accounting.discounts') }}
        </span>
        <pms-smart-table
          :headers="accountingTableHeaders.discounts"
          :items="sideBarData?.discounts || []"
        >
          <template #percentage="{ item }">
            {{ item.percentage }}
          </template>
        </pms-smart-table>
        <div class="separator separator-dashed my-4" />

        <span class="fw-bold fs-4 mb-3">
          {{ $t('domains.academic.single-student.accounting.charges') }}
        </span>
        <pms-smart-table
          :headers="accountingTableHeaders.charges"
          :items="sideBarData?.charges || []"
        >
          <template #percentage="{ item }">
            {{ item.percentage }}
          </template>
        </pms-smart-table>
      </div>
    </template>
  </pms-modal-sidebar>
</template>
<script setup lang="ts">
import { PropType } from 'vue'

import { useLoader } from '@shared/composable'
import type { AcademicSingleStudentAccountingDetailsGetResponse } from '@/shared/swagger'
import { dateFormatter } from '@shared/utils'

import { dateOptions } from '../../../values'
import { accountingTableHeaders, sideBarTitle, sidebarDescription, sideBarState, currentWidth } from '../values'

defineProps({
  sideBarData: {
    type: Object as PropType<AcademicSingleStudentAccountingDetailsGetResponse | null>,
    required: true
  }
})

const { isActive } = useLoader()

const detailsLoader = isActive('academic/students/accounting/details')
</script>
