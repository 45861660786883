import { SmartTableHeader } from '@/shared/types'

export const courseStatisticsTable: Array<SmartTableHeader> = [
  {
    title: 'common.table.class',
    field: 'class',
    slotCellName: 'class',
    cellClass: 'text-center'
  },
  {
    title: 'common.table.enrolled',
    field: 'enrolled',
    slotCellName: 'enrolled',
    cellClass: 'text-center'
  },
  {
    title: 'common.table.passed',
    field: 'passed',
    slotCellName: 'passed',
    cellClass: 'text-center'
  },
  {
    title: 'common.table.failed',
    field: 'failed',
    slotCellName: 'failed',
    cellClass: 'text-center'
  },
  {
    title: 'common.table.will-take',
    field: 'will_take',
    slotCellName: 'willTake',
    cellClass: 'text-center'
  }
]
