export const tabs = [
  {
    id: 'information',
    label: 'domains.academic.single-student.information.title'
  },
  {
    id: 'contact',
    label: 'domains.academic.single-student.contact.title'
  },
  {
    id: 'address',
    label: 'domains.academic.single-student.address.title'
  },
  {
    id: 'parentInformation',
    label: 'domains.academic.single-student.parent-information.title'
  },
  {
    id: 'decision',
    label: 'domains.academic.single-student.decision.title'
  },
  {
    id: 'advisorOpinion',
    label: 'domains.academic.single-student.advisor-opinion.title'
  },
  {
    id: 'dissertation',
    label: 'domains.academic.single-student.dissertation.title'
  },
  {
    id: 'social_activities',
    label: 'domains.academic.single-student.social_activities.title'
  }
]
