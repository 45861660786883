<template>
  <pms-loader v-if="loader" />
  <div v-else>
    <template
      v-for="(tracks, index) in mappedCourses"
      :key="index"
    >
      <h3 class="text-center my-3">
        {{ tracks.courses[0].track }}
      </h3>
      <pms-smart-table
        :items="tracks.courses"
        :headers="requestCourseTable"
        :sortable="true"
      >
        <template #no="{ idx }">
          {{ idx + 1 }}
        </template>
        <template #courseCode="{ item }">
          {{ item.code }}
        </template>
        <template #title="{ item }">
          {{ item.title }}
          <template v-if="item.reference_code">
            <pms-kt-icon
              :icon-name="'arrow-mix'"
              class="text-success lead"
            />
            {{ item.reference_title }}
          </template>
        </template>
        <template #term="{ item }">
          {{ item.term }}
        </template>
        <template #theory="{ item }">
          {{ item.theory }}
        </template>
        <template #practice="{ item }">
          {{ item.practice }}
        </template>
        <template #ects="{ item }">
          {{ item.ects }}
        </template>
        <template #action="{ item }">
          <div class="d-flex gap-5">
            <pms-button
              :size="'sm'"
              :variant="'tonal'"
              @click="addNewRequest(generateRequestData(item))"
            >
              {{ $t('phrase.add-new-request') }}
              <pms-kt-icon
                :icon-name="'arrow-right'"
                class="m-0 p-0 ms-2"
              />
            </pms-button>

            <pms-button
              v-if="item.reference_title"
              :size="'sm'"
              :variant="'tonal'"
              @click="addNewRequest(generateRequestData(item, true))"
            >
              {{ $t('phrase.add-new-request-for-reference') }}
              <pms-kt-icon
                :icon-name="'arrow-right'"
                class="m-0 p-0 ms-2"
              />
            </pms-button>
          </div>
        </template>
      </pms-smart-table>
    </template>
    <template
      v-if="!mappedCourses.length"
    >
      <pms-no-data />
    </template>
  </div>

  <NewRequestForm
    v-if="modifier && modifier.name === 'new-request-content'"
    :requested-by-type="ERequestedBy.Program"
  />
</template>

<script setup lang="ts">
import { requestCourseTable } from '@domains/SectionManagement/features/NewRequest/values'
import { useNewRequest } from '@domains/SectionManagement/features/NewRequest/store'
import { computed } from 'vue'
import { useLoader, useTeleportModal } from '@shared/composable'
import NewRequestForm from '@domains/SectionManagement/features/NewRequest/ui/NewRequestForm/NewRequestForm.vue'
import SectionManagementNewRequestCoreCoursesGetResponse from '../store/api/types/SectionManagementNewRequestCoreCoursesGetResponse'
import { TNewRequestFormModalData } from './NewRequestForm/types'
import { ERequestedBy } from '../enums'

const { isActive } = useLoader()
const { open, modifier, setModifier } = useTeleportModal()
const loader = isActive('academic/manage/sections/courses')

const newRequestStore = useNewRequest()
const mappedCourses = computed(() => {
  const courses:Record<string, {courses:typeof newRequestStore.requestCourses}> = {}
  newRequestStore.requestCourses.forEach(course => {
    if (!courses[course.track]) {
      courses[course.track] = {
        courses: [course]
      }
    } else {
      courses[course.track].courses.push(course)
    }
  })
  return courses
})

function generateRequestData (item: SectionManagementNewRequestCoreCoursesGetResponse[0], isReference = false): TNewRequestFormModalData {
  const result = {
    equicode: item.equicode,
    courseCode: item.course_code,
    courseYear: item.course_year,
    sectionRequestId: item.section_request_id,
    curriculum_id: item.curriculum_id
  }
  if (isReference) {
    result.equicode = item.reference_equicode
    result.courseCode = item.reference_code
    result.sectionRequestId = item.reference_req_id ?? undefined
  }
  return result
}

async function addNewRequest (data: TNewRequestFormModalData): Promise<void> {
  setModifier('new-request-content',
    data,
    { title: 'new-request-title', description: 'new-request-description' }
  )
  open({ name: 'new-request-modal' })
}
</script>
