import { ServiceManageLockResourcesResponse } from '@/shared/swagger'
import { SelectField } from '@shared/types'

export default (data: ServiceManageLockResourcesResponse['specialities'] | null): SelectField['options'] => {
  const options: SelectField['options'] = []

  if (data) {
    data.forEach((el) => {
      options.push({
        label: `${el.code} - ${el.title} [${el.year}]`,
        value: el.code
      })
    })
  }
  return options
}
