import type { StoreType } from '../types'
import { getSectionManagementSectionsQuotas } from '@shared/api'
import SectionManagementSectionsReceivedRequestsQuotasReserveGetRequest from '../api/types/SectionManagementSectionsReceivedRequestsQuotasReserveGetRequest'

export default async function fetchReservedProgramsQuotas (this: StoreType, params: SectionManagementSectionsReceivedRequestsQuotasReserveGetRequest): Promise<void> {
  const { data, ok } = await getSectionManagementSectionsQuotas(params)

  if (ok) {
    this.selectedRequestsQuotas = data
    this.quotaStatistics = data.statistics
  } else {
    this.selectedRequestsQuotas = null
  }
}
