
import type { StoreType } from '../types'
import { getSectionManagementReservedPrograms } from '@shared/api'
import { SectionManagementRequestGetResponse } from '@shared/swagger'
import SectionManagementSectionsQuotasGetRequest from '../api/types/SectionManagementSectionsQuotasGetRequest'

export default async function fetchReservedPrograms (this: StoreType, params: SectionManagementSectionsQuotasGetRequest): Promise<{
  reservedPrograms: SectionManagementRequestGetResponse
}> {
  const { data, ok } = await getSectionManagementReservedPrograms(params)

  return {
    reservedPrograms: ok ? data : []
  }
}
