import getSectionById from '../api/methods/getSectionById/getSectionById'
import type { StoreType } from '../types'

export default async function fetchSingleSection (this: StoreType, id: number): Promise<void> {
  const { data, ok } = await getSectionById(id)

  if (ok) {
    this.singleSection = data
  } else {
    this.singleSection = null
  }
}
