<template>
  <div
    class="bg-body d-flex flex-center rounded-4 w-md-600px p-10"
    :style="`${device === 'mobile' ? 'width: 100%' : ''}`"
  >
    <div
      class="d-flex flex-center flex-column align-items-stretch h-lg-100 w-md-350px"
      :style="`${device === 'mobile' ? 'width: 90%' : ''}`"
    >
      <div class="d-flex flex-row-reverse">
        <pms-dropdown
          placement="end"
          :show-icon="false"
        >
          <template #button>
            <div class="menu-link px-3 py-2 w-50px">
              <span
                class="menu-icon btn btn-icon btn-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px"
                data-kt-element="icon"
              >
                <span class="svg-icon svg-icon-3">
                  <pms-kt-icon
                    :icon-name="getIcon(theme)"
                    icon-class="fs-2"
                  />
                </span>
              </span>
            </div>
          </template>
          <template
            v-for="(item) in themes"
            :key="item.theme"
          >
            <div class="menu-item px-3 my-0">
              <a
                href="javascript:void(0)"
                class="menu-link px-3 py-2"
                :class="{ active: theme === item.theme }"
                @click="setTheme(item.theme)"
              >
                <span
                  class="menu-icon"
                  data-kt-element="icon"
                >
                  <span class="svg-icon svg-icon-3">
                    <pms-kt-icon
                      :icon-name="item.icon"
                      icon-class="fs-2"
                    />
                  </span>
                </span>
                <span class="menu-title">{{ $t(`layout.mode.${item.theme}`) }}</span>
              </a>
            </div>
          </template>
        </pms-dropdown>
      </div>
      <div class="text-center mb-2 mt-2">
        <div class="text-center m-0 p-0 mb-4">
          <img
            class="theme-light-show mw-100 w-200px"
            src="/new-logo-light.svg"
          >
          <img
            class="theme-dark-show mw-100 w-200px"
            src="/new-logo-dark.png"
          >
        </div>
        <h1 class="text-dark fw-bolder mb-2 fs-1 pt-0">
          {{ $t('domains.authenticator.sign-in.title') }}
        </h1>
        <div class="text-gray-500 fw-semibold fs-5">
          {{ $t('domains.authenticator.sign-in.description') }}
        </div>
      </div>

      <pms-smart-form
        :fields="[formFields]"
        @handleSubmit="onSubmit"
      >
        <template #formControls>
          <button
            v-test-id="'submit'"
            type="submit"
            class="btn btn-lg btn-primary w-100"
            :disabled="loader"
          >
            <span
              v-if="!loader"
              class="indicator-label"
            >
              {{ $t('common.continue') }}
            </span>

            <span
              v-if="loader"
              class="indicator-label"
            >
              {{ $t('phrases.please-wait') }} <span class="spinner-border spinner-border-sm align-middle ms-2" />
            </span>
          </button>
        </template>
        <template #link-page>
          <div class="d-flex flex-row-reverse justify-content-between mb-2">
            <router-link
              class="link-primary fs-6 fw-bolder"
              to="/auth/forgot-password"
              target="_blank"
            >
              {{ $t('domains.authenticator.form.forgot-password') }}
            </router-link>
            <span
              class="link-primary fs-6 fw-bolder"
              style="cursor: pointer;"
              @click="openMail"
            >
              {{ $t('domains.authenticator.form.login-to-sdu-mail') }}
            </span>
          </div>
        </template>
      </pms-smart-form>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { FormField } from '@shared/types'
import { deviceType } from '@shared/utils'
import { useLoader } from '@shared/composable'
import { useConfigStore, useThemeStore } from '@shared-stores/pattern'
import { useAuth } from '@domains/SignIn/store'
const { t } = useI18n()
const { isActive } = useLoader()

const storeTheme = useThemeStore()
const storeConfig = useConfigStore()
const store = useAuth()
const loader = isActive('/1.0.0/sign-in')

const device = deviceType()
const theme = computed(() => storeTheme.theme)
const themes = computed(() => storeTheme.themes)

const formFields: Array<FormField> = [
  {
    type: 'text',
    name: 'username',
    group: {
      class: 'mb-4'
    },
    label: {
      text: t('domains.authenticator.form.username'),
      class: 'fs-6 fw-semibold text-dark mb-1'
    },
    binding: {
      autocomplete: 'username'
    },
    validator: {
      schema: 'yup',
      rules: [
        'string',
        'required',
        { func: 'label', value: t('domains.authenticator.form.username') },
        'strict',
        { func: 'min', value: 2 },
        { func: 'max', value: 30 }
      ]
    }
  },
  {
    type: 'password',
    name: 'password',
    group: {
      class: 'mb-4'
    },
    label: {
      text: t('domains.authenticator.form.password'),
      class: 'fs-6 fw-semibold text-dark mb-1'
    },
    binding: {
      autocomplete: 'current-password'
    },
    validator: {
      schema: 'yup',
      rules: [
        'string',
        'required',
        { func: 'label', value: t('domains.authenticator.form.password') },
        { func: 'min', value: 8 },
        { func: 'max', value: 30 }
      ]
    }
  },
  {
    type: 'slot',
    name: 'link-page'
  }
]

const getIcon = (theme: 'light' | 'dark' | 'system') => {
  return themes.value.find((item) => item.theme === theme)?.icon
}

const setTheme = (mode: 'dark' | 'light' | 'system') => {
  const configMode = mode
  storeConfig.setLayoutConfigProperty('general.mode', configMode)
  storeTheme.setTheme(configMode)
}

const openMail = () => {
  window.open(
    process.env.SDU_MAIL_URI ??
    'https://accounts.google.com/AccountChooser/signinchooser?hd=sdu.edu.kz&continue=https%3A%2F%2Fmail.google.com%2Fmail%2F&osid=1&service=mail&ss=1&ltmpl=default&ddm=1&flowName=GlifWebSignIn&flowEntry=AccountChooser'
    , '_blank')
}

const onSubmit = async ({ values }) => {
  store.authenticate({ ...values, device: 'web:desktop' })
}
</script>
