import { useRequests } from '@shared/composable'
import type { AxiosResponse } from '@shared/types'
import type {
  UnknownRequest
} from '@shared/swagger'
import SectionManagementReceivedRequestSectionGetResponse from '../getSectionById/types'

export default async function getSectionById (id: number): Promise<AxiosResponse<SectionManagementReceivedRequestSectionGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, SectionManagementReceivedRequestSectionGetResponse>({
    url: `academic/manage/sections/${id}`,
    body: { },
    loader: 'get-academic/manage/sections/:id'
  })

  return response
}
