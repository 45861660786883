import { sentRequest } from './../../values/refs'
import { destroySectionManagementSentRequest } from '@shared/api'
import type { StatusMessageResponse } from '@shared/types'

import type { StoreType } from '../types'

export default async function deleteSentRequest (this: StoreType, id: number): Promise<StatusMessageResponse> {
  const { ok, message } = await destroySectionManagementSentRequest(id)

  if (ok && sentRequest.value) {
    await this.fetchSentRequests({
      term: sentRequest.value.term,
      year: sentRequest.value.year,
      status: this.status === 0 ? undefined : this.status,
      program_code: sentRequest.value.program_code
    })
  }

  return {
    status: ok,
    message
  }
}
