<template>
  <tbody>
    <tr
      v-for="(hour, index) in scheduleHours"
      :key="index"
      class="fs-5 text-center align-middle"
    >
      <td
        v-styler="{ paddingRight: 0 }"
        class="bg-light"
        :style="index === scheduleHours.length - 1 ? 'border-bottom-left-radius: 0.625rem' : ''"
      >
        <span
          class="text-dark fw-bolder d-block fs-5"
        >
          {{ hour.start_time }}
        </span>
        <span class="d-block fs-5">
          {{ hour.end_time }}
        </span>
      </td>
      <td
        v-for="day in scheduleWeekDays"
        :key="day.id"
        class="p-2 hover-bg-active fs-6"
      >
        <template
          v-if="showEmptyRooms"
        >
          <div class="d-flex flex-row w-100 justify-content-around flex-wrap">
            <p
              v-for="room in getEmptyRooms(day.id, hour.start_time, hour.end_time)"
              :key="room"
              class="p-2"
            >
              <i
                class="bi bi-building fs-4"
                data-bs-toggle="tooltip"
                data-bs-placement="bottom"
                data-kt-initialized="1"
                :data-bs-original-title="$t('common.room')"
              /> : &nbsp; {{ room }}
            </p>
          </div>
        </template>
        <template
          v-for="(course, ind) in getCourses(day.id, hour.start_time, hour.end_time)"
          v-else
          :key="ind"
        >
          <div
            v-if="ind !== 0"
            class="separator separator-dashed my-3"
          />
          <p class="mt-1 m-0 text-primary">
            {{ course.course_code }}.{{ course.course_section }} - {{ $t(`common.course-types.${camelCaseToLowerKebabCase(course.course_type)}`) }}
          </p>
          <p class="m-0">
            {{ course.course_title }}
          </p>
          <div v-show="details">
            {{ course.course_credits }} {{ $t('common.credits') }} / {{ course.course_ects }} {{ $t('common.ects') }}
            <p class="m-0 pt-2">
              <i
                class="bi bi-person-circle fs-4"
                data-bs-toggle="tooltip"
                data-bs-placement="bottom"
                data-kt-initialized="1"
                :data-bs-original-title="$t('common.instructor')"
              /> : &nbsp; {{ course.course_instructor }}
            </p>
          </div>
          <p class="mt-1">
            <i
              class="bi bi-building fs-4"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              data-kt-initialized="1"
              :data-bs-original-title="$t('common.room')"
            /> : &nbsp; {{ course.room }}
          </p>
        </template>
      </td>
    </tr>
  </tbody>
</template>
<script setup lang='ts'>
import { PropType, watch } from 'vue'
import { camelCaseToLowerKebabCase } from '@shared/utils'
import { AcademicInformationScheduleResponse, ScheduleManagementHoursResponse } from '@shared/swagger'
import { scheduleWeekDays, details, showEmptyRooms, selectedRooms } from '../values'

const props = defineProps({
  schedule: {
    type: Array as PropType<AcademicInformationScheduleResponse>,
    required: true
  },
  print: {
    type: Boolean,
    default: false
  },
  scheduleHours: {
    type: Array as PropType<ScheduleManagementHoursResponse>,
    required: true
  }
})

const emit = defineEmits(['update:print'])

watch(
  () => props.print,
  (value) => {
    emit('update:print', value)
  }
)

const getCourses = (day: number, start: string, end: string): AcademicInformationScheduleResponse => {
  return props.schedule.filter((record) => {
    return record.day === day && record.start_time.includes(start) && record.end_time.includes(end)
  })
}

const getEmptyRooms = (day: number, start: string, end: string) => {
  const courses = getCourses(day, start, end)
  const takenRooms = courses.map(course => course.room)
  const freeRooms = selectedRooms.value.filter(room => !takenRooms.includes(room))
  return freeRooms
}
</script>
