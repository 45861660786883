import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type {
  UnknownResponse
} from '@shared/swagger'
import SectionManagementEditOfferPutRequest from '@/domains/SectionManagement/features/MyCourses/store/api/types/SectionManagementEditOfferPutRequest'

export default async function putSectionManagementOffer (id: number, params: SectionManagementEditOfferPutRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { put } = useRequests()

  const response = await put<UnknownResponse, UnknownResponse>({
    url: `academic/manage/sections/offers/${id}`,
    body: params,
    loader: 'put-academic/manage/sections/offers'
  })

  return response
}
