import { useStore } from '@domains/AcademicCurricula/store'
import { storeToRefs } from 'pinia'
import { computed, ref, watch } from 'vue'

const useWorkingCurriculaData = () => {
  const store = useStore()
  const { workingCurriculum } = storeToRefs(store)

  const prorector = ref('')
  const educationalMethodicalDirector = ref('')

  watch(workingCurriculum, () => {
    if (workingCurriculum.value) {
      const prorectorFullnameArray = [workingCurriculum.value.prorector.surname, getFirstLetter(workingCurriculum.value.prorector.name), getFirstLetter(workingCurriculum.value.prorector.father_name)]
      prorector.value = prorectorFullnameArray.join(' ')

      const educationalMethodicalDirectorFullnameArray = [workingCurriculum.value.educational_methodical_director.surname, getFirstLetter(workingCurriculum.value.educational_methodical_director.name), getFirstLetter(workingCurriculum.value.educational_methodical_director.father_name)]
      educationalMethodicalDirector.value = educationalMethodicalDirectorFullnameArray.join(' ')
    }
  })

  const getCourseTypeByCode = (code: string) => {
    return code === 'E' ? 'TK, KB, EC' : 'ЖК, ВК, UC'
  }

  function printContent () {
    const printContent = document.getElementById('workingCurricula')?.innerHTML
    const win = window.open('', '_blank')
    win?.document.open()
    win?.document.write(`
          <html>
            <head>
              <title>Working-curricula-${workingCurriculum.value?.program.code}</title>
              <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css">
            </head>
            <body style="font-family: 'Times New Roman', Times, serif !important;">
              ${printContent}
              <script>
                window.onload = function() {
                  window.print();
                }
              </script>
            </body>
          </html>
        `)
    win?.document.close()
  }

  const sortedTerms = computed(() => {
    return workingCurriculum.value?.terms.slice().sort((a, b) => a.term - b.term) || []
  })

  const groupedCoursesByTerm = computed(() => {
    return sortedTerms.value.map(term => {
      const grouped: Record<string, { elective_code: string, courses: any[] }> = {}

      term.courses.forEach(course => {
        const electiveGroupKey = course.elective_id || 'noElectiveCode'

        if (!grouped[electiveGroupKey]) {
          grouped[electiveGroupKey] = {
            elective_code: course.elective_code || '',
            courses: []
          }
        }
        grouped[electiveGroupKey].courses.push(course)
      })

      // Разделяем курсы на две группы
      const noElectiveCourses = grouped.noElectiveCode?.courses || []
      const electiveCourses = Object.entries(grouped)
        .filter(([key]) => key !== 'noElectiveCode')
        .sort(([a], [b]) => a.localeCompare(b)) // Можно отсортировать по elective_id или elective_code, если нужно

      return {
        term: term.term,
        courses: {
          noElectiveCourses,
          electiveCourses
        },
        total_ects: term.total_ects
      }
    })
  })

  function getFirstLetter (word: string): string {
    const trimmedWord = word.trim()
    if (trimmedWord.length === 0) {
      return '' // Возвращаем пустую строку, если после триммирования нет символов
    }
    return trimmedWord.charAt(0).toUpperCase()
  }

  return {
    prorector,
    educationalMethodicalDirector,
    workingCurriculum,
    printContent,
    getCourseTypeByCode,
    groupedCoursesByTerm
  }
}

export default useWorkingCurriculaData
