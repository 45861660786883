import { StatusMessageResponse } from '@/shared/types'
import type { StoreType } from '../types'
import getInstructors from '../api/methods/getInstructors'
import { ServiceQuestionaryDepartmentInstructorsGetRequest } from '../api/methods/getInstructors/types'

export default async function fetchInstructors (this: StoreType, surveyId: number, params: ServiceQuestionaryDepartmentInstructorsGetRequest): Promise<StatusMessageResponse> {
  const { ok, data, message } = await getInstructors(surveyId, params)

  if (ok) {
    this.instructors = data
  }

  return {
    status: ok,
    message
  }
}
