<template>
  <!-- Actions -->
  <div class="mt-5">
    <router-link :to="{name: 'AcademicCurriculaPage'}">
      <button
        title="Back"
        type="button"
        class="btn btn-light-primary btn-sm me-2 p-3"
      >
        <pms-kt-icon
          icon-name="arrow-left"
          class="m-0 p-1"
        />
      </button>
    </router-link>
    <pms-button @Click="printContent()">
      {{ t('common.download-print') }}
    </pms-button>
  </div>

  <div
    id="workingCurricula"
    class="mt-5 p-5"
  >
    <!-- Head -->
    <div>
      <div class="text-center">
        <p>Қазақстан Республикасы Ғылым және жоғары білім Министрлігі</p>
        <p>Министерство Науки и высшего образования Республики Казахстан</p>
        <p>Ministry of Science and Higher Education of the Republic of Kazakhstan</p>
      </div>

      <div class="d-flex">
        <div class="col-4 text-center d-flex flex-column justify-content-end pb-5">
          <label>"Келісілген"</label>
          <label>
            <p>Оқу-әдістемелік орталығының директоры:</p>
            <p class="nowrap">{{ educationalMethodicalDirector }} {{ signPlace }}</p>
          </label>
          <label>
            {{ dateField }}
          </label>
        </div>
        <div class="col-4 d-flex justify-content-center align-items-center">
          <!-- new-logo-light -->
          <img
            alt="Logo"
            src="/new-logo-light.svg"
            style="width: 260px;"
          >
        </div>
        <div class="col-4 text-center d-flex flex-column justify-content-end pb-5">
          <label>"Бекітілген"</label>
          <label>
            <p>Оқу-істер жөніндегі проректор:</p>
            <p class="nowrap">{{ prorector }} {{ signPlace }}</p>
          </label>
          <label>
            {{ dateField }}
          </label>
        </div>
      </div>

      <div class="d-flex">
        <div class="col-4 text-center">
          <p class="fw-bolder">
            ЖҰМЫС ОҚУ ЖОСПАРЫ
          </p>
        </div>
        <div class="col-4 text-center">
          <p class="fw-bolder">
            WORKING CURRICULUM
          </p>
        </div>
        <div class="col-4 text-center">
          <p class="fw-bolder">
            РАБОЧИЙ УЧЕБНЫЙ ПЛАН
          </p>
        </div>
      </div>

      <div class="d-flex">
        <div class="col-4 text-start">
          <p><strong>Білім беру бағдарламасы:</strong> {{ workingCurriculum?.program.code }} - {{ workingCurriculum?.program.title_kz }}</p>
          <p><strong>Берілетін дәреже:</strong> {{ workingCurriculum?.degree.kz }}</p>
        </div>
        <div class="col-4 text-start">
          <p><strong>Program:</strong> {{ workingCurriculum?.program.code }} - {{ workingCurriculum?.program.title_en }}</p>
          <p><strong>Degree:</strong> {{ workingCurriculum?.degree.en }}</p>
        </div>
        <div class="col-4 text-start">
          <p><strong>Образовательная программа:</strong> {{ workingCurriculum?.program.code }} - {{ workingCurriculum?.program.title_ru }}</p>
          <p><strong>Присуждаемая степень:</strong> {{ workingCurriculum?.degree.ru }}</p>
        </div>
      </div>

      <div class="text-center my-5">
        <p>{{ workingCurriculum?.years_period.start }} - {{ workingCurriculum?.years_period.end }} оқу жылына / на {{ workingCurriculum?.years_period.start }} - {{ workingCurriculum?.years_period.end }} год / {{ workingCurriculum?.years_period.start }} - {{ workingCurriculum?.years_period.end }}  academic year</p>
      </div>
    </div>

    <!-- Table -->
    <div>
      <table class="table table-bordered border-dark">
        <thead>
          <tr>
            <td />
            <td>Portal Code</td>
            <td>Course code and title</td>
            <td>Credits</td>
            <td>ECTS</td>
            <td>Contact hours</td>
            <td>Course type</td>
            <td>Grading type</td>
          </tr>
        </thead>
        <tbody>
          <!-- Iterate over terms -->
          <template
            v-for="curriculum in groupedCoursesByTerm"
            :key="curriculum.term"
          >
            <!-- Semester row -->
            <tr>
              <td colspan="6">
                <div class="text-center">
                  {{ curriculum.term }} semester
                </div>
              </td>
              <td colspan="2" />
            </tr>

            <!-- No elective courses first -->
            <tr
              v-for="course in curriculum.courses.noElectiveCourses"
              :key="course.id"
            >
              <td />
              <td>{{ course.course_code }}</td>
              <td>{{ course.title }}</td>
              <td class="text-center">
                {{ course.credits }}
              </td>
              <td class="text-center">
                {{ course.ects }}
              </td>
              <td class="text-center">
                {{ [course.theory, course.practice, course.lab].join('+') }}
              </td>
              <td>{{ course.course_type }}</td>
              <td>{{ course.grading_type }}</td>
            </tr>

            <!-- Then elective courses -->
            <template
              v-for="([groupKey, group]) in curriculum.courses.electiveCourses"
              :key="groupKey"
            >
              <tr
                v-for="(course, index) in group.courses"
                :key="course.id"
              >
                <!-- Первая колонка для elective_code, отображаем только один раз для группы -->
                <td
                  v-if="index === 0"
                  :rowspan="group.courses.length"
                >
                  {{ group.elective_code }}
                </td>
                <td>{{ course.course_code }}</td>
                <td>{{ course.title }}</td>
                <td class="text-center">
                  {{ course.credits }}
                </td>
                <!-- Отображаем ECTS только один раз для всей группы -->
                <td
                  v-if="index === 0"
                  :rowspan="group.courses.length"
                  class="align-middle text-center"
                >
                  {{ course.ects }}
                </td>
                <td class="text-center">
                  {{ [course.theory, course.practice, course.lab].join('+') }}
                </td>
                <td>{{ course.course_type }}</td>
                <td>{{ course.grading_type }}</td>
              </tr>
            </template>

            <!-- Total row -->
            <tr>
              <td colspan="4">
                Total
              </td>
              <td>{{ curriculum.total_ects }}</td>
              <td colspan="4" />
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import useWorkingCurriculaData from '../composable/useWorkingCurriculaData'

const { t } = useI18n()

const dateField = '"____"_____________20___ж.'
const signPlace = '_________'

const {
  prorector,
  educationalMethodicalDirector,
  workingCurriculum,
  printContent,
  groupedCoursesByTerm
} = useWorkingCurriculaData()
</script>
<style scoped>
#workingCurricula {
  font-family: 'Times New Roman', Times, serif;
}
.nowrap {
  white-space: nowrap;
}
</style>
