import { SmartTableHeader } from '@/shared/types'

export const resourceProgramTable: Array<SmartTableHeader> = [
  {
    title: 'common.table.code',
    field: 'code',
    slotCellName: 'code',
    sortable: true,
    cellClass: 'text-center'
  },
  {
    title: 'common.table.academic-level',
    field: 'education_level',
    slotCellName: 'level',
    cellClass: 'text-center'
  },
  {
    title: 'common.table.cipher',
    field: 'cipher',
    slotCellName: 'cipher',
    sortable: true,
    cellClass: 'text-center'
  },
  {
    title: 'common.table.title',
    field: 'title',
    slotCellName: 'title',
    sortable: true,
    cellClass: 'text-start'
  },
  {
    title: 'common.table.language',
    field: 'language',
    slotCellName: 'language',
    cellClass: 'text-center'
  },
  {
    title: 'common.table.terms-count',
    field: 'terms_count',
    slotCellName: 'termsCount',
    sortable: true,
    cellClass: 'text-center'
  },
  {
    title: 'common.table.faculty',
    field: 'faculty',
    slotCellName: 'faculty',
    cellClass: 'text-center'
  },
  {
    title: 'common.table.action',
    field: 'action',
    slotCellName: 'action',
    cellClass: 'text-center'
  }
]

export const requestCourseTable: Array<SmartTableHeader> = [
  {
    title: 'common.no',
    field: 'no',
    slotCellName: 'no'
  },
  {
    title: 'common.table.course-code',
    field: 'course_code',
    sortable: true,
    cellClass: 'text-center'
  },
  {
    title: 'common.table.equicode',
    field: 'equicode'
  },
  {
    title: 'common.table.course-title',
    field: 'title',
    slotCellName: 'title',
    sortable: true
  },
  {
    title: 'common.table.term',
    field: 'period_number',
    sortable: true,
    cellClass: 'text-center'
  },
  {
    title: 'common.table.theory',
    field: 'theory',
    sortable: true,
    cellClass: 'text-center'
  },
  {
    title: 'common.table.practice',
    field: 'practice',
    cellClass: 'text-center'
  },
  {
    title: 'common.table.ects',
    field: 'ects',
    cellClass: 'text-center'
  },
  {
    title: 'common.table.action',
    field: 'action',
    slotCellName: 'action',
    cellClass: 'text-center'
  }
]

export const requestElectiveCourseTable: Array<SmartTableHeader> = [
  {
    title: 'common.no',
    field: 'no',
    slotCellName: 'no'
  },
  {
    title: 'common.table.course-code',
    field: 'course_code',
    sortable: true,
    cellClass: 'text-center'
  },
  {
    title: 'common.table.equicode',
    field: 'equicode'
  },
  {
    title: 'common.table.course-title',
    field: 'title',
    slotCellName: 'title',
    sortable: true
  },
  {
    title: 'common.table.term',
    field: 'term',
    slotCellName: 'term',
    sortable: true,
    cellClass: 'text-center'
  },
  {
    title: 'common.table.theory',
    field: 'theory',
    sortable: true,
    cellClass: 'text-center'
  },
  {
    title: 'common.table.practice',
    field: 'practice',
    cellClass: 'text-center'
  },
  {
    title: 'common.table.ects',
    field: 'ects',
    cellClass: 'text-center'
  },
  {
    title: 'common.table.action',
    field: 'action',
    slotCellName: 'action',
    cellClass: 'text-center'
  }
]
