import { AcademicSingleStudentCurriculumGetResponse } from '@/domains/AcademicSingleStudent/store/api/methods/getAcademicSingleStudentCurriculum/types'
import getActualGradeDetail from './getActualGradeDetail'

export default (details: AcademicSingleStudentCurriculumGetResponse[number]['groups'][0]['items'][0]['details']): string => {
  if (!details.length) return '#0a0ace'
  const detail = getActualGradeDetail(details)
  return detail.letter_grade === 'IP'
    ? '#ffad4c'
    : detail.letter_grade === 'W'
      ? '#0a0ace'
      : detail.letter_grade === 'AW'
        ? '#ff0000'
        : detail.grade < 50
          ? '#ff0000'
          : '#1ba018'
}
