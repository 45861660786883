<template>
  <Card>
    <template #title>
      {{ period }}
    </template>
    <template #content>
      <DataTable :value="courses">
        <Column
          field="course_code"
          :header="t('common.table.course_code')"
        />
        <Column
          field="course_title"
          :header="t('common.table.course_title')"
          footer-class="text-danger"
        />
        <Column
          field="credits"
          :header="t('common.table.credits')"
          :footer="gpa?.credits"
          footer-class="text-danger"
        />
        <Column
          field="ects"
          :header="t('common.table.ects')"
          :footer="gpa?.ects"
          footer-class="text-danger"
        />
        <Column
          field="grade"
          :header="t('common.table.grade')"
          :footer="'SA: ' + gpa?.sa"
          footer-class="text-danger"
        />
        <Column
          field="grade_letter"
          :header="t('common.table.grade_letter')"
          :footer="'GA: ' + gpa?.ga"
          footer-class="text-danger"
        />
        <Column
          field="grade_point"
          :header="t('common.table.grade_point')"
          :footer="'SPA: ' + gpa?.spa"
          footer-class="text-danger"
        />
        <Column
          field="grade_title"
          :header="t('common.table.traditional')"
          :footer="'GPA: ' + gpa?.gpa"
          footer-class="text-danger"
        />
      </DataTable>
    </template>
  </Card>
</template>

<script setup lang="ts">
import { AcademicSingleStudentTranscriptGetResponse } from '@/domains/AcademicSingleStudent/store/api/methods/getAcademicSingleStudentTranscript/types'
import { PropType, ref } from 'vue'
import Card from 'primevue/card'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { useI18n } from 'vue-i18n'

defineProps({
  courses: {
    type: Object as PropType<AcademicSingleStudentTranscriptGetResponse['programs'][number]['courses']>,
    required: true
  },
  period: {
    type: String,
    required: true
  },
  gpa: {
    type: Object as PropType<AcademicSingleStudentTranscriptGetResponse['programs'][number]['gpa'][number]>,
    reqired: true
  }
})

const { t } = useI18n()
</script>
<style scoped>
</style>
