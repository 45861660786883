<template>
  <pms-card
    v-if="students !== null"
    :header="false"
  >
    <pms-loader v-if="loader" />
    <pms-smart-table
      v-else
      id="all-students-table"
      :items="students"
      :headers="allStudentsTable"
      :sortable="true"
      :options="{
        wrapper: {
          responsive: false
        }
      }"
    >
      <template #checkboxHeader>
        <pms-checkbox
          class="d-flex justify-content-center"
          :variant="themeMode == 'dark' ? 'solid' : 'default'"
          :disabled="students === null || students.length === 0"
          @on-check="handleSelectAll()"
          @on-uncheck="handleUnselectAll()"
        />
      </template>
      <template #checkbox="{ row }">
        <pms-checkbox
          class="d-flex justify-content-center"
          :state="isSelected(row)"
          @on-check="handleSelect(row)"
          @on-uncheck="handleUnselect(row)"
        />
      </template>
      <template
        #id-number="{ item }"
      >
        <div class="text-start" />
        {{ item.id }}
      </template>
      <template #full_name="{ item }">
        <div class="text-start fw-bolder">
          <div
            class="d-flex justify-content-start flex-column"
          >
            <p class="text-gray-800 fw-bold text-hover-primary mb-1 fs-6">
              {{ item.full_name }}
            </p>
          </div>
        </div>
      </template>
      <template #class="{ item }">
        <div class="fw-bolder">
          {{ item.class }}
        </div>
      </template>
      <template #speciality="{ item }">
        <div class="d-flex align-items-center justify-content-start gap-1">
          <span class="text-gray-800 fw-bold fs-6 d-block flex-shrink-0">
            {{ `(${item.speciality.program_code}) ${item.speciality.title}` }}
          </span>
        </div>
      </template>
      <template #language_level="{ item }">
        <div class="d-flex align-items-center justify-content-center gap-1">
          <span class="text-gray-800 fw-bold fs-6 d-block flex-shrink-0">
            {{ getLevel(item)?.title }} ({{ getLevel(item)?.code }})
          </span>
        </div>
      </template>
      <template #action="{item}">
        <pms-dropdown
          :group-class="'w-100px'"
          :icon-placement="'end'"
          :placement="'end'"
        >
          <div class="w-150px menu-item pt-0 pb-0 text-left">
            <a
              ref="javascript:void(0)"
              class="menu-link px-3 text-left"
              @click="setLanguageAction(item)"
            >
              {{ $t('common.set') }}
            </a>
          </div>
        </pms-dropdown>
      </template>
    </pms-smart-table>
    <action-buttons
      v-show="selectedStudents && selectedStudents.length > 0"
      :students="selectedStudents"
    />
    <LanguageModal v-if="modifier" />
    <pms-pagination
      class="p-0 m-0 mt-6"
    />
  </pms-card>
  <div class="student-tooltip">
    <img
      class="student-image"
      src=""
    >
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { useLoader, useTeleportModal } from '@shared/composable'
import { useAllStudents } from '../../store'
import { allStudentsTable, selectedDatasForModal, selectedStudents } from '../../values'
import { themeMode } from '@/shared/composable/pattern'
import { storeToRefs } from 'pinia'
import ActionButtons from './ActionButtons.vue'
import LanguageModal from '../LanguageModal.vue'
import useResource from '@/shared/stores/useResource'
import { ServiceManageLanguagesStudentsGetResponse } from '../../store/methods/getStudents/type'
import { ServiceManageLanguagesApplicantsGetResponse } from '../../store/methods/getApplicants/type'

const { isActive } = useLoader()
const store = useAllStudents()
const loader = isActive('academic/students')
const { modifier, setModifier, open } = useTeleportModal()
const { students } = storeToRefs(store)
const resource = useResource()
const levels = computed(() => resource.getServiceManageLanguagesResourcesLanguageLevels)

const handleSelect = (student: ServiceManageLanguagesStudentsGetResponse['students'][0]) => {
  selectedStudents.value.push(student)
}

const getLevel = (item: ServiceManageLanguagesApplicantsGetResponse['applicants'][0]) => {
  const foundLevel = levels.value.find(data => data.id === item.language_level_id)

  if (foundLevel) {
    return foundLevel
  }
}

const handleUnselect = (student: ServiceManageLanguagesStudentsGetResponse['students'][0]) => {
  selectedStudents.value = selectedStudents.value.filter((s) => s.id !== student.id)
}

const handleSelectAll = () => {
  selectedStudents.value = students.value ? students.value : []
}

const handleUnselectAll = () => {
  selectedStudents.value = []
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const setLanguageAction = (item: any) => {
  selectedDatasForModal.value = []
  setModifier('language-modal',
    { student: item.id },
    { title: 'common.set-all' }
  )
  open({ name: 'language-modal' })
}

const isSelected = (student: ServiceManageLanguagesStudentsGetResponse['students'][0]) => {
  return selectedStudents.value.some((s) => s.id === student.id)
}
</script>

<style scoped>
.student-tooltip {
  position: absolute;
  z-index: 999;
  display: none;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  transition: all 0.3s ease-in-out;
  width: 200px;
  pointer-events: none;

  .student-image {
    width: 100%;
    height: 100%;
    min-height: 250px;
    object-fit: cover;
  }
}
</style>
