import { RouteRecordWithLayout } from '@shared/types'
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { useServiceSurveys } from './store'

const handleRoute = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  console.log('debug handleRoute')

  const store = useServiceSurveys()
  store.$reset()

  store.bootstrap()
  next()
}

export default {
  layout: 'default',
  path: '/surveys',
  children: [
    {
      path: '',
      name: 'Surveys',
      component: () => import('./ServicesSurveys.vue'),
      beforeEnter: [
        handleRoute
      ]
    },
    {
      path: '/surveys/:id',
      name: 'Survey',
      component: () => import('./features/SurveyPage/SurveyPage.vue'),
      beforeEnter: [

      ]
    }
  ]
} as RouteRecordWithLayout
