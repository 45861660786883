<template>
  <template v-if="loader">
    <pms-loader />
  </template>
  <div v-else>
    <pms-card
      :header="false"
      :title="'domains.academic.syllabus.editor.basic.title'"
      :description="'domains.academic.syllabus.editor.basic.description'"
      class="mb-3 pt-5"
      :options="{ body: { class: 'pt-0'} }"
    >
      <syllabus-course-information
        :overview="overview"
      />
      <syllabus-information-requisites />
      <syllabus-information-programs />
    </pms-card>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useLoader } from '@shared/composable'

import { useSingleCourse } from '@/domains/AcademicSingleCourse/store'

import SyllabusCourseInformation from '../../../ui/SyllabusCourseInformation.vue'
import SyllabusInformationRequisites from '../../../ui/SyllabusInformationRequisites.vue'
import SyllabusInformationPrograms from '../../../ui/SyllabusInformationPrograms.vue'

const singleCourseStore = useSingleCourse()

const overview = computed(() => singleCourseStore.getSyllabusOverview)

const { isActive } = useLoader()
const loader = isActive('academic/manage/syllabus')
</script>
