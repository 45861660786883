import type { StoreType } from '../types'
import { StatusMessageResponse } from '@/shared/types'
import SectionManagementNewRequestCoreCoursesGetRequest from '../api/types/SectionManagementNewRequestCoreCoursesGetRequest'
import getSectionManagementNewRequestCoreCourses from '@/shared/api/SectionManagement/getSectionManagementNewRequestCoreCourses'

export default async function fetchRequestCourse (this: StoreType, params: SectionManagementNewRequestCoreCoursesGetRequest): Promise<StatusMessageResponse> {
  const { data, ok, message } = await getSectionManagementNewRequestCoreCourses(params)

  if (ok) {
    this.setRequestCourses(data)
  } else {
    this.setRequestCourses([])
  }
  return {
    status: ok,
    message: message
  }
}
