<template>
  <FilterForm />
  <SentRequestsTable class="mt-5" />
  <!-- <pms-card :header="false">
    <ProgramStep v-if="sentRequestsStore.step === 'program'" />
    <CourseStep v-if="sentRequestsStore.step === 'course'" />
  </pms-card> -->
  <pms-teleport-modal
    :name="'sent-requests-modal'"
    :teleport-name="'sent-requests-content'"
    :title="modifier?.translate?.title "
    :description="$t(`academic.manage.section.sent-requests.${modifier?.translate?.description || 'description'}`)"
    :keyboard="false"
    :backdrop="false"
    :options="{
      content: {class: 'rounded'},
      header: {class: 'pb-0 border-0 justify-content-between'},
      body: {class: 'scroll-y pt-0'}
    }"
  />
  <sent-requests-modal v-if="modifier && modifier.name === 'sent-requests'" />
</template>
<script setup lang="ts">
import { onBeforeUnmount } from 'vue'
import { useTeleportModal } from '@/shared/composable'

import SentRequestsModal from './ui/SentRequestsModal.vue'
import FilterForm from './ui/FilterForm/FilterForm.vue'

import { useSentRequests } from './store'
import SentRequestsTable from './ui/SentRequestsTable/SentRequestsTable.vue'

const { modifier } = useTeleportModal()
const sentRequestsStore = useSentRequests()

onBeforeUnmount(() => {
  sentRequestsStore.$reset()
})
</script>
