import { SmartTableHeader } from '@/shared/types'

export const tableHeader: Array<SmartTableHeader> = [
  {
    title: 'common.table.no',
    field: 'no',
    slotCellName: 'no'
  },
  {
    title: 'common.table.course_code',
    field: 'course-code',
    slotCellName: 'course-code',
    sortable: true,
    cellClass: 'text-center'
  },
  {
    title: 'common.table.note',
    field: 'note',
    slotCellName: 'note',
    cellClass: 'text-center'
  },
  {
    title: 'common.table.actions',
    field: 'actions',
    slotCellName: 'actions',
    cellClass: 'text-center'
  }
]
