<template>
  <div>
    <pms-page-shimmer
      v-if="loader"
      :shimmer="'list'"
      :filter="true"
      :full-width="true"
    />
    <template v-else>
      <StudentInformation />
      <div
        v-for="(program) in transcript?.programs"
        :key="program.title"
        class="mt-5"
      >
        <ProgramContent
          :program="program"
        />
      </div>
      <transcript-description
        :locale="locale"
        :theme-mode="themeMode"
        class="mt-5"
      />
    </template>
  </div>
</template>
<script setup lang="ts">
import { computed, onMounted } from 'vue'
import { useLoader, useDriver } from '@shared/composable'
import { quickTourTranscript } from '../../values'
import TranscriptDescription from './ui/TranscriptDescription.vue'
import StudentInformation from './ui/StudentInformation.vue'
import { useI18n } from 'vue-i18n'
import { themeMode } from '@shared-composable/pattern'
import { useSingleStudent } from '../../store'
import { storeToRefs } from 'pinia'
import ProgramContent from './ui/ProgramContent/ProgramContent.vue'

const store = useSingleStudent()
const { transcript } = storeToRefs(store)

const { isActiveOneOf } = useLoader()
const { setSteps: setQuickTourSteps } = useDriver()
const i18n = useI18n()
const locale = computed(() => i18n.locale.value)

const loader = isActiveOneOf(['academic/students/resources', 'academic/students/transcript'])

onMounted(async () => {
  setQuickTourSteps(await quickTourTranscript())
})
</script>
