import { AcademicSingleStudentCurriculumGetResponse } from '@/domains/AcademicSingleStudent/store/api/methods/getAcademicSingleStudentCurriculum/types'
import { TotalCourse } from '../types'

export default (courses: (AcademicSingleStudentCurriculumGetResponse[number]['groups'][0])['items']): Array<TotalCourse> => {
  const credits: TotalCourse = {
    title: '',
    theoreticalCourse: 0,
    nonTheoreticalCourse: 0,
    sum: 0
  }
  const ects: TotalCourse = {
    title: '',
    theoreticalCourse: 0,
    nonTheoreticalCourse: 0,
    sum: 0
  }
  courses.forEach((course) => {
    credits.title = 'domains.editor.curricula.readonly.local-credits'
    credits.theoreticalCourse += course.theory > 0 ? course.credits : 0
    credits.nonTheoreticalCourse += course.theory === 0 ? course.credits : 0
    credits.sum += course.credits

    ects.title = 'domains.editor.curricula.readonly.ects'
    ects.theoreticalCourse += course.theory > 0 ? course.ects : 0
    ects.nonTheoreticalCourse += course.theory === 0 ? course.ects : 0
    ects.sum += course.ects
  })
  return [credits, ects]
}
