import { StatusMessageResponse } from '@/shared/types'
import type { StoreType } from '../types'
import getServiceSurveys from '../api/methods/getSurveys'

export default async function fetchSurveys (this: StoreType): Promise<StatusMessageResponse> {
  const { ok, data, message } = await getServiceSurveys()

  if (ok) {
    this.surveys = data
  }

  return {
    status: ok,
    message
  }
}
