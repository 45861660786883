import { SmartTableHeader } from '@/shared/types'

export const tableHeader: Array<SmartTableHeader> = [
  {
    title: 'common.table.class',
    field: 'class',
    cellClass: 'text-center',
    slotCellName: 'class',
    headerClass: 'text-center'
  },
  {
    title: 'common.table.enrolled',
    field: 'enrolled',
    slotCellName: 'enrolled'
  },
  {
    title: 'common.table.passed',
    field: 'passed',
    slotCellName: 'passed',
    cellClass: 'text-center',
    headerClass: 'text-center'
  },
  {
    title: 'common.table.failed',
    field: 'failed',
    slotCellName: 'failed',
    cellClass: 'text-center',
    headerClass: 'text-center'
  },
  {
    title: 'common.table.will-take',
    field: 'will_take',
    slotCellName: 'will_take',
    cellClass: 'text-center',
    headerClass: 'text-center'
  }
]
