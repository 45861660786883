import { LayoutPages } from '@shared-types/pattern/config'

const SidebarMenu: Array<LayoutPages> = [
  {
    heading: 'layout.sidebar.menu.dashboard.heading',
    pages: [
      {
        sectionTitle: 'layout.sidebar.menu.dashboard.pages.dashboard',
        route: '/dashboard',
        isRoute: true,
        svgIcon: '/media/icons/duotune/general/gen022.svg'
      }
    ]
  },
  {
    heading: 'layout.sidebar.menu.academic.heading',
    pages: [
      /*
      {
        sectionTitle: 'layout.sidebar.menu.academic.pages.academic-panel',
        route: '/academic',
        isRoute: true,
        svgIcon: '/media/icons/duotune/abstract/abs048.svg'
      },
      */
      {
        sectionTitle: 'layout.sidebar.menu.academic.pages.courses',
        route: '/academic/courses',
        isRoute: false,
        svgIcon: '/media/icons/duotune/files/fil012.svg',
        subPages: [
          {
            sectionTitle: 'layout.sidebar.menu.academic.pages.all-courses',
            route: '/academic/courses',
            moduleCode: 'show_courses',
            isRoute: true
          },
          {
            sectionTitle: 'layout.sidebar.menu.academic.pages.my-courses',
            route: '/academic/courses/my-courses',
            moduleCode: 'show_courses',
            isRoute: true
          },
          {
            sectionTitle: 'layout.sidebar.menu.academic.pages.my-sections',
            route: '/academic/courses/my-sections',
            moduleCode: 'show_courses',
            isRoute: true
          },
          {
            sectionTitle: 'layout.sidebar.menu.academic.pages.my-schedule',
            route: '/academic/courses/my-schedule',
            moduleCode: 'show_course_schedule',
            isRoute: true
          },
          {
            sectionTitle: 'layout.sidebar.menu.academic.pages.consent-requests',
            route: '/academic/courses/consent-requests',
            moduleCode: 'show_consent_requests',
            isRoute: true
          }
        ]
      },
      {
        sectionTitle: 'layout.sidebar.menu.academic.pages.management',
        route: '/academic/manage',
        isRoute: false,
        svgIcon: '/media/icons/duotune/general/gen019.svg',
        subPages: [
          {
            sectionTitle: 'layout.sidebar.menu.academic.pages.sections',
            route: '/academic/manage/section-management',
            moduleCode: 'show_section_management',
            isRoute: true
          },
          {
            sectionTitle: 'layout.sidebar.menu.academic.pages.schedule-management',
            route: '/academic/manage/schedule-management',
            moduleCode: 'show_schedule_management',
            isRoute: true
          }
        ]
      },
      {
        sectionTitle: 'layout.sidebar.menu.academic.pages.curricula',
        route: '/academic/curricula',
        isRoute: true,
        moduleCode: 'show_curriculla',
        svgIcon: '/media/icons/duotune/finance/fin001.svg'
      },
      {
        sectionTitle: 'layout.sidebar.menu.academic.pages.syllabuses',
        route: '/academic/syllabuses',
        moduleCode: 'show_syllabus',
        isRoute: true,
        svgIcon: '/media/icons/duotune/general/gen059.svg'
      },
      {
        sectionTitle: 'layout.sidebar.menu.academic.pages.exam-dates',
        route: '/academic/exam-dates',
        isRoute: true,
        moduleCode: 'show_exam_dates',
        svgIcon: '/media/icons/duotune/general/gen013.svg'
      },
      {
        sectionTitle: 'layout.sidebar.menu.academic.pages.all-schedule',
        route: '/academic/schedule',
        isRoute: true,
        // moduleCode: '', // todo add code
        svgIcon: '/media/icons/duotune/files/fil002.svg'
      }
    ]
  },
  {
    heading: 'layout.sidebar.menu.administrative.heading',
    pages: [

      {
        sectionTitle: 'layout.sidebar.menu.academic.pages.students',
        route: '/academic/students',
        isRoute: false,
        svgIcon: '/media/icons/duotune/technology/teh002.svg',
        subPages: [
          {
            sectionTitle: 'layout.sidebar.menu.academic.pages.all-students',
            route: '/academic/students',
            moduleCode: 'show_student_information',
            isRoute: true
          },
          {
            sectionTitle: 'layout.sidebar.menu.academic.pages.my-advisory',
            route: '/academic/students/my-advisory',
            // moduleCode: '', // todo add code
            isRoute: true
          }
        ]
      },
      {
        sectionTitle: 'layout.sidebar.menu.administrative.pages.staff-list',
        route: '/administrative/staff-list',
        isRoute: true,
        moduleCode: 'show_staff_list',
        svgIcon: '/media/icons/duotune/communication/com014.svg'
      },
      {
        sectionTitle: 'layout.sidebar.menu.administrative.pages.system-calendar',
        route: '/administrative/system-calendar',
        isRoute: true,
        moduleCode: 'show_system_calendar',
        svgIcon: '/media/icons/duotune/general/gen014.svg'
      },
      {
        sectionTitle: 'layout.sidebar.menu.administrative.pages.gate-entry',
        route: '/administrative/gate-entry',
        isRoute: true,
        moduleCode: 'show_gate_entry_records',
        svgIcon: '/media/icons/duotune/general/gen018.svg'
      },
      {
        sectionTitle: 'layout.sidebar.menu.administrative.pages.rules-and-regulations',
        route: '/administrative/rules-and-regulations',
        isRoute: true,
        moduleCode: 'show_rules_and_regulations',
        svgIcon: '/media/icons/duotune/general/gen056.svg'
      },
      {
        sectionTitle: 'layout.sidebar.menu.academic.pages.reports',
        route: '/academic/reports',
        isRoute: true,
        moduleCode: 'show_reports',
        svgIcon: '/media/icons/duotune/general/gen005.svg'
      },
      {
        sectionTitle: 'layout.sidebar.menu.administrative.pages.message-board',
        route: '/administrative/message-board',
        isRoute: true,
        moduleCode: 'show_message_board',
        svgIcon: '/media/icons/duotune/abstract/abs018.svg'
      }
    ]
  },
  {
    heading: 'layout.sidebar.menu.service.heading',
    pages: [
      {
        svgIcon: '/media/icons/duotune/general/gen005.svg',
        sectionTitle: 'layout.sidebar.menu.service.pages.surveys',
        route: '/surveys',
        isRoute: true
        // moduleCode: 'show_services'
      },
      {
        svgIcon: '/media/icons/duotune/communication/com014.svg',
        sectionTitle: 'layout.sidebar.menu.service.pages.advisor-assignment',
        route: '/advisor-assignment',
        isRoute: true,
        moduleCode: 'show_advisor_assignment'
      },
      {
        sectionTitle: 'layout.sidebar.menu.service.pages.service-panel',
        route: '/services',
        isRoute: false,
        svgIcon: '/media/icons/duotune/coding/cod006.svg',
        subPages: [
          {
            sectionTitle: 'layout.sidebar.menu.service.pages.service-list',
            route: '/services',
            isRoute: true,
            moduleCode: 'show_services'
          },
          {
            sectionTitle: 'layout.sidebar.menu.service.pages.helpdesk-service',
            route: '/services/helpdesk-service',
            isRoute: true
            // moduleCode: '', // todo add code
          },
          {
            sectionTitle: 'layout.sidebar.menu.service.pages.dms-service',
            route: '/services/dms-service',
            isRoute: true
            // moduleCode: '', // todo add code
          },
          {
            sectionTitle: 'layout.sidebar.menu.service.pages.mail-service',
            route: '/services/mail-service',
            isRoute: true
            // moduleCode: '', // todo add code
          },
          {
            sectionTitle: 'layout.sidebar.menu.service.pages.website-service',
            route: '/services/website-service',
            isRoute: true
            // moduleCode: '', // todo add code
          },
          {
            sectionTitle: 'layout.sidebar.menu.service.pages.library-service',
            route: '/services/library-service',
            isRoute: true
            // moduleCode: '', // todo add code
          },
          {
            sectionTitle: 'layout.sidebar.menu.service.pages.moodle-service',
            route: '/services/moodle-service',
            isRoute: true
            // moduleCode: '', // todo add code
          }
        ]
      },
      {
        sectionTitle: 'layout.sidebar.menu.service.pages.message-broadcast.title',
        route: '/services/message-broadcast',
        isRoute: false,
        svgIcon: '/media/icons/duotune/abstract/abs018.svg',
        subPages: [
          {
            sectionTitle: 'layout.sidebar.menu.service.pages.message-broadcast.all-messages',
            route: '/services/message-broadcast?mode=inbox',
            isRoute: true
            // moduleCode: '', // todo add code
          },
          {
            sectionTitle: 'layout.sidebar.menu.service.pages.message-broadcast.new-message',
            route: '/services/message-broadcast/new-message',
            isRoute: true
            // moduleCode: '', // todo add code
          }
        ]
      },
      {
        sectionTitle: 'layout.sidebar.menu.academic.pages.lock-management',
        route: '/service/manage/locks',
        isRoute: true,
        moduleCode: 'show_lock_management',
        svgIcon: '/media/icons/duotune/general/gen047.svg'
      },
      {
        sectionTitle: 'layout.sidebar.menu.service.pages.languages',
        route: '/service/manage/languages',
        isRoute: true,
        moduleCode: 'show_language_levels',
        svgIcon: '/media/icons/duotune/maps/map004.svg'
      },
      {
        sectionTitle: 'layout.sidebar.menu.service.pages.announcements',
        route: '/service/manage/announcements',
        isRoute: true,
        moduleCode: 'show_announcement',
        svgIcon: '/media/icons/duotune/communication/com004.svg'
      },
      {
        sectionTitle: 'layout.sidebar.menu.service.pages.esign',
        route: '/service/e-sign',
        isRoute: true,
        moduleCode: 'show_esing',
        svgIcon: '/media/icons/duotune/art/art008.svg'
      }
    ]
  },
  {
    heading: 'layout.sidebar.menu.manager.heading',
    pages: [
      {
        sectionTitle: 'layout.sidebar.menu.manager.pages.knowledge-base',
        route: '/manager/knowledge-base',
        isRoute: false,
        svgIcon: '/media/icons/duotune/abstract/abs014.svg',
        subPages: [
          {
            sectionTitle: 'layout.sidebar.menu.manager.pages.all-categories',
            route: '/knowledge-base/manage/categories',
            isRoute: true
            // moduleCode: '', // todo add code
          },
          {
            sectionTitle: 'layout.sidebar.menu.manager.pages.all-articles',
            route: '/knowledge-base/manage/articles',
            isRoute: true
            // moduleCode: '', // todo add code
          },
          {
            sectionTitle: 'layout.sidebar.menu.manager.pages.new-article',
            route: '/knowledge-base/manage/article/new',
            isRoute: true
            // moduleCode: '', // todo add code
          }
        ]
      }
    ]
  },
  {
    heading: 'layout.sidebar.menu.profile.heading',
    pages: [
      {
        sectionTitle: 'layout.sidebar.menu.profile.pages.profile',
        route: '/profile',
        isRoute: false,
        svgIcon: '/media/icons/duotune/communication/com006.svg',
        subPages: [
          {
            sectionTitle: 'layout.sidebar.menu.profile.sub-pages.overview',
            route: '/profile',
            isRoute: true,
            moduleCode: 'show_profile'
          },
          {
            sectionTitle: 'layout.sidebar.menu.profile.sub-pages.addresses',
            route: '/profile/addresses',
            isRoute: true
            // moduleCode: '', // todo add code
          },
          {
            sectionTitle: 'layout.sidebar.menu.profile.sub-pages.biography',
            route: '/profile/biography',
            isRoute: true
            // moduleCode: '', // todo add code
          },
          {
            sectionTitle: 'layout.sidebar.menu.profile.sub-pages.contacts',
            route: '/profile/contacts',
            isRoute: true
            // moduleCode: '', // todo add code
          },
          {
            sectionTitle: 'layout.sidebar.menu.profile.sub-pages.education',
            route: '/profile/education',
            isRoute: true
            // moduleCode: '', // todo add code
          },
          {
            sectionTitle: 'layout.sidebar.menu.profile.sub-pages.publications',
            route: '/profile/publications',
            isRoute: true
            // moduleCode: '', // todo add code
          },
          {
            sectionTitle: 'layout.sidebar.menu.profile.sub-pages.experience',
            route: '/profile/experience',
            isRoute: true
            // moduleCode: '', // todo add code
          },
          {
            sectionTitle: 'layout.sidebar.menu.profile.sub-pages.skills',
            route: '/profile/skills',
            isRoute: true
            // moduleCode: '', // todo add code
          }
        ]
      },
      {
        sectionTitle: 'layout.sidebar.menu.profile.pages.settings',
        route: '/profile/settings',
        isRoute: true,
        // moduleCode: '', // todo add code
        svgIcon: '/media/icons/duotune/coding/cod009.svg'
      }
    ]
  },
  {
    heading: 'layout.sidebar.menu.release.heading',
    pages: [
      {
        sectionTitle: 'layout.sidebar.menu.release.pages.changelog',
        route: '/changelog',
        isRoute: true,
        // moduleCode: '', // todo add code
        svgIcon: '/media/icons/duotune/coding/cod003.svg'
      }
    ]
  }
]

export default SidebarMenu
