import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type {
  SectionManagementRequestPostRequest,
  UnknownResponse
} from '@shared/swagger'
import SectionManagementNewRequestPostRequest from '@/domains/SectionManagement/features/NewRequest/store/api/types/SectionManagementNewRequestPostRequest'

export default async function postSectionManagementRequest (params: SectionManagementNewRequestPostRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { post } = useRequests()

  const response = await post<UnknownResponse, UnknownResponse>({
    url: 'academic/manage/sections/requests',
    body: params,
    loader: 'academic/manage/sections/requests-post'
  })

  return response
}
