import { StatusMessageResponse } from '@/shared/types'
import type { StoreType } from '../types'
import getDeparments from '../api/methods/getDeparments'

export default async function fetchDepartments (this: StoreType): Promise<StatusMessageResponse> {
  const { ok, data, message } = await getDeparments()

  if (ok) {
    this.departments = data
  }

  return {
    status: ok,
    message
  }
}
