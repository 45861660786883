<template>
  <pms-loader v-if="loader" />
  <div
    v-else
    class="d-flex flex-column"
  >
    <div class="d-flex flex-row flex-end">
      <button
        class="btn btn-primary btn-lg ms-2"
        @click="openModal(-1)"
      >
        {{ $t('common.add') }}
      </button>
    </div>
    <pms-smart-table
      :items="sections"
      :headers="sectionsZoomTableHeader"
      :class="'mt-4'"
      :options="{
        wrapper: { responsive: false}
      }"
    >
      <template #no="{idx}">
        {{ idx + 1 }}
      </template>
      <template #course_code>
        {{ store.selectedCourse?.code ?? '' }}
      </template>
      <template #teacher="{item}">
        {{ item.instructor?.full_name }}
      </template>
      <template #grade_submitter="{item}">
        {{ item.grade_submitter?.full_name }}
      </template>

      <template #typeHeader="{ item }">
        <div class="d-flex gap-3 flex-wrap w-100px">
          {{ $t(item.title) }}
          <Select
            v-model="typeFilter"
            :options="typeOptions"
            option-label="label"
            option-value="id"
            class="form-control"
            type="select"
          />
        </div>
      </template>
      <template #type="{item}">
        {{ item.type.id }}
      </template>

      <template #students="{item}">
        {{ item.students_count }}
      </template>
      <template #action="{item}">
        <pms-dropdown
          :group-class="'w-100px order-5'"
          :icon-placement="'end'"
        >
          <div class="w-150px menu-item pt-0 pb-0 text-left">
            <a
              href="javascript:void(0)"
              class="menu-link px-3 text-left"
              @click="openModal(item.id)"
            >
              {{ $t('common.table.edit') }}
            </a>
            <a
              href="javascript:void(0)"
              class="menu-link px-3 text-left"
              @click="deleteAction(item.id,item.section)"
            >
              {{ $t('common.table.delete') }}
            </a>
          </div>
        </pms-dropdown>
      </template>
    </pms-smart-table>
  </div>
  <SectionsModal v-if="modifier && modifier.name === 'received-requests-section'" />
</template>
<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useLoader, useTeleportModal, useAlert } from '@/shared/composable'
import { useReceivedRequest } from '../store'
import { sectionsZoomTableHeader } from '../values'
import SectionsModal from './SectionsModal.vue'
import Select from 'primevue/select'
import { storeToRefs } from 'pinia'
import { useResource } from '@/shared/stores'

const { t } = useI18n()
const { isActive } = useLoader()
const { setModifier, open, modifier } = useTeleportModal()
const { dialog, success, error } = useAlert()

const store = useReceivedRequest()
const { selectedRequestsSections } = storeToRefs(store)

const loader = isActive('academic/manage/sections')
const resourceStore = useResource()
const { sectionManagementResource } = storeToRefs(resourceStore)

const typeOptions = computed(() => {
  const result = (sectionManagementResource.value ? sectionManagementResource.value.section_types : []).map((i) => ({
    id: i.id,
    label: i.type
  }))

  result.unshift({
    id: '',
    label: t('common.all')
  })

  return result
})
const typeFilter = ref('')
const filteredRequests = ref<typeof selectedRequestsSections | []>([])
const sections = computed(() => {
  if (typeFilter.value.length) {
    return filteredRequests.value
  }
  return selectedRequestsSections.value
})

watch(() => typeFilter.value, () => {
  filterRequests()
})

function filterRequests () {
  filteredRequests.value = selectedRequestsSections.value.filter((req) => req.type.id === typeFilter.value)
}

const openModal = (id: number) => {
  setModifier('received-requests-section',
    { id: id },
    {
      title: store.selectedCourse?.title + ` (${store.selectedCourse?.code})`,
      description: t('common.table.credits') + ': ' + store.selectedCourse?.credits
    }
  )
  open({ name: 'received-requests-modal' })
}

const deleteAction = async (id: number, title?: string) => {
  dialog({
    title: 'domains.academic.manage.sections.received-requests.are-you-sure',
    text: t('domains.academic.manage.sections.received-requests.delete-text', { name: title }),
    onConfirm: async () => {
      const { status, message } = await store.deleteSection(id)
      status
        ? success({ text: message, isToast: true })
        : error({ text: message })
    }
  })
}
</script>
