import type { StoreType } from '../types'
import type { StatusMessageResponse } from '@shared/types'

import { postSectionManagementSections } from '@shared/api'
import SectionManagementSectionsPostRequest from '../api/types/SectionManagementSectionsPostRequest'

export default async function postSingleSection (this: StoreType, values: SectionManagementSectionsPostRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await postSectionManagementSections(values)

  return {
    status: ok,
    message
  }
}
