<template>
  <pms-card :header="false">
    <pms-smart-table
      :items="myCourses"
      :headers="tableHeader"
      :sortable="true"
      :class="'mt-4'"
      :options="{
        wrapper: { responsive: false}
      }"
    >
      <template #no="{idx}">
        {{ idx + 1 }}
      </template>
      <template #course-code="{item}">
        <span
          class="fs-6"
          :data-bs-original-title="item.course_title "
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          data-bs-trigger="hover"
        >
          {{ item.course_code }}
        </span>
      </template>
      <template #note="{item}">
        {{ item.note }}
      </template>
      <template #actions="{item}">
        <template v-if="item.offer_id">
          <div class="d-flex gap-5 flex-center">
            <pms-button
              class="text-white btn"
              :text="'common.edit'"
              @click="editCourseOffer({offer_id: item.offer_id})"
            >
              <i class="bi bi-pencil" />{{ $t('common.edit') }}
            </pms-button>

            <pms-button
              class="text-white btn btn-danger"
              @click="deleteCourseOffer({offerId: item.offer_id})"
            >
              <i class="bi bi-trash" />{{ $t('common.delete') }}
            </pms-button>
          </div>
        </template>

        <pms-button
          v-else
          class="text-white btn"
          @click="createCourseOffer({course_code: item.course_code, year: currentYearTerm.year, term: currentYearTerm.term})"
        >
          <i class="bi bi-plus" />{{ $t('common.create') }}
        </pms-button>
      </template>
    </pms-smart-table>
    <EditCourseOffer v-if="modifier && modifier.name === 'edit-course-offer'" />
    <CreateCourseOffer v-if="modifier && modifier.name === 'create-course-offer'" />
  </pms-card>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { useMyCourses } from '../../store'
import { tableHeader } from './values'
import { storeToRefs } from 'pinia'
import { TEditCourseFormModalData } from './ui/EditCourseOffer/types'
import { useAlert, useTeleportModal } from '@shared/composable'
import EditCourseOffer from './ui/EditCourseOffer/EditCourseOffer.vue'
import CreateCourseOffer from './ui/CreateCourseOffer/CreateCourseOffer.vue'
import { TCreateCourseOfferFormModalData } from './ui/CreateCourseOffer/types'
import { useSectionManagement } from '@/domains/SectionManagement/store'
import SectionManagementDeleteOfferRequest from '../../store/api/types/SectionManagementDeleteOfferRequest'
import UseCoursesFetcher from '../CoursesFilter/composable/UseCoursesFetcher/UseCoursesFetcher'

const { open, modifier, setModifier, show } = useTeleportModal()

const { currentYearTerm } = useSectionManagement()
const { dialog, error, success } = useAlert()
const store = useMyCourses()
const { courses } = storeToRefs(store)

const editCourseOffer = (data: TEditCourseFormModalData) => {
  setModifier('edit-course-offer',
    data,
    { title: 'edit-couse-title', description: 'edit-course-description' }
  )
  show()
  open({ name: 'my-courses-modal' })
}

const { fetchCourses } = UseCoursesFetcher()

const deleteCourseOffer = (data: SectionManagementDeleteOfferRequest) => {
  dialog({
    title: 'common.are-you-sure',
    text: '',
    onConfirm: async () => {
      const { status, message } = await store.deleteCourseOffer(data.offerId)
      if (status) {
        success({ text: message, isToast: true })
        if (store.selectedDepartment) { fetchCourses({ departmentCode: store.selectedDepartment.code }) }
      } else {
        error({ text: message })
      }
    }
  })
}

const createCourseOffer = (data: TCreateCourseOfferFormModalData) => {
  setModifier('create-course-offer',
    data,
    { title: 'create-couse-offer-title', description: 'create-course-offer-description' }
  )
  show()
  open({ name: 'my-courses-modal' })
}

const myCourses = computed(() => {
  return courses.value
})

</script>
