import { SelectField } from '@shared/types'
import { ServiceManageLanguagesResourcesResponse } from '@shared/swagger'

export default (data: ServiceManageLanguagesResourcesResponse['languages']): SelectField['options'] => {
  const options: SelectField['options'] = []

  data.forEach((item) => {
    options.push({
      label: `[${(item.id)}] - ${item.language}`,
      value: item.id
    })
  })

  return options
}
