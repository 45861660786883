import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type {
  UnknownResponse
} from '@shared/swagger'
import SectionManagementCreateOfferPostRequest from '@/domains/SectionManagement/features/MyCourses/store/api/types/SectionManagementCreateOfferPostRequest'

export default async function postSectionManagementOffer (params: SectionManagementCreateOfferPostRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { post } = useRequests()

  const response = await post<UnknownResponse, UnknownResponse>({
    url: 'academic/manage/sections/offers',
    body: params,
    loader: 'academic/manage/sections/offers'
  })

  return response
}
