<template>
  <p class="fs-5 fw-bold p-0 mb-2">
    {{ $t('domains.academic.manage.sections.request-program-component') }}
  </p>
  <div class="d-flex gap-4 mb-4">
    <div
      class="d-flex gap-3 align-items-center"
    >
      <p class="fs-5 m-0">
        {{ $t('common.year') }}
      </p>
      <select
        v-model="year"
        class="form-select form-select-solid form-select-sm w-80px"
        @change="setSelectYear($event)"
      >
        <option :value="null" />
        <option
          v-for="yearItem in years"
          :key="yearItem"
          :value="yearItem"
        >
          {{ yearItem }}
        </option>
      </select>
    </div>
    <div
      class="d-flex gap-3 align-items-center"
    >
      <p class="fs-5 m-0">
        {{ $t('common.term') }}
      </p>
      <select
        v-model="term"
        class="form-select form-select-solid form-select-sm w-80px"
        @change="setSelectTerm($event)"
      >
        <option
          :value="null"
          :disabled="selectedYearTerm.year === null"
        />
        <option
          v-for="termItem in terms"
          :key="termItem"
          :value="termItem"
          :disabled="selectedYearTerm.year === null"
        >
          {{ termItem }}
        </option>
      </select>
    </div>
    <pms-dropdown
      :btn-class="'btn btn-light-primary btn-sm p-2 px-4'"
      :icon-placement="'start'"
      :placement="'start'"
      :items="dropdownItems"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { DropdownMenuItem } from '@shared/types'
import { useNewRequest } from '../store'
import { useRoute } from 'vue-router'
import { useAlert } from '@/shared/composable'
import { useI18n } from 'vue-i18n'

const route = useRoute()
const newRequestStore = useNewRequest()
const selectedYearTerm = newRequestStore.selectedYearTerm
const { error } = useAlert()
const years = computed(() => newRequestStore.getSelectedProgramsYears)
const terms = computed(() => newRequestStore.getSelectedProgramsTerms)

const { t } = useI18n()

const year = computed({
  get: () => newRequestStore.selectedYearTerm.year,
  set: (value) => {
    newRequestStore.selectedYearTerm.year = value
  }
})

const term = computed({
  get: () => newRequestStore.selectedYearTerm.term,
  set: (value) => {
    newRequestStore.selectedYearTerm.term = value
  }
})

const dropdownItems : DropdownMenuItem[] = [
  {
    label: 'domains.academic.manage.section.new-request.show-core',
    menuType: 'link',
    async action () {
      if (selectedYearTerm.term && selectedYearTerm.year) {
        newRequestStore.isFetched = true
        newRequestStore.program_request_term = selectedYearTerm.term
        newRequestStore.program_request_year = selectedYearTerm.year
        if (newRequestStore.selectedProgram) {
          const { status, message } = await newRequestStore.fetchRequestCourses(
            {
              program_year: selectedYearTerm.year,
              period_number: selectedYearTerm.term,
              program_code: newRequestStore.selectedProgram.code,
              year: Number(route.query.year)
            }
          )
          if (!status) {
            error({
              text: message
            })
          }
        }
        newRequestStore.program_request_type = 'C'
      } else {
        useAlert().error({ text: t('domains.academic.section-management.new-request.error.select-year-and-term') })
      }
    }
  },
  {
    label: 'domains.academic.manage.section.new-request.show-elective',
    menuType: 'link',
    async action () {
      if (selectedYearTerm.term && selectedYearTerm.year) {
        newRequestStore.isFetched = true
        newRequestStore.program_request_term = selectedYearTerm.term
        newRequestStore.program_request_year = selectedYearTerm.year
        if (newRequestStore.selectedProgram) {
          const { status, message } = await newRequestStore.fetchRequestElectiveCourses(
            {
              program_code: newRequestStore.selectedProgram.code,
              program_year: selectedYearTerm.year,
              period_number: selectedYearTerm.term,
              year: Number(route.query.year)
            }
          )
          if (!status) {
            error({
              text: message
            })
          }
        }

        newRequestStore.program_request_type = 'E'
      } else {
        useAlert().error({ text: t('domains.academic.section-management.new-request.error.select-year-and-term') })
      }
    }
  }
]

function setSelectYear ($event) {
  if ($event.target.value) {
    selectedYearTerm.year = $event.target.value
  } else {
    selectedYearTerm.year = undefined
  }
}
function setSelectTerm ($event) {
  if ($event.target.value) {
    selectedYearTerm.term = $event.target.value
  } else {
    selectedYearTerm.term = undefined
  }
}

</script>
