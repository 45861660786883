import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type {
  UnknownRequest
} from '@shared/swagger'
import SectionManagementReceivedRequestsQuotasSectionGetRequest from '@/domains/SectionManagement/features/ReceivedRequests/store/api/types/SectionManagementReceivedRequestsQuotasSectionGetRequest'
import SectionManagementReceivedRequestsQuotasSectionGetResponse from '@/domains/SectionManagement/features/ReceivedRequests/store/api/types/SectionManagementReceivedRequestsQuotasSectionGetResponse'

export default async function getSectionManagementRequestQuotas (params: SectionManagementReceivedRequestsQuotasSectionGetRequest): Promise<AxiosResponse<SectionManagementReceivedRequestsQuotasSectionGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, SectionManagementReceivedRequestsQuotasSectionGetResponse>({
    url: 'academic/manage/sections/received/requests/quotas/section',
    body: { params },
    loader: 'get-academic/manage/sections/received/requests/quotas/section'
  })

  return response
}
