import type { StatusMessageResponse } from '@shared/types'
import type { StoreType } from '../types'
import putServiceManageLanguagesApplicants from '../methods/putLanguageApplicants'
import { ServiceManageLanguagesApplicantsBulkPutRequest } from '../methods/putLanguageApplicants/types'
import { applicantsSearchValues } from '../../values'

export default async function putStudents (this: StoreType, values: ServiceManageLanguagesApplicantsBulkPutRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await putServiceManageLanguagesApplicants(values)

  if (ok) {
    this.fetchApplicants(applicantsSearchValues.value)
  }

  return {
    status: ok,
    message
  }
}
