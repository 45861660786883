import type { StoreType } from '../types'
import { postSectionManagementSectionsQuotas } from '@shared/api'
import { StatusMessageResponse } from '@shared/types'
import SectionManagementSectionsQuotasPostRequest from '../api/types/SectionManagementSectionsQuotasPostRequest'

export default async function createReceivedRequestReservations (this: StoreType, params: SectionManagementSectionsQuotasPostRequest): Promise<StatusMessageResponse> {
  const { message, ok } = await postSectionManagementSectionsQuotas(params)

  return {
    status: ok,
    message
  }
}
