import { SmartTableHeader } from '@/shared/types'

export const tableHeader: Array<SmartTableHeader> = [
  {
    title: 'common.table.no',
    field: 'no',
    slotCellName: 'no'
  },
  {
    title: 'common.table.course-code',
    field: 'course-code',
    slotCellName: 'course-code',
    slotName: 'courseCodeHeader',
    sortable: true,
    cellClass: 'text-center'
  },
  {
    title: 'common.table.course-title',
    field: 'course-title',
    slotCellName: 'course-title'
  },
  {
    title: 'common.table.quota',
    field: 'quota'
  },
  {
    title: 'common.table.met',
    field: 'met',
    slotCellName: 'met'
  },
  {
    title: 'common.table.date',
    field: 'create_date',
    headerClass: 'w-150px',
    slotCellName: 'create_date'
  },
  {
    title: 'common.table.status',
    field: 'status',
    slotCellName: 'status',
    cellClass: 'text-center',
    headerClass: 'w-100px'
  },
  {
    title: 'common.table.action',
    field: 'action',
    cellClass: 'text-center',
    slotCellName: 'action'
  }
]
export const quotaTableHeader: Array<SmartTableHeader> = [
  {
    title: 'common.table.no',
    field: 'no',
    slotCellName: 'no'
  },
  {
    title: 'common.table.theory',
    field: 'theory',
    slotCellName: 'type-N',
    cellClass: 'text-center',
    headerClass: 'text-center'
  },
  {
    title: 'common.table.practice',
    field: 'practice',
    slotCellName: 'type-P',
    cellClass: 'text-center',
    headerClass: 'text-center'
  },
  {
    title: 'common.table.lab',
    field: 'lab',
    slotCellName: 'type-L',
    cellClass: 'text-center',
    headerClass: 'text-center'
  },
  {
    title: 'common.table.met',
    field: 'total_quota',
    slotCellName: 'total_quota',
    cellClass: 'text-center',
    headerClass: 'text-center'
  },
  {
    title: 'common.table.reserved',
    field: 'reserves_count',
    slotCellName: 'reserved',
    cellClass: 'text-center',
    headerClass: 'text-center'
  },
  {
    title: 'common.table.students',
    field: 'registered_count',
    slotCellName: 'students',
    cellClass: 'text-center',
    headerClass: 'text-center'
  },
  {
    title: 'common.table.action',
    field: 'action',
    cellClass: 'text-center',
    slotCellName: 'action'
  }
]
