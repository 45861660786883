import { StatusMessageResponse } from '@/shared/types'
import type { StoreType } from '../types'
import getQuestions from '../api/methods/getQuestions'
import { ServiceQuestionaryQuestionsGetRequest } from '../api/methods/getQuestions/types'

export default async function fetchQuestions (this: StoreType, params: ServiceQuestionaryQuestionsGetRequest): Promise<StatusMessageResponse> {
  const { ok, data, message } = await getQuestions(params)

  if (ok) {
    this.questions = data
  }

  return {
    status: ok,
    message
  }
}
