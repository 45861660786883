<template>
  <CoursesFilter class="w-100" />
  <!-- /1.0.0/academic/manage/sections/offers -->
  <CoursesTable class="mt-5" />

  <pms-teleport-modal
    :name="'my-courses-modal'"
    :teleport-name="'my-courses-modal-content'"
    :title="modifier?.translate?.title"
    :description="modifier?.translate?.description"
    :keyboard="false"
    :backdrop="false"
    :size="'xl'"
    :options="{
      content: {class: 'rounded'},
      header: {class: 'pb-0 border-0 justify-content-between'},
      body: {class: 'scroll-y pt-0'}
    }"
  />
</template>
<script lang="ts" setup>
import { useTeleportModal } from '@/shared/composable'
import CoursesFilter from './ui/CoursesFilter/CoursesFilter.vue'
import CoursesTable from './ui/CoursesTable/CoursesTable.vue'

const { modifier } = useTeleportModal()
</script>
