import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type {
  UnknownRequest
} from '@shared/swagger'
import SectionManagementReceivedRequestsGetRequest from '@/domains/SectionManagement/features/ReceivedRequests/store/api/types/SectionManagementReceivedRequestsGetRequest'
import SectionManagementReceivedRequestsGetResponse from '@/domains/SectionManagement/features/ReceivedRequests/store/api/types/SectionManagementReceivedRequestsGetResponse'

export default async function getSectionManagementReceivedRequests (params: SectionManagementReceivedRequestsGetRequest): Promise<AxiosResponse<SectionManagementReceivedRequestsGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, SectionManagementReceivedRequestsGetResponse>({
    url: 'academic/manage/sections/received/requests',
    body: { params: params },
    loader: 'academic/manage/sections/received/requests'
  })

  return response
}
