import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type {
  UnknownRequest, SectionManagementRequestGetResponse
} from '@shared/swagger'
import SectionManagementSectionsQuotasGetRequest from '@/domains/SectionManagement/features/ReceivedRequests/store/api/types/SectionManagementSectionsQuotasGetRequest'

export default async function getSectionManagementSectionsQuotas (params: SectionManagementSectionsQuotasGetRequest): Promise<AxiosResponse<SectionManagementRequestGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, SectionManagementRequestGetResponse>({
    url: 'academic/manage/sections/reserved-programs',
    body: { params },
    loader: 'academic/manage/sections/reserved-programs'
  })

  return response
}
