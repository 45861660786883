<template>
  <Teleport to="#received-requests-content">
    <pms-row>
      <template
        v-for="(side, index) in sides"
        :key="index"
      >
        <pms-grid :col="6">
          <pms-smart-form
            :fields="[side.fields]"
            @handleChange="onChange"
          >
            <template #formControls />
          </pms-smart-form>
        </pms-grid>
      </template>
    </pms-row>
    <div class="form-group mt-3 col-12 d-flex justify-content-end gap-2">
      <pms-button
        variant="link"
        color="danger"
        class="me-5"
        type="reset"
        @click="closeModal"
      >
        {{ $t('common.cancel') }}
      </pms-button>
      <pms-button
        type="submit"
        :text="$t('common.save')"
        @click="onSubmit"
      />
    </div>
  </Teleport>
</template>
<script setup lang="ts">
import { watch, computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { useTeleportModal, useAlert } from '@/shared/composable'
import { FormField, SelectField } from '@/shared/types'

import { useResource } from '@/shared/stores'

import { useSectionManagement } from '@domains/SectionManagement/store'

import { useReceivedRequest } from '../store'
import { toOptionType } from '../utils'
import { objectDeepCopy } from '@/shared/utils'
import SectionManagementSectionsPostRequest from '../store/api/types/SectionManagementSectionsPostRequest'
import { storeToRefs } from 'pinia'

const { t } = useI18n()
const { error, success } = useAlert()
const { show, close, modifier, unsetModifier } = useTeleportModal()

const sectionManagementStore = useSectionManagement()
const resource = useResource()
const { sectionManagementResource } = storeToRefs(resource)
const store = useReceivedRequest()
const { singleSection } = storeToRefs(store)

const attendanceTypes = computed(() => resource.getSectionManagementResourceAttendanceTypes)
const paymentMethods = computed(() => resource.getSectionManagementResourcePaymentMethods)
const instructors = computed(() => resource.getSectionManagementResourceInstructors)
const implementationModes = computed(() => resource.getSectionManagementResourceImplementationModes)
const deliveryModesOptions = computed(() => {
  return (sectionManagementResource.value ? sectionManagementResource.value.delivery_modes : []).map((item) => ({
    value: item.id,
    label: item.mode
  }))
})
const weeksCount: SelectField['options'] = []
for (let i = 1; i < 17; i++) {
  weeksCount.push({ label: String(i), value: i })
}

const onChange = ({ name, newValue }) => {
  if (name === 'section_type') {
    if (newValue === '0') newValue = 'N'
    if (newValue === '1') newValue = 'L'
    if (newValue === '2') newValue = 'P'
  }
  tempValues.value[name] = newValue
  if (name === 'instructor_id' && tempValues.value.grade_submitter_id === -1) {
    tempValues.value.grade_submitter_id = Number(newValue)
  }
}

const hasNoEventAccess = computed(() => {
  return singleSection.value ? !singleSection.value.event_access : false
})
const sectionTypeChangeable = computed(() => {
  return singleSection.value ? singleSection.value.section_type_changeable : true
})

const onSubmit = async () => {
  const { status, message } = modifier.value?.data?.id === -1
    ? await store.postSingleSection(tempValues.value)
    : await store.putSingleSection(Number(modifier.value?.data?.id), tempValues.value)

  if (status) {
    success({ text: message, isToast: true })
    closeModal()
    await store.fetchRequestSections(
      {
        course_code: store.selectedCourse?.code ?? '',
        year: store.selectedCourse?.year ?? -1,
        term: sectionManagementStore.currentYearTerm.term
      })
  } else {
    error({ text: message })
  }
}

const closeModal = () => {
  close({ name: 'received-requests-modal' })
  unsetModifier()
}
const defaultTempValues: SectionManagementSectionsPostRequest = {
  course_code: store.selectedCourse?.code ?? '',
  year: sectionManagementStore.currentYearTerm.year,
  term: sectionManagementStore.currentYearTerm.term,
  section_type: '',
  instructor_id: -1,
  grade_submitter_id: -1,
  message: '',
  implementation_mode: -1,
  payment_method: -1,
  attend_type: -1,
  weeks_count: -1,
  delivery_mode: undefined
}

const tempValues = ref<SectionManagementSectionsPostRequest>(objectDeepCopy(defaultTempValues))

const sides = computed<Array<{side:string, fields: Array<FormField>}>>(() => [
  {
    side: 'left',
    fields: [
      {
        label: {
          text: `${t('domains.academic.manage.section.form-field.teacher')}`,
          class: 'mb-1'
        },
        group: {
          class: 'mb-3'
        },
        name: 'instructor_id',
        type: 'select',
        value: tempValues.value.instructor_id,
        options: toOptionType(instructors.value, 'id', 'full_name'),
        validator: {
          schema: 'yup',
          rules: [
            'number',
            'required'
          ]
        }
      },
      {
        label: {
          text: `${t('domains.academic.manage.section.form-field.grade-submitter')}`,
          class: 'mb-1'
        },
        group: {
          class: 'mb-3'
        },
        name: 'grade_submitter_id',
        type: 'select',
        value: tempValues.value.grade_submitter_id,
        options: toOptionType(instructors.value, 'id', 'full_name'),
        validator: {
          schema: 'yup',
          rules: [
            'number',
            'required'
          ]
        }
      },
      {
        label: {
          text: `${t('domains.academic.manage.section.form-field.section-type')}`,
          class: 'mb-1'
        },
        group: {
          class: 'mb-3'
        },
        name: 'section_type',
        disabled: (!sectionTypeChangeable.value && hasNoEventAccess.value),
        type: 'select',
        value: tempValues.value.section_type === 'N'
          ? 0
          : tempValues.value.section_type === 'L'
            ? 1
            : tempValues.value.section_type === 'P' ? 2 : null,
        options: [
          { value: 0, label: 'Theory' },
          { value: 1, label: 'Laboratory' },
          { value: 2, label: 'Practice' }
        ]
      },
      {
        label: {
          text: `${t('domains.academic.manage.section.form-field.payment-method')}`,
          class: 'mb-1'
        },
        group: {
          class: 'mb-3'
        },
        name: 'payment_method',
        type: 'select',
        value: tempValues.value.payment_method + 1,
        disabled: hasNoEventAccess.value,
        options: toOptionType(paymentMethods.value, 'id', 'method').map(el => ({ label: el.label, value: Number(el.value) + 1 })),
        validator: {
          schema: 'yup',
          rules: [
            'number',
            'required'
          ]
        }
      }
    ]
  },
  {
    side: 'right',
    fields: [
      {
        label: {
          text: `${t('domains.academic.manage.section.form-field.attend-type')}`,
          class: 'mb-1'
        },
        group: {
          class: 'mb-3'
        },
        name: 'attend_type',
        type: 'select',
        disabled: hasNoEventAccess.value,
        value: tempValues.value.attend_type + 1,
        options: toOptionType(attendanceTypes.value, 'id', 'type').map(el => ({ label: el.label, value: Number(el.value) + 1 })),
        validator: {
          schema: 'yup',
          rules: [
            'number',
            'required'
          ]
        }
      },
      {
        label: {
          text: `${t('domains.academic.manage.section.form-field.implementation-mode')}`,
          class: 'mb-1'
        },
        group: {
          class: 'mb-3'
        },
        name: 'implementation_mode',
        type: 'select',
        disabled: hasNoEventAccess.value,
        value: tempValues.value.implementation_mode + 1,
        options: toOptionType(implementationModes.value, 'id', 'mode').map(el => ({ label: el.label, value: Number(el.value) + 1 })),
        validator: {
          schema: 'yup',
          rules: [
            'number',
            'required'
          ]
        }
      },
      {
        label: {
          text: `${t('domains.academic.manage.section.form-field.mode-of-delivery')}`,
          class: 'mb-1'
        },
        group: {
          class: 'mb-3'
        },
        name: 'delivery_mode',
        type: 'select',
        disabled: hasNoEventAccess.value,
        value: tempValues.value.delivery_mode,
        options: deliveryModesOptions.value,
        validator: {
          schema: 'yup',
          rules: [
            'number',
            'required'
          ]
        }
      },
      {
        label: {
          text: `${t('domains.academic.manage.section.form-field.weeks-count')}`,
          class: 'mb-1'
        },
        group: {
          class: 'mb-3'
        },
        disabled: hasNoEventAccess.value,
        name: 'weeks_count',
        type: 'select',
        value: tempValues.value.weeks_count,
        options: weeksCount,
        validator: {
          schema: 'yup',
          rules: [
            'number',
            'required'
          ]
        }
      },
      {
        label: {
          text: `${t('domains.academic.manage.section.form-field.message')}`,
          class: 'mb-1'
        },
        group: {
          class: 'mb-3'
        },
        disabled: hasNoEventAccess.value,
        name: 'message',
        type: 'textarea',
        value: tempValues.value.message || ''
      }
    ]
  }
])

watch(
  () => modifier.value,
  async (newValue) => {
    if (newValue && newValue.data && newValue.data.id) {
      if (newValue.data.id !== -1) {
        await store.fetchSingleSection(Number(newValue.data.id))
        const exactSection = computed(() => store.getSingleSection)
        if (exactSection.value) {
          tempValues.value.course_code = exactSection.value.course.code
          tempValues.value.year = exactSection.value.course.year
          tempValues.value.term = exactSection.value.term
          tempValues.value.section_type = exactSection.value.type.id
          tempValues.value.instructor_id = exactSection.value.instructor.id
          tempValues.value.grade_submitter_id = exactSection.value.grade_submitter.id
          tempValues.value.message = exactSection.value.note ?? ''
          tempValues.value.implementation_mode = exactSection.value.implementation_mode.id
          tempValues.value.payment_method = exactSection.value.payment_method.id
          tempValues.value.attend_type = exactSection.value.attendance_type.id
          tempValues.value.weeks_count = exactSection.value.weeks_count
          tempValues.value.delivery_mode = exactSection.value.delivery_mode ? exactSection.value.delivery_mode.id : undefined
        } else {
          tempValues.value = objectDeepCopy(defaultTempValues)
        }
      }
    } else {
      tempValues.value = objectDeepCopy(defaultTempValues)
    }
    show()
  },
  { immediate: true }
)
</script>
