import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type { UnknownResponse } from '@shared/swagger'
import { ServiceManageLanguagesSingleStudentPutRequest } from './type'

export default async function putServiceManageLanguagesSingleStudent (uuid: string, params: ServiceManageLanguagesSingleStudentPutRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { put } = useRequests()

  const response = await put<ServiceManageLanguagesSingleStudentPutRequest, UnknownResponse>({
    url: `service/manage/languages/students/${uuid}`,
    body: params,
    loader: `service/manage/languages/students/${uuid}`
  })

  return response
}
