<template>
  <pms-loader v-if="loader" />
  <div
    v-else
    class="mt-3"
  >
    <h3
      v-if="newRequestStore.requestType === 'suggested'"
      class="d-flex justify-content-center mt-3"
    >
      {{ $t('domains.academic.manage.sections.suggested-courses') }}
    </h3>
    <h3
      v-else-if="newRequestStore.requestType === 'program' && newRequestStore.program_request_type === 'E'"
      class="d-flex justify-content-center mt-3"
    >
      {{ $t('domains.academic.manage.sections.elective-courses') }}
    </h3>
    <pms-smart-table
      :items="courses"
      :headers="tableFields"
      :sortable="true"
    >
      <template #no="{ idx }">
        {{ idx + 1 }}
      </template>
      <template #code="{ item }">
        {{ item.code }}
      </template>
      <template #courseCodeHeader="{ item }">
        <div class="d-flex gap-3 flex-wrap">
          {{ $t(item.title) }}
          <input
            v-model="courseCodeInput"
            name="CommonCourseTableInputCode"
            class="form-control"
            type="text"
          >
        </div>
      </template>
      <template #action="{ item }">
        <pms-button
          :size="'sm'"
          :variant="'tonal'"
          @click="addNewRequest({
            equicode: item.equicode,
            courseCode: item.code,
            courseYear: item.course_year,
            sectionRequestId: item.section_request_id,
            curriculum_id: 555 // test
          })"
        >
          {{ $t('phrase.add-new-request') }}
          <pms-kt-icon
            :icon-name="'arrow-right'"
            class="m-0 p-0 ms-2"
          />
        </pms-button>
      </template>
    </pms-smart-table>
  </div>
  <NewRequestForm
    v-if="modifier && modifier.name === 'new-request-content'"
    :requested-by-type="ERequestedBy.Suggested"
  />
</template>

<script setup lang="ts">
import { tableFields } from './values/index'
import { useNewRequest } from '@domains/SectionManagement/features/NewRequest/store'
import { computed, ref, watch } from 'vue'
import { debounce } from '@shared/utils/optimize'
import { useLoader, useTeleportModal } from '@shared/composable'
import NewRequestForm from '@domains/SectionManagement/features/NewRequest/ui/NewRequestForm/NewRequestForm.vue'
import { TNewRequestFormModalData } from '../NewRequestForm/types'
import { ERequestedBy } from '../../enums'

const { isActive } = useLoader()
const { open, modifier, setModifier } = useTeleportModal()
const loader = isActive('academic/manage/sections/courses')
const newRequestStore = useNewRequest()
const courseCodeInput = ref('')

const courses = computed(() => {
  if (courseCodeInput.value.length) {
    newRequestStore.suggested_courses.filter((course) => course.code.includes(courseCodeInput.value))
  }
  return newRequestStore.suggested_courses
})

watch(() => courseCodeInput.value, debounce(filterCourses))

function filterCourses () {
  return newRequestStore.suggested_courses.filter((course) => course.code.includes(courseCodeInput.value))
}

type NewRequestData = {
  equicode: number;
  courseCode: string;
  courseYear: number;
  sectionRequestId: number;
  curriculum_id: number;
}

async function addNewRequest (item: NewRequestData) {
  const data: TNewRequestFormModalData = {
    equicode: item.equicode,
    courseCode: item.courseCode,
    courseYear: item.courseYear,
    sectionRequestId: item.sectionRequestId,
    curriculum_id: item.curriculum_id
  }

  setModifier('new-request-content',
    data,
    { title: 'new-request-title', description: 'new-request-description' }
  )
  open({ name: 'new-request-modal' })
}
</script>
