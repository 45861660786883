
import { defineStore } from 'pinia'
import type { Name, State, Getters, Actions } from './types'

import * as actions from './actions'

export const useServiceSurveys = defineStore<Name, State, Getters, Actions>('Service.useServiceSurveys', {
  actions: {
    ...actions
  },

  getters: {
    //
  },

  state () {
    return {
      surveys: [],
      survey: null,
      questions: [],
      departments: [],
      instructors: [],
      sections: [],
      results: null
    }
  }
})
