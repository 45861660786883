import type { StatusMessageResponse } from '@shared/types'

import type { StoreType } from '../types'
import { ServiceManageLanguagesSingleApplicantPutRequest } from '../methods/putLanguageSingleApplicant/type'
import putServiceManageLanguagesSingleApplicant from '../methods/putLanguageSingleApplicant'
import { applicantsSearchValues } from '../../values'

export default async function putSingleApplicant (this: StoreType, id: string, values: ServiceManageLanguagesSingleApplicantPutRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await putServiceManageLanguagesSingleApplicant(id, values)

  if (ok) {
    this.fetchApplicants(applicantsSearchValues.value)
  }

  return {
    status: ok,
    message
  }
}
