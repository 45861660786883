import type { StoreType } from '../types'

import { getSectionManagementRequests } from '@shared/api'
import SectionManagementSentRequestsGetRequest from '../api/types/SectionManagementSentRequestsGetRequest'
import SectionManagementSentRequestCoursesGetRequest from '../api/types/SectionManagementSentRequestCoursesGetRequest'

export default async function fetchSentRequests (this: StoreType, values: SectionManagementSentRequestCoursesGetRequest): Promise<void> {
  const { data, ok } = await getSectionManagementRequests(values)

  if (ok) {
    this.setSentRequests(data)
  } else {
    this.setSentRequests([])
  }
}
