import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type {
  UnknownRequest
} from '@shared/swagger'
import SectionManagementNewRequestCoreCoursesGetRequest from '@/domains/SectionManagement/features/NewRequest/store/api/types/SectionManagementNewRequestCoreCoursesGetRequest'
import SectionManagementNewRequestCoreCoursesGetResponse from '@/domains/SectionManagement/features/NewRequest/store/api/types/SectionManagementNewRequestCoreCoursesGetResponse'

/**
 * Api path /1.0.0/academic/manage/sections/courses/new-request-courses
 */
export default async function getSectionManagementNewRequestCoreCourses (params: SectionManagementNewRequestCoreCoursesGetRequest): Promise<AxiosResponse<SectionManagementNewRequestCoreCoursesGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, SectionManagementNewRequestCoreCoursesGetResponse>({
    url: 'academic/manage/sections/courses/new-request-courses',
    body: { params: params },
    loader: 'academic/manage/sections/courses/new-request-courses'
  })
  return response
}
