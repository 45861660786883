import { useRequests } from '@shared/composable'
import type { AxiosResponse } from '@shared/types'
import type { UnknownRequest } from '@shared/swagger'
import { ServiceQuestionaryResultsGetResponse, ServiceQuestionaryResultsGetRequest } from './types'

/**
 * API /1.0.0/service/questionaries/results
 * @param params
 * @returns ServiceQuestionaryResultsGetResponse
 */
export default async function getResults (params: ServiceQuestionaryResultsGetRequest): Promise<AxiosResponse<ServiceQuestionaryResultsGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, ServiceQuestionaryResultsGetResponse>({
    url: 'service/questionaries/results',
    body: { params },
    loader: 'get-service/questionaries/results'
  })

  return response
}
