import { AcademicInformationSchedulesResourcesResponse } from '@/shared/swagger'
import { MappedSpecialities } from '../types'

export default (data:AcademicInformationSchedulesResourcesResponse['specialities']): MappedSpecialities[] => {
  return data.map(faculty => ({
    id: faculty.id + '',
    label: faculty.title,
    children: faculty.children.map(department => ({
      id: department.code,
      label: department.title,
      children: department.programs.map(speciality => ({
        id: speciality.program_code,
        label: `${speciality.program_code} - ${speciality.speciality_code} ${speciality.title} (${speciality.language_code}) [${speciality.terms}]`
      }))
    }))
  }))
}
