<template>
  <pms-card
    v-if="course_search_response !== null || loader"
    id="search-course-results-widget"
    :title="$t('domains.academic.manage.course.course-list.title')"
    :description="$t('domains.academic.manage.course.course-list.description')"
    :options="{ description: { class: 'mt-1 fs-7 text-muted' }, body: { class: 'pt-0'}}"
  >
    <pms-loader v-if="loader" />
    <pms-smart-table
      :items="course_search_response ?? []"
      :headers="headers"
      :sortable="true"
      :options="{ wrapper: { responsive: false }}"
    >
      <template #title="{ item }">
        {{ item[`title_${localeShortest}`] }}
      </template>
      <template #department="{ item }">
        {{ item.department_title }} ({{ item.department_code }})
      </template>
      <template #action="{ item }">
        <pms-dropdown
          :group-class="'w-100px'"
          :icon-placement="'end'"
          :placement="'end'"
        >
          <PermissionCheck :permission-code="permissionCodes.edit_courses.code">
            <div
              type="button"
              class="btn btn-light-primary mb-2 fw-bold w-150px"
              @click="goToEditCoursePage(item)"
            >
              <pms-kt-icon
                :icon-name="'pencil'"
                class="fw-bold fs-3 p-0"
              />
              {{ $t('common.edit') }}
            </div>
          </PermissionCheck>
          <PermissionCheck :permission-code="permissionCodes.show_courses.code">
            <router-link
              type="button"
              class="btn btn-light-primary fw-bold mb-2 w-150px"
              :to="`/academic/course?year=${item.year}&term=${item.terms[0] || 0}&code=${item.code}&page=overview&previous_page=AcademicCoursesPage`"
            >
              <pms-kt-icon
                :icon-name="'eye'"
                class="fw-bold fs-3 p-0"
              />
              {{ $t('common.view') }}
            </router-link>
          </PermissionCheck>
          <PermissionCheck :permission-code="permissionCodes.edit_courses.code">
            <div
              type="button"
              class="btn btn-light-danger mb-2 fw-bold w-150px"
              @click="deleteCourse(item)"
            >
              <pms-kt-icon
                :icon-name="'trash'"
                class="fw-bold fs-3 p-0"
              />
              {{ $t('common.delete') }}
            </div>
          </PermissionCheck>
        </pms-dropdown>
      </template>
    </pms-smart-table>
    <pms-pagination
      class="p-0 m-0 mt-6"
    />
  </pms-card>
</template>
<script setup lang="ts">
import { useLoader, useLocale } from '@shared/composable'

import { courseHeaders as headers } from '../values'
import { useManageCourses } from '../store'
import { useAcademicManageCoursesComposable } from '../composable'
import { storeToRefs } from 'pinia'

import PermissionCheck from '@/shared/ui/components/PermissionCheck.vue'
import permissionCodes from '@/shared/values/permissionCodes'

const { isActive } = useLoader()
const { localeShortest } = useLocale()

const { course_search_response } = storeToRefs(useManageCourses())
const loader = isActive('academic/courses')

const { goToEditCoursePage, deleteCourse } = useAcademicManageCoursesComposable()

</script>
