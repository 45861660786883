import type { SmartTableHeader } from '@shared/types'
import type { Placement } from '../types'

export const mainInformationTableHeaders: Array<Placement> = [
  {
    id: 'left',
    rowClass: 'col-md-6',
    tableData: [
      {
        translate: 'psw-retrieve-email',
        field: 'psw_retrieve_email',
        dateValue: false
      },
      {
        translate: 'university-email',
        field: 'university_email',
        dateValue: false
      },
      {
        translate: 'birth-date',
        field: 'birth_date',
        dateValue: true
      },
      {
        translate: 'marriage-status',
        field: 'marriage_status',
        dateValue: false
      },
      {
        translate: 'citizenship',
        field: 'citizenship',
        dateValue: false
      },
      {
        translate: 'iin',
        field: 'iin',
        dateValue: false
      },
      {
        translate: 'iban',
        field: 'iban',
        dateValue: false
      },
      {
        translate: 'document-type',
        field: 'document_type',
        dateValue: false
      },
      {
        translate: 'document-no',
        field: 'document_no',
        dateValue: false
      },
      {
        translate: 'document-authority',
        field: 'document_authority',
        dateValue: false
      },
      {
        translate: 'document-issue-date',
        field: 'document_issue_date',
        dateValue: true
      },
      {
        translate: 'attestation-type',
        field: 'attestation_type',
        dateValue: false
      },
      {
        translate: 'attestation-no',
        field: 'attestation_no',
        dateValue: false
      }
    ]
  },
  {
    id: 'right',
    rowClass: 'col-md-6',
    tableData: [
      {
        translate: 'attestation-gpa',
        field: 'attestation_gpa',
        dateValue: false
      },
      {
        translate: 'attestation-issue-date',
        field: 'attestation_issue_date',
        dateValue: true
      },
      {
        translate: 'entrance-exam',
        field: 'entrance_exam',
        dateValue: false
      },
      {
        translate: 'exam-score',
        field: 'exam_score',
        dateValue: false
      },
      {
        translate: 'exam-code',
        field: 'exam_code',
        dateValue: false
      },
      {
        translate: 'exam-issue-date',
        field: 'exam_issue_date',
        dateValue: true
      },
      {
        translate: 'grant-type',
        field: 'grant_type',
        dateValue: false
      },
      {
        translate: 'grant-certificate',
        field: 'grant_certificate',
        dateValue: false
      },
      {
        translate: 'preparation-course',
        field: 'preparation_course',
        dateValue: false
      },
      {
        translate: 'language-skills',
        field: 'language_skills',
        dateValue: false,
        custom: 'language'
      },
      {
        translate: 'school',
        field: 'school',
        dateValue: false
      }
    ]
  }
]

export const contactsTableHeaders: Array<SmartTableHeader> = [
  {
    title: 'common.table.type',
    field: 'type'
  },
  {
    title: 'common.table.contact',
    field: 'contact'
  },
  {
    title: 'common.table.note',
    field: 'note'
  }
]

export const addressesTableHeaders: Array<SmartTableHeader> = [
  {
    title: 'common.table.type',
    field: 'type'
  },
  {
    title: 'common.table.country',
    field: 'country'
  },
  {
    title: 'common.table.province',
    field: 'province'
  },
  {
    title: 'common.table.region',
    field: 'region'
  },
  {
    title: 'common.table.city',
    field: 'city'
  },
  {
    title: 'common.table.address-line',
    field: 'address_line'
  }
]

export const parentInformationTableHeaders: Array<SmartTableHeader> = [
  {
    title: 'common.table.parent-type',
    field: 'parent_type'
  },
  {
    title: 'common.table.name',
    field: 'name'
  },
  {
    title: 'common.table.surname',
    field: 'surname'
  },
  {
    title: 'common.table.profession',
    field: 'profession'
  },
  {
    title: 'common.table.birth-date',
    field: 'birth_date'
  },
  {
    title: 'common.table.email',
    field: 'email'
  },
  {
    title: 'common.table.telephone',
    field: 'telephone'
  },
  {
    title: 'common.table.mobile',
    field: 'mobile'
  },
  {
    title: 'common.table.working-place',
    field: 'working_place'
  }
]

export const decisionsTableHeaders: Array<SmartTableHeader> = [
  {
    title: 'common.table.date',
    field: 'date',
    headerClass: 'w-125px'
  },
  {
    title: 'common.table.decision-no',
    field: 'decision_no',
    headerClass: 'w-125px'
  },
  {
    title: 'common.table.type',
    field: 'type'
  },
  {
    title: 'common.table.subject',
    field: 'subject'
  },
  {
    title: 'common.table.description',
    field: 'description'
  }
]

export const advisorOpinionTableHeaders: Array<SmartTableHeader> = [
  {
    title: 'common.table.date',
    slotCellName: 'date',
    field: 'date'
  },
  {
    title: 'common.table.advisor',
    field: 'advisor'
  },
  {
    title: 'common.table.content',
    field: 'content',
    slotCellName: 'content'
  },
  {
    title: 'common.table.action',
    field: 'action',
    slotCellName: 'action'
  }
]

export const dissertationsTableHeaders: Array<SmartTableHeader> = [
  {
    title: 'common.table.title',
    field: 'title'
  },
  {
    title: 'common.table.reviewer',
    field: 'reviewer'
  },
  {
    title: 'common.table.edu-level',
    field: 'education_level'
  },
  {
    title: 'common.table.university',
    field: 'university'
  },
  {
    title: 'common.table.teacher',
    field: 'teacher'
  },
  {
    title: 'common.table.begin-year',
    field: 'begin_year'
  },
  {
    title: 'common.table.defence-year',
    field: 'defence_year'
  }
]

export const socialActivitiesTableHeader = [
  {
    title: 'common.table.type',
    field: 'type'
  },
  {
    title: 'common.table.title',
    field: 'title'
  },
  {
    title: 'common.table.start_date',
    field: 'from_date'
  },
  {
    title: 'common.table.end_date',
    field: 'until_date'
  }
]
export const informationTableHeaders = {
  mainInformation: mainInformationTableHeaders,
  contact: contactsTableHeaders,
  address: addressesTableHeaders,
  parentInformation: parentInformationTableHeaders,
  decisions: decisionsTableHeaders,
  advisorOpinion: advisorOpinionTableHeaders,
  dissertation: dissertationsTableHeaders,
  social_activities: socialActivitiesTableHeader
}
