import type { StoreType } from '../types'
import { useAlert, usePagination } from '@shared/composable'
import { getServiceManageLanguagesApplicants } from '../methods/getApplicants/index'
import { ServiceManageLanguagesApplicantsGetRequest } from '../methods/getApplicants/type'
import { StatusMessageResponse } from '@/shared/types'

export default async function fetchApplicants (this: StoreType, params: ServiceManageLanguagesApplicantsGetRequest): Promise<StatusMessageResponse> {
  const { setPaginate, setTriggerFunction } = usePagination()
  const { error } = useAlert()

  setTriggerFunction(async (perPage: number, offset: number) => {
    const { ok, message, data } = await getServiceManageLanguagesApplicants({ ...params, per_page: perPage, offset: offset })
    if (ok) {
      this.setApplicants(data.applicants)
      setPaginate(data.per_page, data.current_page, data.last_page, data.total)
    } else {
      error({ text: message })
    }
  })

  const { ok, message, data } = await getServiceManageLanguagesApplicants(params)

  if (ok) {
    this.setApplicants(data.applicants)
    setPaginate(data.per_page, data.current_page, data.last_page, data.total)
  } else {
    error({ text: message })
  }

  return {
    status: ok,
    message: message
  }
}
