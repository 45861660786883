import { SmartTableHeader } from '@/shared/types'

export const requestCourseTable: Array<SmartTableHeader> = [
  {
    title: 'common.no',
    field: 'no',
    slotCellName: 'no'
  },
  {
    title: 'common.table.course-code',
    field: 'course_code',
    slotCellName: 'code',
    sortable: true,
    cellClass: 'text-center'
  },
  {
    title: 'common.table.equicode',
    field: 'equicode'
  },
  {
    title: 'common.table.course-title',
    field: 'title',
    sortable: true
  },
  {
    title: 'common.table.term',
    field: 'period_number',
    sortable: true,
    cellClass: 'text-center'
  },
  {
    title: 'common.table.theory',
    field: 'theory',
    sortable: true,
    cellClass: 'text-center'
  },
  {
    title: 'common.table.practice',
    field: 'practice',
    cellClass: 'text-center'
  },
  {
    title: 'common.table.ects',
    field: 'ects',
    cellClass: 'text-center'
  },
  {
    title: 'common.table.action',
    field: 'action',
    slotCellName: 'action',
    cellClass: 'text-center'
  }
]
