import type { StoreType } from '../types'
import getSectionManagementMyCourses from '@/shared/api/SectionManagement/getSectionManagementMyCourses'
import SectionManagementOffersGetRequest from '../api/types/SectionManagementOffersGetRequest'

// get sent requests
export default async function fetchCourses (this: StoreType, values: SectionManagementOffersGetRequest): Promise<void> {
  const { data, ok } = await getSectionManagementMyCourses(values)

  if (ok) {
    this.setCourses(data)
  } else {
    this.setCourses([])
  }
}
