import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type {
  UnknownRequest
} from '@shared/swagger'
import SectionManagementSectionsSentRequestQuotasGetResponse from '@/domains/SectionManagement/features/SentRequests/store/api/types/SectionManagementSectionsSentRequestQuotasGetResponse'
import SectionManagementSectionsSentRequestQuotasGetRequest from '@/domains/SectionManagement/features/SentRequests/store/api/types/SectionManagementSectionsSentRequestQuotasGetRequest'

export default async function getSectionManagementSentRequestQuotas (params: SectionManagementSectionsSentRequestQuotasGetRequest): Promise<AxiosResponse<SectionManagementSectionsSentRequestQuotasGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, SectionManagementSectionsSentRequestQuotasGetResponse>({
    url: `academic/manage/sections/requests/${params.request_id}/quotas`,
    body: { },
    loader: 'get-academic/manage/sections/requests/{request}/quotas'
  })

  return response
}
