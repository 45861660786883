import { useServiceSurveys } from '@/domains/ServicesSurveys/store'
import { FormGroup, Nullable } from '@/shared/types'
import { storeToRefs } from 'pinia'
import { TreeNode } from 'primevue/treenode'
import { computed, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { RouteParams, useRoute } from 'vue-router'

export default function useFilterFormModel () {
  const route = useRoute()
  const params = route.params as unknown as RouteParams
  const surveyId = +params.id

  const store = useServiceSurveys()
  onMounted(() => {
    store.fetchDepartments()
    store.fetchSurvey(surveyId)
    store.fetchQuestions({ questionary_id: surveyId })
    store.fetchInstructors(surveyId, {})
    store.fetchSections(surveyId, {})
  })
  const { departments, instructors, sections, questions, survey } = storeToRefs(store)

  const questionOptions = computed(() => {
    return questions.value.map((question) => {
      return {
        label: question.title,
        value: question.id
      }
    })
  })
  const departmentOptions = computed(() => {
    return departmentsToOption(departments.value)
  })
  const instructorOptions = computed(() => {
    return instructors.value.map((instructor) => {
      return {
        label: instructor.full_name,
        value: instructor.employee_id
      }
    })
  })
  const sectionOptions = computed(() => {
    return sections.value.map((section) => {
      return {
        label: `${section.course_title} (${section.course_code}) - ${section.type}`,
        value: section.section_id
      }
    })
  })

  const departmentsToOption = (items: typeof departments.value): TreeNode[] => {
    const departmentMapped: TreeNode[] = []
    items.forEach(department => {
      departmentMapped.push({
        id: department.id,
        label: department.title,
        key: department.department_code,
        selectable: true,
        children: departmentsToOption(items.filter((item) => item.parent_id === department.id))
      })
    })
    return departmentMapped
  }

  const { t } = useI18n()

  type TValues = {
    questionary_id: Nullable<number>,
    department_code: Nullable<string>,
    instructor_id: Nullable<number>,
    section_id: Nullable<number>,
    question_id: Nullable<number>
    }

  const tempValues = ref<TValues>({
    questionary_id: null,
    department_code: null,
    instructor_id: null,
    section_id: null,
    question_id: null
  })

  const selectedDepartment = ref()

  const onSubmit = ({ values }) => {
    tempValues.value = { ...values }
    store.fetchResults({
      questionary_id: surveyId,
      department_code: showDepartmentCodeSelect ? getDepartmentValue() : undefined,
      instructor_id: showInstructorSelect && tempValues.value.instructor_id ? (tempValues.value.instructor_id ?? undefined) : undefined,
      section_id: showSectionSelect && tempValues.value.section_id ? (tempValues.value.section_id ?? undefined) : undefined,
      question_id: tempValues.value.question_id ? tempValues.value.question_id : undefined
    })
  }
  const onHandleChange = ({ name, newValue }) => {
    if (name === 'instructor_id' && newValue && getDepartmentValue()) {
      store.fetchSections(surveyId, {
        department_code: getDepartmentValue(),
        instructor_id: newValue || undefined
      })
      tempValues.value.section_id = null
    }
    tempValues.value[name] = newValue
  }

  const getDepartmentValue = () => {
    return selectedDepartment.value ? Object.keys(selectedDepartment.value)[0] : undefined
  }

  watch(selectedDepartment, (newValue) => {
    const departmentCode = getDepartmentValue()

    tempValues.value.instructor_id = null
    tempValues.value.section_id = null

    if (!departmentCode) {
      tempValues.value.instructor_id = null
      tempValues.value.department_code = null
      return
    }
    tempValues.value.department_code = departmentCode

    store.fetchInstructors(surveyId, { department_code: departmentCode })
  })

  const showDepartmentCodeSelect = computed(() => {
    return survey.value ? survey.value.parameter_fields.includes('departments') : false
  })
  const showSectionSelect = computed(() => {
    return survey.value ? survey.value.parameter_fields.includes('courses') : false
  })
  const showInstructorSelect = computed(() => {
    return survey.value ? survey.value.parameter_fields.includes('instructors') : false
  })

  const formFields = computed<Array<FormGroup>>(() => [
    {
      class: 'row',
      fields: [
        {
          label: {
            text: `${t('common.question')}:`,
            class: 'mb-1 mt-2'
          },
          group: {
            class: 'col-12'
          },
          name: 'question_id',
          type: 'select',
          options: questionOptions.value,
          value: tempValues.value.question_id
        },
        {
          name: 'department_code',
          type: 'slot',
          group: {
            class: 'col-4'
          }
        },
        {
          label: {
            text: `${t('common.search-field.instructor')}:`,
            class: 'mb-1 mt-2'
          },
          group: {
            class: 'col-4' + (showInstructorSelect.value ? '' : ' d-none')
          },
          name: 'instructor_id',
          type: 'select',
          options: instructorOptions.value,
          value: tempValues.value.instructor_id
        },
        {
          label: {
            text: `${t('common.search-field.section')}:`,
            class: 'mb-1 mt-2'
          },
          group: {
            class: 'col-4' + (showSectionSelect.value ? '' : ' d-none')
          },
          name: 'section_id',
          type: 'select',
          options: sectionOptions.value,
          value: tempValues.value.section_id
        }
      ]
    }])
  return {
    departmentOptions,
    formFields,
    selectedDepartment,
    onSubmit,
    onHandleChange,
    showDepartmentCodeSelect
  }
}
