import { useRequests } from '@shared/composable'
import type { AxiosResponse } from '@shared/types'
import type { UnknownRequest } from '@shared/swagger'
import { ServiceQuestionaryQuestionsGetRequest, ServiceQuestionaryQuestionsGetResponse } from './types'

/**
 * API /1.0.0/service/questionaries/{questionary}/questions
 * @param params
 * @returns ServiceQuestionaryQuestionsGetResponse
 */
export default async function getQuestions (params: ServiceQuestionaryQuestionsGetRequest): Promise<AxiosResponse<ServiceQuestionaryQuestionsGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, ServiceQuestionaryQuestionsGetResponse>({
    url: `service/questionaries/${params.questionary_id}/questions`,
    body: {},
    loader: 'get-service/questionaries/{questionary}/questions'
  })

  return response
}
