<template>
  <tbody>
    <template
      v-for="(student, index) in table_data"
      :key="index"
    >
      <tr
        :class="{
          'student-blocked': student.event_lock || false
        }"
      >
        <td
          v-if="student.student_rowspan"
          class="text-center"
          :rowspan="student.student_rowspan"
          vertical-align
        >
          {{ student.rownum }}
        </td>
        <td
          v-if="student.student_rowspan"
          class="text-center"
          :rowspan="student.student_rowspan"
        >
          {{ student.student_id }}
        </td>
        <td
          v-if="student.student_rowspan"
          class="text-center"
          :rowspan="student.student_rowspan"
        >
          {{ student.student_name }}
        </td>

        <td
          v-if="student.section_rowspan"
          class="text-center align-items-center"
          :rowspan="student.section_rowspan"
        >
          {{ student.lesson_date }}
        </td>
        <td
          class="text-center align-items-center"
        >
          {{ student.lesson_time }}
        </td>
        <td>
          <div
            v-if="student.date_available"
            class="d-flex align-items-center"
          >
            <div
              class="ms-10 me-15"
            >
              <AttendanceTableBodySessionCell :student="student" />
            </div>
            <pms-button
              class="py-1 pb-2 text-success"
              variant="outlined"
              color="success"
              @click="saveSingleAttendance(student)"
            >
              <i class="bi bi-floppy2 p-0" />
              <span class="ms-2">
                {{ $t('common.save') }}
              </span>
            </pms-button>
            <pms-button
              class="py-1 pb-2 ms-3"
              variant="outlined"
              color="danger"
              @click="resetSingleAttendance(student)"
            >
              <i class="bi bi-trash p-0" />
              <span class="ms-2">
                {{ $t('common.reset') }}
              </span>
            </pms-button>

            <pms-button
              class="py-1 ms-3 ms-xl-auto"
              color="secondary"
              @click="cancelSingleAttendance(student)"
            >
              {{ $t('common.cancel') }}
            </pms-button>
          </div>
        </td>
      </tr>
    </template>
  </tbody>
</template>
<script setup lang="ts">
import AttendanceTableBodySessionCell from './AttendanceTableBodySessionCell.vue'

import { table_data, useAttendanceTable } from '../composable'

const { saveSingleAttendance, resetSingleAttendance, cancelSingleAttendance } = useAttendanceTable()

</script>
<style scoped lang="scss">
.student-blocked {
  background-color: #f8d7da !important;
}
td{
  vertical-align: middle;
}
</style>
