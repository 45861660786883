<template>
  <pms-smart-table
    id="gateEntryPrintMe"
    :items="record_data"
    :headers="headers"
    :sortable="true"
  >
    <template #no="{idx}">
      {{ idx + 1 }}
    </template>

    <template #tableDate="{item}">
      {{ item.access_date_in ? dateFix(item.access_date_in) : dateFix(item.access_date_out) }}
    </template>

    <template #tableIn="{item}">
      <pms-badge
        v-if="item.access_time_in"
        :color-variant="'tonal'"
        :color="'success'"
        class="fs-base"
      >
        <pms-kt-icon
          icon-name="black-down"
          class="text-success fs-5 ms-n1 mt-1"
        />
        {{ item.access_time_in }}
      </pms-badge>
    </template>

    <template #tableOut="{item}">
      <pms-badge
        v-if="item.access_time_out"
        :color-variant="is_same_date(item)?'tonal':'default'"
        :color="'danger'"
        class="fs-base"
      >
        <pms-kt-icon
          icon-name="black-up"
          class="fs-5 ms-n1 mt-1"
          :class="is_same_date(item)?'text-danger':'text-light'"
        />
        <div>
          {{ item.access_time_out }}
        </div>
      </pms-badge>
      <br v-if="!is_same_date(item)">
      <pms-badge
        v-if="item.access_time_out && !is_same_date(item)"
        :color-variant="'default'"
        :color="'danger'"
        class="fs-base"
      >
        <div>
          {{ ' '+dateFix(item.access_date_out) }}
        </div>
      </pms-badge>
    </template>
    <template #tableDuration="{item}">
      <span
        class="fw-bolder d-block mb-1 fs-4 badge me-2"
        :class="(item.work_time == 120 && !(item.access_time_in && item.access_time_out)) ? 'badge-light-danger':'badge-light-success text-dark'"
      >
        {{ `${calculateWorkTime(item.work_time).hours} hours ${calculateWorkTime(item.work_time).minutes} minutes` }}
      </span>
    </template>
  </pms-smart-table>
</template>
<script setup lang="ts">
import { computed, PropType } from 'vue'
import type { AdministrativeGateEntryResponse } from '@shared/swagger'
import { gateEntryTable as headers } from '../values'

const props = defineProps({
  records: {
    type: Object as PropType<AdministrativeGateEntryResponse>,
    required: true
  }
})

const calculateWorkTime = (work_time: AdministrativeGateEntryResponse[0]['work_time']): {hours: number, minutes: number} => {
  return { hours: Math.floor(work_time / 60), minutes: work_time % 60 }
}

const dateFix = (date: AdministrativeGateEntryResponse[0]['access_date_in']) : string => {
  const [year, month, day] = date.split('-')
  return `${day}.${month}.${year}`
}

function is_same_date (item:typeof props.records[0]) {
  return item.access_date_in === item.access_date_out
}
const record_data = computed(() => props.records.map(record => ({ ...record, class: record.access_date_in !== record.access_date_out ? 'bg-light-danger' : '' })))
</script>
