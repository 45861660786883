import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type {
  UnknownRequest
} from '@shared/swagger'
import SectionManagementNewRequestCoreCoursesGetRequest from '@/domains/SectionManagement/features/NewRequest/store/api/types/SectionManagementNewRequestCoreCoursesGetRequest'
import SectionManagementNewRequestCoreCoursesGetResponse from '@/domains/SectionManagement/features/NewRequest/store/api/types/SectionManagementNewRequestCoreCoursesGetResponse'
import SectionManagementNewRequestSuggestedCoursesGetRequest from '@/domains/SectionManagement/features/NewRequest/store/api/types/SectionManagementNewRequestSuggestedCoursesGetRequest'
import SectionManagementNewRequestSuggestedCoursesGetResponse from '@/domains/SectionManagement/features/NewRequest/store/api/types/SectionManagementNewRequestSuggestedCoursesGetResponse'

/**
 * Api path /1.0.0/academic/manage/sections/courses/suggested
 */
export default async function getSectionManagementNewRequestSuggestedCourses (params: SectionManagementNewRequestSuggestedCoursesGetRequest): Promise<AxiosResponse<SectionManagementNewRequestSuggestedCoursesGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, SectionManagementNewRequestSuggestedCoursesGetResponse>({
    url: 'academic/manage/sections/courses/suggested',
    body: { params: params },
    loader: 'get-academic/manage/sections/courses/suggested'
  })
  return response
}
