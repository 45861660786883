import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type { UnknownRequest } from '@shared/swagger'
import { ServiceQuestionaryDepartmentInstructorSectionsGetRequest, ServiceQuestionaryDepartmentInstructorSectionsGetResponse } from './types'

/**
 * API /1.0.0/service/questionaries/{survey}/courses
 * @param params
 * @returns ServiceQuestionaryDepartmentInstructorSectionsGetResponse
 */
export default async function getSections (surveyId: number, params: ServiceQuestionaryDepartmentInstructorSectionsGetRequest): Promise<AxiosResponse<ServiceQuestionaryDepartmentInstructorSectionsGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, ServiceQuestionaryDepartmentInstructorSectionsGetResponse>({
    url: `service/questionaries/${surveyId}/courses`,
    body: {
      params
    },
    loader: 'get-service/questionaries/{survey}/courses'
  })

  return response
}
