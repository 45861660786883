import { useRequests } from '@/shared/composable'
import type {
  UnknownRequest
} from '@/shared/swagger'
import type { AxiosResponse } from '@shared/types'
import { ServiceSurveyGetResponse } from './types'

export default async function getServiceSurveyById (id: number): Promise<AxiosResponse<ServiceSurveyGetResponse>> {
  const { get } = useRequests()

  const response = get<UnknownRequest, ServiceSurveyGetResponse>({
    url: `service/questionaries/${id}`,
    body: { },
    loader: 'get-service/questionaries/:id'
  })

  return response
}
