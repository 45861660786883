<template>
  <Teleport to="#publication-modal-content">
    <pms-smart-form
      :fields="[formFields]"
      @handleChange="onHandleChange"
      @handleSubmit="onSubmit"
    >
      <template #formControls>
        <div class="text-end mt-4">
          <pms-button
            type="submit"
            class="me-3"
            :loader="loader"
            :disabled="loader"
            :text="'common.submit'"
          />
          <pms-button
            :color="'light'"
            :text="'common.cancel'"
            data-bs-dismiss="modal"
            @click="closeModal()"
          />
        </div>
      </template>
    </pms-smart-form>
  </Teleport>
</template>
<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import type { FormField } from '@shared/types'
import type { ProfileEducationBooksGetResponse } from '@shared/swagger'
import { useLoader, useAlert, useTeleportModal } from '@shared/composable'
import { objectDeepCopy } from '@shared/utils'
import { useResource } from '@shared/stores'

import { useProfile } from '../../../store'
import { months } from '../values'
import { toOptionsType, monthToOptionsType } from '../utils'

const { t } = useI18n()
const { isActiveOneOf } = useLoader()
const { success, error } = useAlert()
const { close, show, modifier, unsetModifier } = useTeleportModal()

const resource = useResource()
const store = useProfile()
const loader = isActiveOneOf(['profile/education/books-edit', 'profile/education/books-post'])

const countriesSuggestions = computed(() => resource.getProfileResource?.countries || [])
const bookTypes = computed(() => resource.getProfileResource?.book_types || [])
const books = computed(() => store.getBooks)

const defaultTempValues: ProfileEducationBooksGetResponse[0] = {
  id: -1,
  title: '',
  publisher: '',
  book_type: -1,
  authors: '',
  pages: '',
  url: '',
  country: -1,
  published_month: -1,
  published_year: -1
}

const tempValues = ref<ProfileEducationBooksGetResponse[0]>(objectDeepCopy(defaultTempValues))

const formFields = computed<Array<FormField>>(() => [
  {
    label: {
      text: t('domains.profile.publications.book.title-field'),
      class: 'fs-6 fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'title',
    type: 'text',
    value: tempValues.value.title,
    placeholder: t('domains.profile.publications.book.title-placeholder'),
    validator: {
      schema: 'yup',
      rules: [
        'string',
        'required'
      ]
    }
  },
  {
    label: {
      text: t('domains.profile.publications.book.authors-field'),
      class: 'fs-6 required fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'authors',
    type: 'text',
    value: tempValues.value.authors,
    required: true,
    placeholder: t('domains.profile.publications.book.authors-placeholder'),
    validator: {
      schema: 'yup',
      rules: [
        'string',
        'required'
      ]
    }
  },
  {
    label: {
      text: t('domains.profile.publications.book.book-type-field'),
      class: 'fs-6 required fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'book_type',
    type: 'select',
    value: tempValues.value.book_type,
    required: true,
    options: toOptionsType(bookTypes.value),
    validator: {
      schema: 'yup',
      rules: [
        'mixed',
        {
          func: 'required',
          args: ['An option must be selected']
        },
        {
          func: 'test',
          args: ['Validation: is null option', 'An option must be selected', (value) => value !== null && value !== 'common.not-selected']
        }
      ]
    }
  },
  {
    label: {
      text: t('domains.profile.publications.book.pages-field'),
      class: 'fs-6 fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'pages',
    type: 'number',
    value: Number(tempValues.value.pages),
    placeholder: t('domains.profile.publications.book.pages-placeholder'),
    validator: {
      schema: 'yup',
      rules: [
        'number',
        'required'
      ]
    }
  },
  {
    label: {
      text: t('domains.profile.publications.book.url-field'),
      class: 'fs-6 fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'url',
    type: 'url',
    value: tempValues.value.url,
    placeholder: t('domains.profile.publications.book.url-placeholder'),
    validator: {
      schema: 'yup',
      rules: [
        'string',
        'required'
      ]
    }
  },
  {
    label: {
      text: t('domains.profile.publications.book.country-field'),
      class: 'fs-6 required fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'country',
    type: 'select',
    value: tempValues.value.country,
    required: true,
    options: toOptionsType(countriesSuggestions.value),
    validator: {
      schema: 'yup',
      rules: [
        'mixed',
        {
          func: 'required',
          args: ['An option must be selected']
        },
        {
          func: 'test',
          args: ['Validation: is null option', 'An option must be selected', (value) => value !== null && value !== 'common.not-selected']
        }
      ]
    }
  },
  {
    label: {
      text: t('domains.profile.publications.book.publisher-field'),
      class: 'fs-6 fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'publisher',
    type: 'text',
    value: tempValues.value.publisher,
    placeholder: t('domains.profile.publications.book.publisher-placeholder'),
    validator: {
      schema: 'yup',
      rules: [
        'string',
        'required'
      ]
    }
  },
  {
    label: {
      text: t('domains.profile.publications.book.published-month-field'),
      class: 'fs-6 required fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'published_month',
    type: 'select',
    value: tempValues.value.published_month,
    required: true,
    options: monthToOptionsType(months),
    validator: {
      schema: 'yup',
      rules: [
        'mixed',
        {
          func: 'required',
          args: ['An option must be selected']
        },
        {
          func: 'test',
          args: ['Validation: is null option', 'An option must be selected', (value) => value !== null && value !== 'common.not-selected']
        }
      ]
    }
  },
  {
    label: {
      text: t('domains.profile.publications.book.published-year-field'),
      class: 'fs-6 required fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'published_year',
    type: 'number',
    value: tempValues.value.published_year === -1 ? '' : tempValues.value.published_year,
    required: true,
    placeholder: t('domains.profile.publications.book.published-year-placeholder'),
    validator: {
      schema: 'yup',
      rules: [
        'number',
        'required'
      ]
    }
  }

])

const closeModal = () => {
  close({ name: 'publication-modal' })
  unsetModifier()
}

const onHandleChange = ({ name, newValue }) => {
  tempValues.value[name] = newValue
}

const onSubmit = async ({ values }) => {
  if (!values.published_year) {
    error({ text: t('domains.profile.publications.book.published-year-field-error') })
    return
  }
  if (!values.published_month) {
    error({ text: t('domains.profile.publications.book.published-month-field-error') })
    return
  }
  if (!values.country) {
    error({ text: t('domains.profile.publications.book.country-field-error') })
    return
  }
  values.pages += ''
  const { status, message } =
           tempValues.value.id === -1
             ? await store.addBook({ ...values })
             : await store.editBook(tempValues.value.id, { ...values })
  if (status) {
    success({ text: message, isToast: true })
    close({ name: 'publication-modal' })
    unsetModifier()
    store.fetchLanguages()
  } else {
    error({ text: message })
  }
}
watch(
  () => modifier.value,
  (newValue) => {
    if (newValue && newValue.data && newValue.data.id && newValue.data.action) {
      if (newValue.data.action === 'edit') {
        const exactBook = books.value.find((item) => item.id === newValue.data?.id)
        tempValues.value = objectDeepCopy(exactBook || defaultTempValues)
      } else {
        tempValues.value = objectDeepCopy(defaultTempValues)
      }
      show()
    }
  }, { immediate: true }
)
</script>
