import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { RouteRecordWithLayout } from '@shared/types'

import { useProfile } from './store'

const handleRoute = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const store = useProfile()

  store.$reset()
  store.bootstrap(to.meta.page as string)
  next()
}

export default {
  layout: 'default',
  path: '/profile',
  children: [
    {
      path: '',
      name: 'ProfilePage',
      component: () => import('./ProfilePage.vue'),
      children: [
        {
          path: '',
          name: 'OverviewPage',
          component: () => import('./features/Overview/OverviewPage.vue'),
          meta: {
            page: 'overview'
          },
          beforeEnter: [
            handleRoute
          ]
        },
        {
          path: 'addresses',
          name: 'AddressesPage',
          component: () => import('./features/Addresses/AddressesPage.vue'),
          meta: {
            page: 'addresses'
          },
          beforeEnter: [
            handleRoute
          ]
        },
        {
          path: 'biography',
          name: 'AutobiographyPage',
          component: () => import('./features/Biography/BiographyPage.vue'),
          meta: {
            page: 'biography'
          },
          beforeEnter: [
            handleRoute
          ]
        },
        {
          path: 'contacts',
          name: 'ContactsPage',
          component: () => import('./features/Contacts/ContactsPage.vue'),
          meta: {
            page: 'contacts'
          },
          beforeEnter: [
            handleRoute
          ]
        },
        {
          path: 'education',
          name: 'EducationPage',
          component: () => import('./features/Education/EducationPage.vue'),
          meta: {
            page: 'education'
          },
          beforeEnter: [
            handleRoute
          ]
        },
        {
          path: 'publications',
          name: 'PublicationsPage',
          component: () => import('./features/Publications/PublicationsPage.vue'),
          meta: {
            page: 'publications'
          },
          beforeEnter: [
            handleRoute
          ]
        },
        {
          path: 'experience',
          name: 'ExperiencePage',
          component: () => import('./features/Experience/ExperiencePage.vue'),
          meta: {
            page: 'experience'
          },
          beforeEnter: [
            handleRoute
          ]
        },
        {
          path: 'skills',
          name: 'SkillsPage',
          component: () => import('./features/Skills/SkillsPage.vue'),
          meta: {
            page: 'skills'
          },
          beforeEnter: [
            handleRoute
          ]
        },
        {
          path: 'settings',
          name: 'SettingsPage',
          component: () => import('./features/Settings/SettingsPage.vue'),
          meta: {
            page: 'settings'
          },
          beforeEnter: [
            handleRoute
          ]
        },
        {
          path: 'settings/password',
          name: 'SettingsPasswordPage',
          component: () => import('./features/SettingsPassword/SettingsPasswordPage.vue'),
          meta: {
            page: 'settings_password'
          },
          beforeEnter: [
            handleRoute
          ]
        }
      ]
    }
  ]
} as RouteRecordWithLayout
