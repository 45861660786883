<template>
  <div class="d-flex flex-column">
    <div class="d-flex flex-rows align-items-center">
      <button
        title="Back"
        type="button"
        class="btn btn-light-primary btn-sm me-2 p-2 ps-3 pe-3"
        @click="handleBackToCourses"
      >
        <pms-kt-icon
          icon-name="arrow-left"
          class="m-0 p-0"
        />
      </button>
      <div class="d-flex flex-column justify-content-start">
        <span class="text-dark fs-3 fw-bold m-0 p-0">
          {{ selectedCourse?.title + ` (${selectedCourse?.code})` }}
        </span>
        <span class="fs-6 m-0 p-0">
          {{ $t('common.table.credits')+': '+selectedCourse?.credits }}
        </span>
      </div>
    </div>

    <div class="d-flex flex-row flex-end gap-3">
      <button
        type="button"
        class="btn btn-warning btn-lg"
        @click="openReserveModal"
      >
        {{ $t('phrase.section-management.met-reserve') }}
      </button>
      <button
        class="btn btn-primary btn-lg ms-2"
        @click="openSectionsZoom"
      >
        {{ $t('common.sections') }}
      </button>
    </div>
    <pms-page-shimmer
      v-if="loader"
      :shimmer="'table'"
      :full-width="true"
    />
    <pms-smart-table
      v-else
      :items="requests"
      :headers="singleCourseTableHeader"
      :class="'mt-4 mb-4'"
      :options="{
        wrapper: { responsive: false}
      }"
    >
      <template #no="{ idx }">
        {{ idx + 1 }}
      </template>

      <template #quota="{ item }">
        {{ item.quota }}
      </template>

      <template #note="{ item }">
        {{ item.note }}
      </template>

      <template #met="{ item }">
        {{ item.met_quota }}
      </template>

      <template #program="{item}">
        {{ item.program_code ? item.program_code : 'All' }}
      </template>

      <template #status="{item}">
        <pms-badge
          :value="item.status?.title"
          :size="'md'"
          class="me-2 "
          :color="item.status.id === 2 ? 'success':item.status.id === 1 ?'primary': item.status.id === 4 ? 'warning':'danger'"
        />
      </template>

      <template #action="{item}">
        <pms-dropdown
          :group-class="'w-100px order-5'"
          :icon-placement="'end'"
        >
          <div class="w-150px menu-item pt-0 pb-0 text-left">
            <template v-if="item.met_quota">
              <a
                href="javascript:void(0)"
                class="menu-link px-3 text-left"
                @click="openDetailsZoom(item)"
              >
                {{ $t('common.table.details') }}
              </a>
            </template>
            <a
              v-if="item.status.id !== 4"
              href="javascript:void(0)"
              class="menu-link px-3 text-left"
              @click="openRejectZoom(item)"
            >
              {{ $t('common.table.reject') }}
            </a>
          </div>
        </pms-dropdown>
      </template>

      <template #create_date="{item}">
        {{ dateFormatter(item.create_date) }}
      </template>
    </pms-smart-table>
    <div ref="sectionsZoomRef">
      <SectionsZoom v-if="zooms === 'sections_zoom'" />
    </div>
    <div ref="detailsZoomRef">
      <ReceivedDetailsZoom v-if="zooms === 'zoom_details'" />
    </div>
    <div ref="rejectZoomRef">
      <ReceivedRejectZoom v-if="zooms === 'reject_zoom'" />
    </div>
    <ReceivedDetailsReservedForm v-if="modifier && modifier.name === 'new-reserve-content'" />
  </div>
</template>
<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref, nextTick } from 'vue'
import { storeToRefs } from 'pinia'

import { useReceivedRequest } from '../store'
import { useLoader, useTeleportModal } from '@/shared/composable'
import { useSectionManagement } from '@domains/SectionManagement/store'
import { singleCourseTableHeader, zooms } from '../values'
import { dateFormatter } from '@/shared/utils'

import SectionsZoom from './SectionsZoom.vue'
import ReceivedDetailsZoom from '@/domains/SectionManagement/features/ReceivedRequests/ui/ReceivedDetailsZoom/ReceivedDetailsZoom.vue'
import ReceivedRejectZoom from '@domains/SectionManagement/features/ReceivedRequests/ui/ReceivedRejectZoom.vue'
import ReceivedDetailsReservedForm from './ReceivedDetailsReservedForm.vue'
import SectionManagementReceivedRequestsGetResponse from '../store/api/types/SectionManagementReceivedRequestsGetResponse'

const { isActive } = useLoader()
const { setModifier, open, modifier } = useTeleportModal()

const loader = isActive('academic/manage/sections/received/requests')
const receivedRequestStore = useReceivedRequest()
const store = useSectionManagement()
const { receivedRequests: requests, selectedCourse } = storeToRefs(receivedRequestStore)
const { currentYearTerm } = storeToRefs(store)
const sectionsZoomRef = ref<HTMLElement | null>(null)
const detailsZoomRef = ref<HTMLElement | null>(null)
const rejectZoomRef = ref<HTMLElement | null>(null)

const openDetailsZoom = async (item: SectionManagementReceivedRequestsGetResponse[0]) => {
  receivedRequestStore.selectedRequest = item
  zooms.value = 'zoom_details'
  if (!receivedRequestStore.selectedCourse) return

  item.status.id !== 4 // if not 4 (Reserved)
    ? await receivedRequestStore.fetchRequestsQuotas({
      request_id: item.request_id,
      program_code: item.program_code,
      equicode: receivedRequestStore.selectedCourse.equicode
    })
    : await receivedRequestStore.fetchReservedProgramsQuotas({ // todo use /1.0.0/academic/manage/sections/received/requests/quotas/reserve
      course_code: item.course_code,
      program_code: item.program_code
    })
  scrollDown(detailsZoomRef)
}
const openRejectZoom = async (item: SectionManagementReceivedRequestsGetResponse[0]) => {
  receivedRequestStore.selectedRequest = item
  zooms.value = 'reject_zoom'
  scrollDown(rejectZoomRef)
}
const openSectionsZoom = async () => {
  zooms.value = 'sections_zoom'
  scrollDown(sectionsZoomRef)
}

const scrollDown = async (component: any) => {
  await nextTick()
  if (component.value) {
    component.value.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }
}

async function openReserveModal () {
  setModifier('new-reserve-content',
    {},
    {
      title: 'academic.manage.section.received-requests.reserve-modal-title',
      description: 'academic.manage.section.received-requests.reserve-modal-description'
    }
  )
  open({ name: 'received-requests-modal' })
}

function handleBackToCourses () {
  receivedRequestStore.step = 'courses'
}
onMounted(async () => {
  await receivedRequestStore.fetchRequestSections({
    course_code: receivedRequestStore.selectedCourse?.code ?? '',
    year: receivedRequestStore.selectedCourse?.year ?? -1,
    term: currentYearTerm.value.term
  })
})
onBeforeUnmount(() => {
  zooms.value = null
  receivedRequestStore.selectedCourse = null
  receivedRequestStore.selectedRequest = null
  receivedRequestStore.selectedRequestsSections = []
  receivedRequestStore.selectedRequestsQuotas = []
})
</script>
