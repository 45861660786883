<template>
  <Teleport to="#experience-modal-content">
    <pms-smart-form
      :fields="[formFields]"
      @handleChange="onHandleChange"
      @handleSubmit="onSubmit"
    >
      <template #formControls>
        <div class="text-end mt-4">
          <pms-button
            type="submit"
            class="me-3"
            :loader="loader"
            :disabled="loader"
            :text="'common.submit'"
          />
          <pms-button
            :color="'light'"
            :text="'common.cancel'"
            data-bs-dismiss="modal"
            @click="closeModal()"
          />
        </div>
      </template>
    </pms-smart-form>
  </Teleport>
</template>
<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import type { FormField } from '@shared/types'
import type { ProfileCertificatesGetResponse } from '@shared/swagger'
import { useLoader, useAlert, useTeleportModal } from '@shared/composable'
import { objectDeepCopy } from '@shared/utils'
import { useResource } from '@shared/stores'

import { useProfile } from '../../../store'
import { getIdByTitle, getTitleById } from '../utils'

const { t } = useI18n()
const { isActiveOneOf } = useLoader()
const { success, error } = useAlert()
const { close, show, modifier, unsetModifier } = useTeleportModal()

const store = useProfile()
const resource = useResource()
const loader = isActiveOneOf(['profile/certificates-edit', 'profile/certificates-post'])

const selectedOrganization = ref('')

const certificates = computed(() => store.getCertificates)
const organizations = computed(() => resource.getProfileResource?.organizations || [])

const defaultTempValues: ProfileCertificatesGetResponse[0] = {
  id: -1,
  title: '',
  organization_id: -1,
  certified_date: '',
  credential_id: '',
  credential_url: '',
  expired_date: ''
}

const tempValues = ref<ProfileCertificatesGetResponse[0]>(objectDeepCopy(defaultTempValues))

const formFields = computed<Array<FormField>>(() => [
  {
    label: {
      text: t('domains.profile.experience.certificate.title-field'),
      class: 'fs-6 required fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'title',
    type: 'text',
    value: tempValues.value.title,
    required: true,
    placeholder: t('domains.profile.experience.certificate.title-placeholder'),
    validator: {
      schema: 'yup',
      rules: [
        'string',
        'required'
      ]
    }
  },
  {
    label: {
      text: t('domains.profile.experience.certificate.credential-url-field'),
      class: 'fs-6 required fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'credential_url',
    type: 'url',
    value: tempValues.value.credential_url,
    required: true,
    placeholder: t('domains.profile.experience.certificate.credential-url-placeholder'),
    validator: {
      schema: 'yup',
      rules: [
        'string',
        'required'
      ]
    }
  },
  {
    label: {
      text: t('domains.profile.experience.certificate.organization-field'),
      class: 'fs-6 required fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'organization_id',
    type: 'autocomplete',
    value: selectedOrganization.value,
    required: true,
    suggestions: {
      itemField: 'title',
      items: organizations.value,
      class: ''
    },
    placeholder: t('domains.profile.experience.certificate.organization-placeholder'),
    validator: {
      schema: 'yup',
      rules: [
        'string'
      ]
    }
  },
  {
    label: {
      text: t('domains.profile.experience.certificate.credential-id-field'),
      class: 'fs-6 required fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'credential_id',
    type: 'text',
    value: tempValues.value.credential_id,
    required: true,
    placeholder: t('domains.profile.experience.certificate.credential-id-placeholder'),
    validator: {
      schema: 'yup',
      rules: [
        'string',
        'required'
      ]
    }
  },
  {
    label: {
      text: t('domains.profile.experience.project.certified-date-field'),
      class: 'fs-6 required fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'certified_date',
    type: 'date',
    value: tempValues.value.certified_date,
    required: true
  },
  {
    label: {
      text: t('domains.profile.experience.project.expired-date-field'),
      class: 'fs-6 required fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'expired_date',
    type: 'date',
    value: tempValues.value.expired_date,
    required: true
  }
])

const closeModal = () => {
  close({ name: 'experience-modal' })
  unsetModifier()
}

const onHandleChange = ({ name, newValue }) => {
  if (name === 'organization_id') {
    selectedOrganization.value = newValue
  } else {
    tempValues.value[name] = newValue
  }
}

const onSubmit = async ({ values }) => {
  if (!values.title) {
    error({ text: 'domains.profile.experience.certificate.title-field-error' })
    return
  }
  if (!values.expired_date) {
    error({ text: 'domains.profile.experience.certificate.expired-date-field-error' })
    return
  }
  if (!values.certified_date) {
    error({ text: 'domains.profile.experience.certificate.certified-date-field-error' })
    return
  }
  if (!values.credential_url) {
    error({ text: 'domains.profile.experience.certificate.credential-url-field-error' })
    return
  }
  if (!values.credential_id) {
    error({ text: 'domains.profile.experience.certificate.credential-id-field-error' })
    return
  }
  const organizationId = getIdByTitle(selectedOrganization.value, organizations.value)
  if (organizationId) {
    values.organization_id = organizationId
  } else {
    values.organization_new = tempValues.value.organization_id + ''
  }

  const { status, message } =
            tempValues.value.id === -1
              ? await store.addCertificate({ ...values })
              : await store.editCertificate(tempValues.value.id, { ...values })
  if (status) {
    success({ text: message, isToast: true })
    close({ name: 'experience-modal' })
    unsetModifier()
    store.fetchExperiences()
  } else {
    error({ text: message })
  }
}
watch(
  () => modifier.value,
  (newValue) => {
    if (newValue && newValue.data && newValue.data.id && newValue.data.action) {
      if (newValue.data.action === 'edit') {
        const exactCertificate = certificates.value.find((item) => item.id === newValue.data?.id)
        tempValues.value = objectDeepCopy(exactCertificate || defaultTempValues)
        selectedOrganization.value = getTitleById(tempValues.value.organization_id, organizations.value)
      } else {
        tempValues.value = objectDeepCopy(defaultTempValues)
      }
      show()
    }
  }, { immediate: true }
)
</script>
