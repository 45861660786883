<template>
  <div
    v-if="records !== null"
    id="export-actions"
    class="d-flex justify-content-between mt-4"
  >
    <a
      v-if="user_data.user_type === 'P'"
      class="btn btn-sm mr-2"
      :class="{ 'disabled btn-light': records.length === 0, 'btn-primary': records.length > 0}"
      @click="onSendEmail"
    >
      {{ $t('domains.administrative.gate-entry.table.send-to-email') }}
    </a>
    <div>
      <a
        v-styler="{ 'margin-right': '10px'}"
        v-test-id="'print'"
        class="btn btn-sm mr-2"
        :class="{ 'disabled btn-light': records.length === 0, 'btn-primary': records.length > 0 }"
        @click="onPrintAction"
      >
        {{ $t('domains.administrative.gate-entry.table.print') }}
      </a>
      <a
        v-test-id="'table-download'"
        class="btn btn-sm mr-2"
        :class="{ 'disabled btn-light': records.length === 0, 'btn-primary': records.length > 0 }"
        @click="onExcelDownloadAction"
      >
        {{ $t('domains.administrative.gate-entry.table.download') }}
      </a>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { user_data } from '../values'
import { useAlert } from '@shared/composable'
import { useGateEntryStore } from '../store'
import { printGateEntryRecords } from '../assets/printer/gate-entry'
import { utils, writeFile } from 'xlsx'
import { storeToRefs } from 'pinia'

const { t } = useI18n()
const { error } = useAlert()
const store = useGateEntryStore()
const dataState = storeToRefs(store)
const records = dataState.records

const onExcelDownloadAction = (): void => {
  const wb = utils.book_new()
  let number = 1
  let totalHours = 0
  const wsData = (records.value ? records.value : []).map((record) => {
    totalHours += record.work_time

    return [
      `${number++}`,
      `${record.full_name}`,
      `${record.access_date_in}`,
      `${record.access_time_in}`,
      `${record.access_time_out}`,
      `${Math.floor(record.work_time / 60)}h ${record.work_time % 60}m`
    ]
  }
  )

  wsData.push([t('domains.administrative.gate-entry.table.total-time'), `${Math.floor(totalHours / 60)}h ${totalHours % 60}m`])

  const ws = utils.aoa_to_sheet([['NO', 'FULL NAME', 'DATE', 'ENTRANCE', 'LEAVE', 'DURATION'], ...wsData])

  ws['!cols'] = [{ wch: 10 }, { wch: 25 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }]
  utils.book_append_sheet(wb, ws, 'GATEENTRY')
  writeFile(wb, 'GATEENTRY.xlsx')
}

const onPrintAction = () => {
  const headers = [
    t('common.table.no'),
    t('common.table.full-name'),
    t('common.table.date'),
    t('common.table.in-date'),
    t('common.table.out-date'),
    t('common.table.duration')
  ]
  const totalHours = t('domains.administrative.gate-entry.table.total-time')
  const data = records.value ? records.value : []
  if (data.length > 0) {
    printGateEntryRecords(data, headers, totalHours)
  } else {
    error({ text: 'No records found' })
  }
}

const onSendEmail = () => {
  const data = { reader_group: user_data.value.reader_group, employee_id: Number(user_data.value.user_id), from_date: user_data.value.from_date, due_date: user_data.value.due_date }
  store.sendMail(data)
}
</script>
