<template>
  <pms-card
    :header="false"
  >
    <pms-page-shimmer
      v-if="loader"
      :shimmer="'table'"
      :full-width="true"
    />
    <pms-smart-table
      v-else
      :items="requests"
      :headers="tableHeader"
      :sortable="true"
      :class="'mt-4'"
      :options="{
        wrapper: { responsive: false}
      }"
    >
      <template #no="{ idx }">
        {{ idx + 1 }}
      </template>
      <template #course-code="{item}">
        {{ item.course.code }}
      </template>
      <template #courseCodeHeader="{ item }">
        <div class="d-flex gap-3 flex-wrap w-100px">
          {{ $t(item.title) }}
          <input
            v-model="courseCodeInput"
            name="MyCourcesFormCodeSearch"
            class="form-control"
            type="text"
          >
        </div>
      </template>
      <template #course-title="{item}">
        {{ item.course.title }}
      </template>
      <template #met="{item}">
        {{ item.met ?? 0 }}
      </template>
      <template #status="{item}">
        {{ item.status.title }}
      </template>
      <template #action="{item}">
        <pms-dropdown
          :group-class="'w-100px'"
          :icon-placement="'end'"
        >
          <div class="w-150px menu-item pt-0 pb-0 text-left">
            <a
              href="javascript:void(0)"
              class="menu-link px-3 text-left"
              @click="openModal(item)"
            >
              {{ $t('common.table.information') }}
            </a>
            <a
              href="javascript:void(0)"
              class="menu-link px-3 text-left"
              @click="deleteAction(item.id,item.course.code + ' ' + item.course.title)"
            >
              {{ $t('common.table.delete') }}
            </a>
          </div>
        </pms-dropdown>
      </template>
      <template #create_date="{item}">
        {{ dateFormatter(item.create_date) }}
      </template>
    </pms-smart-table>
  </pms-card>
</template>
<script lang="ts" setup>
import { computed, onBeforeUnmount, ref, watch } from 'vue'
import { useSentRequests } from '../../store'
import { useI18n } from 'vue-i18n'
import { useAlert, useLoader, useTeleportModal } from '@/shared/composable'
import SectionManagementSentRequestCoursesGetResponse from '../../store/api/types/SectionManagementSentRequestCoursesGetResponse'
import { dateFormatter } from '@/shared/utils'
import { storeToRefs } from 'pinia'
import { useSectionManagement } from '@/domains/SectionManagement/store'
import { tableHeader } from './values'
import { debounce } from '@/shared/utils/optimize'

const store = useSentRequests()
const { t } = useI18n()
const { isActive } = useLoader()
const { setModifier, open } = useTeleportModal()
const { dialog, error, success } = useAlert()
const sectionManagement = useSectionManagement()
const { currentYearTerm } = storeToRefs(sectionManagement)

const loader = isActive('academic/manage/sections/requests')

const courseCodeInput = ref('')
const filteredRequests = ref<SectionManagementSentRequestCoursesGetResponse | undefined>([])
const requests = computed(() => {
  if (courseCodeInput.value && (filteredRequests.value && filteredRequests.value.length > 0)) {
    return filteredRequests.value
  }
  return store.getSentRequests
})

watch(() => courseCodeInput.value, debounce(filterRequests))

function filterRequests () {
  filteredRequests.value = store.getSentRequests.filter((req) => req.course.code.includes(courseCodeInput.value))
}

const openModal = async (item: SectionManagementSentRequestCoursesGetResponse[0]) => {
  await store.fetchSentRequestQuotas(item.id)
  store.request_id = item.id
  setModifier('sent-requests',
    {
      id: item.id
    },
    {
      title: item.course.title + ` (${item.course.code})`,
      description: 'sent-requests-modal-description'
    }
  )
  open({ name: 'sent-requests-modal' })
}

const deleteAction = async (id: number, title?: string) => {
  dialog({
    title: 'domains.academic.manage.sections.sent-requests.are-you-sure',
    text: t('domains.academic.manage.sections.sent-requests.delete-text', { name: title }),
    onConfirm: async () => {
      const { status, message } = await store.deleteSentRequest(id)
      status
        ? success({
          text: message
        })
        : error({
          text: message
        })
    }
  })
}

onBeforeUnmount(() => {
  store.setSentRequests([])
  store.status = 0
})
</script>
