<template>
  <pms-tab
    v-model:active="active"
    :tabs="tabs"
    tab-buttons-class="w-100 justify-content-center"
  >
    <template #program>
      <readonly-view-program
        :tab="active"
        :curricula="curriculum"
      />
    </template>
    <template #elective>
      <readonly-view-elective
        :tab="active"
        :curricula="curriculum"
      />
    </template>
    <template #reference>
      <readonly-view-reference
        :tab="active"
        :curricula="curriculum"
      />
    </template>
  </pms-tab>
</template>
<script setup lang="ts">
import { ref, PropType } from 'vue'
import ReadonlyViewProgram from './ReadonlyViewProgram.vue'
import ReadonlyViewElective from './ReadonlyViewElective.vue'
import ReadonlyViewReference from './ReadonlyViewReference.vue'
import { readonlyTabs as tabs } from '../values'
import { AcademicSingleStudentCurriculumGetResponse } from '@/domains/AcademicSingleStudent/store/api/methods/getAcademicSingleStudentCurriculum/types'

const active = ref<string>('all')

defineProps({
  curriculum: {
    type: Object as PropType<AcademicSingleStudentCurriculumGetResponse[number]>,
    required: true
  }
})
</script>
