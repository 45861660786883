import type { SelectField } from '@/shared/types'
import type { AcademicStudentsResourceResponse } from '@shared/swagger'

export default (data: AcademicStudentsResourceResponse['classes'] | undefined): SelectField['options'] => {
  const options: SelectField['options'] = []

  data?.forEach((num) => {
    options.push({
      label: String(num),
      value: num
    })
  })
  return options
}
