import { StatusMessageResponse } from '@/shared/types'
import type { StoreType } from '../types'
import getResults from '../api/methods/getResults'
import { ServiceQuestionaryResultsGetRequest } from '../api/methods/getResults/types'

export default async function fetchResults (this: StoreType, params: ServiceQuestionaryResultsGetRequest): Promise<StatusMessageResponse> {
  const { ok, data, message } = await getResults(params)

  if (ok) {
    this.results = data
  }

  return {
    status: ok,
    message
  }
}
