<template>
  <div
    class="form-group"
    :class="field?.group?.class"
    :style="field?.group?.style"
  >
    <label
      v-if="field.label && field.label.text"
      :for="`inputText${fieldName}`"
      :class="field.label.class"
      :style="field.label.style"
    >
      <template v-if="field.label && field.label.slot">
        <slot :name="field.label.slot" />:
      </template>
      <template v-else>
        {{ isTranslatable(field.hint) ? $t(field.label.text) : field.label.text }}
      </template>
    </label>
    <DatePicker
      v-if="field.type==='date'"
      :id="`inputText${fieldName}`"
      v-model="dateValue"
      date-format="dd-mm-yyyy"
      show-icon
      class="form-control"
      :manual-input="false"
      :style="field.style"
      :placeholder="field.placeholder"
      :required="field.required"
      :disabled="field.disabled"
      @date-select="updateValue"
    />
    <Password
      v-else-if="field.type === 'password'"
      :id="`inputText${fieldName}`"
      v-model="field.value"
      :feedback="false"
      :name="fieldName"
      toggle-mask
      input-class="w-100 p-3"
      :class="field.class"
      :style="field.style"
      :placeholder="field.placeholder"
      :required="field.required"
      :disabled="field.disabled"
      @input="updateValue"
    />
    <input
      v-else
      :id="`inputText${fieldName}`"
      v-maska:[field.vMask]
      :type="field.type || 'text'"
      :name="fieldName"
      class="form-control"
      :class="field.class"
      :style="field.style"
      :placeholder="field.placeholder"
      :value="input.value"
      :required="field.required"
      :disabled="field.disabled"
      v-bind="field.binding || {}"
      @input="updateValue"
    >
    <small
      v-if="field.hint"
      :id="`inputTextHelp${fieldName}`"
      class="form-text text-muted"
    >
      <template v-if="field.hintSlot">
        <slot :name="field.hintSlot" />
      </template>
      <template v-else>
        <i
          v-if="field.hintIcon"
          class="bi bi-info-circle fs-8"
        />
        {{ isTranslatable(field.hint) ? $t(field.hint) : field.hint }}
      </template>
    </small>
    <slot />
  </div>
</template>
<script setup lang="ts">
import { PropType, computed } from 'vue'
import { FormField, FormInput } from '@shared-types/form'
import { isTranslatable } from '@shared/utils'
import DatePicker from 'primevue/datepicker'
import moment from 'moment'
import Password from 'primevue/password'

const props = defineProps({
  input: {
    type: Object as PropType<FormInput>,
    required: true
  },
  inputIdx: {
    type: Number,
    required: true
  },
  fieldName: {
    type: [String],
    required: true
  },
  field: {
    type: Object as PropType<FormField>,
    required: true
  }
})

const emit = defineEmits(['setValue'])

const dateValue = computed(() => {
  let date: null | string = null
  if (moment(props.input.value, 'YYYY-MM-DD', true).isValid()) {
    date = moment(props.input.value).format('DD-MM-YYYY')
  } else if (moment(props.input.value, 'DD-MM-YYYY', true).isValid()) {
    date = props.input.value
  }
  return date
})

const updateValue = (event) => {
  let newValue

  if (props.field.type === 'date') {
    newValue = moment(event).format('DD-MM-YYYY').trim()
  } else {
    newValue = event.target.value
  }

  emit('setValue', {
    idx: props.inputIdx,
    name: props.field.name,
    oldValue: props.input.value || null,
    newValue
  })
}
</script>
<style scoped>
.form-group {
  display: flex;
  flex-direction: column;
}

.input-wrapper {
  display: flex;
  flex-direction: column;
}

::v-deep .p-inputtext:enabled:focus {
  border-color: #B5B5C3;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
</style>
