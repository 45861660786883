import type { StoreType } from '../types'

import getSectionManagementRequests from '@/shared/api/SectionManagement/getSectionManagementRequests'
import SectionManagementSentRequestCoursesGetRequest from '../api/types/SectionManagementSentRequestCoursesGetRequest'

// get sent requests
export default async function fetchSectionsCourses (this: StoreType, values: SectionManagementSentRequestCoursesGetRequest): Promise<void> {
  const { data, ok } = await getSectionManagementRequests(values)

  if (ok) {
    this.setSectionsCourses(data)
  } else {
    this.setSectionsCourses([])
  }
}
