<template>
  <program-color-description />

  <div class="d-flex justify-content-end mb-5">
    <programs-view-buttons />
  </div>
  <template
    v-for="(items, index) in core"
    :key="index"
  >
    <pms-row>
      <template
        v-for="(term, cIndex) in items"
        :key="cIndex"
      >
        <pms-grid
          :col="columnState ==='two-line' ? 6 : 12"
          style="position: relative;"
        >
          <span
            class="pinned text-muted"
            :style="{
              'font-size': `${ columnState === 'two-line' ? 10 : 14}em`,
              left: `${ columnState === 'two-line' ? -35 : -50}px`,
              top: `${ columnState === 'two-line' ? -50 : -70}px`,
            }"
          >
            {{ term.term }}
          </span>
          <pms-card
            class="mb-2"
            :header="false"
            :stretch="100"
          >
            <pms-smart-table
              :headers="headers"
              :items="term.courses"
            >
              <template #no="{idx, item}">
                <colored-text :course="item">
                  {{ idx + 1 }}
                </colored-text>
              </template>
              <template #course_code="{item}">
                <colored-text :course="item">
                  {{ item.course_code }}
                </colored-text>
                <div
                  v-if="!!item.details.length && !!getActualGradeDetail(item.details).course_code"
                  class="fw-bold text-muted"
                >
                  {{ getActualGradeDetail(item.details).course_code }}
                </div>
              </template>
              <template #title="{item}">
                <colored-text :course="item">
                  {{ item.title }}
                </colored-text>
              </template>
              <template #theory="{ item }">
                <colored-text :course="item">
                  {{ item.theory }}
                </colored-text>
              </template>
              <template #practice="{ item }">
                <colored-text :course="item">
                  {{ item.practice }}
                </colored-text>
              </template>
              <template #credits="{ item }">
                <colored-text :course="item">
                  {{ item.credits }}
                </colored-text>
              </template>
              <template #grades="{ item }">
                <colored-text :course="item">
                  {{ !!item.details.length ? getActualGradeDetail(item.details).letter_grade: '' }}
                </colored-text>
              </template>
              <template #ects="{ item }">
                <colored-text :course="item">
                  {{ item.ects }}
                </colored-text>
              </template>
              <template #action>
                <div
                  :data-bs-original-title="$t('domains.editor.curricula.readonly.disabled-click-edit-to-enable')"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  data-bs-trigger="hover"
                >
                  <pms-button
                    :variant="'tonal'"
                    :size="'sm'"
                    :icon="'bi bi-chevron-down p-0 m-0'"
                    :icon-placement="'end'"
                    :text="'common.action'"
                    :disabled="true"
                  />
                </div>
              </template>
            </pms-smart-table>
            <pms-smart-table
              class="mt-5"
              :headers="totalHeaders"
              :items="term.total"
            >
              <template #title="{value}">
                <span class="fw-bold">
                  {{ $t(value) }}
                </span>
              </template>
            </pms-smart-table>
          </pms-card>
        </pms-grid>
      </template>
    </pms-row>
  </template>
  <pms-card
    class="mt-3 mb-2"
    :header="false"
    :stretch="100"
  >
    <pms-smart-table
      :headers="totalHeaders"
      :items="allCoursesTotal"
    >
      <template #title="{value}">
        <span class="fw-bold">
          {{ $t(value) }}
        </span>
      </template>
    </pms-smart-table>
  </pms-card>
</template>
<script setup lang="ts">
import { PropType, computed } from 'vue'
import { columnState, coreHeaders as headers, totalHeaders } from '../values'
import { getCoursesByTerm, getElectivesAsCourseByTerm, getTotalCourse, getActualGradeDetail, getLastTermOfStudent } from '../utils'
import ProgramsViewButtons from './ProgramsViewButtons.vue'
import ColoredText from './ColoredText.vue'
import ProgramColorDescription from './ProgramColorDescription.vue'
import { TotalCourse } from '../types'
import { AcademicSingleStudentCurriculumGetResponse } from '@/domains/AcademicSingleStudent/store/api/methods/getAcademicSingleStudentCurriculum/types'

const props = defineProps({
  curricula: {
    type: Object as PropType<AcademicSingleStudentCurriculumGetResponse[0]>,
    required: true
  },
  tab: {
    type: String,
    required: true
  }
})
const allCoursesAndElectives = computed(() => [
  ...getCoursesByTerm(-1, props.curricula.groups),
  ...getElectivesAsCourseByTerm(-1, props.curricula.elective_sections)
])
const allCoursesTotal = computed(() => getTotalCourse(allCoursesAndElectives.value))

const core = computed(() => {
  const result: Array<Array<{ term: number; courses: (AcademicSingleStudentCurriculumGetResponse[number]['groups'][0])['items']; total: Array<TotalCourse>}>> = []
  const terms = getLastTermOfStudent(allCoursesAndElectives.value)
  let term = 1

  if (columnState.value === 'one-line') {
    for (let i = 1; i <= terms; i++) {
      result.push([
        {
          term: term++,
          courses: [
            ...getCoursesByTerm(i, props.curricula.groups),
            ...getElectivesAsCourseByTerm(i, props.curricula.elective_sections)
          ],
          total: getTotalCourse([
            ...getCoursesByTerm(i, props.curricula.groups),
            ...getElectivesAsCourseByTerm(i, props.curricula.elective_sections)
          ])
        }])
    }
  } else {
    for (let i = 1; i <= terms; i += 2) {
      result.push([
        {
          term: term++,
          courses: [
            ...getCoursesByTerm(i, props.curricula.groups),
            ...getElectivesAsCourseByTerm(i, props.curricula.elective_sections)
          ],
          total: getTotalCourse([
            ...getCoursesByTerm(i, props.curricula.groups),
            ...getElectivesAsCourseByTerm(i, props.curricula.elective_sections)
          ])
        },
        {
          term: term++,
          courses: [
            ...getCoursesByTerm(i, props.curricula.groups),
            ...getElectivesAsCourseByTerm(i, props.curricula.elective_sections)
          ],
          total: getTotalCourse([
            ...getCoursesByTerm(i, props.curricula.groups),
            ...getElectivesAsCourseByTerm(i, props.curricula.elective_sections)
          ])
        }
      ])
    }
  }
  return result
})

</script>
<style scoped lang="scss">
.pinned {
  position: absolute;
  font-weight: 600;
  z-index: 0;
  opacity: 0.3;
}
</style>
