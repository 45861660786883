import { SelectField } from '@shared/types'

export const toOptionType = (data, value, label): SelectField['options'] => {
  const options: SelectField['options'] = []
  data.forEach((el) => {
    options.push({
      label: el[label],
      value: el[value]
    })
  })
  return options
}
