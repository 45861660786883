import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type { UnknownRequest } from '@shared/swagger'
import { ServiceQuestionaryDepartmentInstructorsGetResponse, ServiceQuestionaryDepartmentInstructorsGetRequest } from './types'

/**
 * API /1.0.0/service/questionaries/{questionary}/instructors
 * @param params
 * @returns ServiceQuestionaryDepartmentInstructorsGetResponse
 */
export default async function getInstructors (survey: number, params: ServiceQuestionaryDepartmentInstructorsGetRequest): Promise<AxiosResponse<ServiceQuestionaryDepartmentInstructorsGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, ServiceQuestionaryDepartmentInstructorsGetResponse>({
    url: `service/questionaries/${survey}/instructors`,
    body: { params },
    loader: 'get-service/questionaries/{questionary}/instructors'
  })

  return response
}
