<template>
  <div
    class="d-flex flex-column-fluid flex-lg-row-auto justify-content-center p-12"
    :class="{
      'p-10': device === 'desktop',
      'p-2': device === 'tablet' || device === 'mobile',
    }"
  >
    <SignInAction />
  </div>
</template>

<script lang="ts" setup>
import SignInAction from './features/SignInAction.vue'
import { deviceType } from '@/shared/utils'

const device = deviceType()
</script>
