<template>
  <div
    class="d-flex flex-column-fluid flex-lg-row-auto justify-content-center p-12"
    :class="{
      'p-10': device === 'desktop',
      'p-2': device === 'tablet' || device === 'mobile',
    }"
  >
    <div class="bg-body d-flex flex-center rounded-4 w-md-600px p-10">
      <div class="w-md-400px">
        <div class="text-center mb-5">
          <h1 class="text-dark fw-bolder mb-3">
            {{ $t("domains.authenticator.forgot-password.title") }}
          </h1>
          <div class="text-gray-500 fw-semibold fs-6">
            {{ $t("domains.authenticator.forgot-password.description") }}
          </div>
        </div>

        <pms-smart-form
          :fields="[formFields]"
          @handleSubmit="onSubmit"
        >
          <template #formControls>
            <div class="d-flex flex-wrap justify-content-center pb-lg-0">
              <button
                v-test-id="'submit'"
                type="submit"
                class="btn btn-primary me-4"
              >
                <span
                  v-if="!loader"
                  class="indicator-label"
                >{{
                  $t("common.submit")
                }}</span>
                <span
                  v-if="loader"
                  class="indicator-label"
                >
                  {{ $t("phrases.please-wait") }}
                  <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                  />
                </span>
              </button>
              <router-link
                v-test-id="'go-back'"
                class="btn btn-light"
                to="/auth/sign-in"
              >
                {{
                  $t("phrases.go-back")
                }}
              </router-link>
            </div>
          </template>
        </pms-smart-form>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import type { FormField } from '@shared/types'
import { deviceType } from '@shared/utils'
import { useAlert, useLoader } from '@shared/composable'
import { useAuth } from '@domains/SignIn/store'
import { useRouter } from 'vue-router'

const router = useRouter()

const { t } = useI18n()
const { isActive } = useLoader()
const { success, error } = useAlert()

const store = useAuth()
const device = deviceType()
const loader = isActive('forgot-password')

const formFields: Array<FormField> = [
  {
    type: 'text',
    name: 'username',
    group: {
      class: 'mb-4'
    },
    label: {
      text: t('domains.authenticator.form.username'),
      class: 'fs-6 fw-semibold text-dark mb-1'
    },
    validator: {
      schema: 'yup',
      rules: [
        'string',
        'required',
        'lowercase',
        {
          func: 'label',
          value: t('domains.authenticator.form.username')
        },
        'strict',
        { func: 'min', value: 2 },
        { func: 'max', value: 30 },
        'trim'
      ]
    }
  }
]

const onSubmit = async ({ values }) => {
  const { ok, message } = await store.forgotPassword({ ...values, device: 'web:desktop' })

  if (ok) {
    success({ text: message, isToast: true })
    router.push({ name: 'SignInPage' })
  } else {
    error({ text: message })
  }
}
</script>
