<template>
  <Teleport to="#my-courses-modal-content">
    <pms-row>
      <pms-grid :col="5">
        <pms-smart-form
          :fields="[formFields]"
          @handleSubmit="onSubmit"
        >
          <template #formControls>
            <div class="d-flex gap-3">
              <pms-button
                :color="'light'"
                :text="'common.cancel'"
                data-bs-dismiss="modal"
                @click="closeModal()"
              />
              <pms-button
                :color="'primary'"
                :text="'common.save'"
                type="submit"
                data-bs-dismiss="modal"
              />
            </div>
          </template>
        </pms-smart-form>
      </pms-grid>
    </pms-row>
  </Teleport>
</template>

<script setup lang="ts">
import useEditCourseOffer from './composable/useEditCourseOffer'

const { onSubmit, formFields, closeModal } = useEditCourseOffer()
</script>
