import { useResource } from '@/shared/stores'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useSentRequests } from '../../../store'
import { FormGroup } from '@/shared/types'
import SectionManagementSentRequestCoursesGetRequest from '../../../store/api/types/SectionManagementSentRequestCoursesGetRequest'

export default function useFormDataGenerator () {
  const store = useSentRequests()
  const resource = useResource()
  const { t } = useI18n()

  const programs = computed(() => resource.getSectionManagementResourcePrograms)
  const departments = computed(() => resource.getSectionManagementResourceDepartments)

  const statuses = computed(() => ([
    { id: 0, title: t('common.all') },
    ...resource.getSectionManagementResourceRequestStatuses
  ]))

  const defaultOption = {
    value: null,
    label: t('common.all')
  }

  const groupedPrograms = computed(() => {
    return [{
      label: '',
      items: [
        defaultOption
      ]
    }, ...departments.value.map((department) => {
      return {
        label: department.title,
        code: department.code,
        items: programs.value.filter(pr => pr.department.code === department.code).map((program) => {
          return {
            value: program.code,
            label: `${program.code} - ${program.language.code} - [${program.cipher}]: - ${program.title} - [${program.terms_count}]`
          }
        })
      }
    })]
  })

  const tempValues = ref({
    program: undefined,
    status: undefined
  })

  const formFields = computed<Array<FormGroup>>(() => [
    {
      class: 'row',
      fields: [
        {
          group: {
            class: 'col-6'
          },
          name: 'program',
          type: 'slot',
          value: tempValues.value.program
        },
        {
          group: {
            class: 'col-6'
          },
          name: 'status',
          type: 'slot',
          value: tempValues.value.status
        }
      ]
    }
  ])

  const onSubmit = async (data: SectionManagementSentRequestCoursesGetRequest) => {
    store.fetchSentRequests({
      status: data.status ? data.status : undefined,
      program_code: data.program_code,
      year: data.year,
      term: data.term
    })
  }

  return {
    formFields,
    statuses,
    groupedPrograms,
    onSubmit,
    defaultOption
  }
}
