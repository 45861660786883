import { defineStore } from 'pinia'
import type { Name, State, Getters, Actions } from './types'

import * as actions from './actions'
import * as getters from './getters'

export const useGateEntryStore = defineStore<Name, State, Getters, Actions>('Administrative.gateEntry', {
  actions: {
    ...actions
  },

  getters: {
    ...getters
  },

  state: (): State => ({
    records: null,
    view: 'employee',
    sendMailRes: { message: '' }
  })
})
