import { SelectField } from '@shared/types'
import { ServiceManageLanguagesResourcesResponse } from '@shared/swagger'

export default (data: ServiceManageLanguagesResourcesResponse['years']): SelectField['options'] => {
  const options: SelectField['options'] = []

  data.forEach((item) => {
    options.push({
      label: `${item.year}`,
      value: item.year
    })
  })

  return options
}
