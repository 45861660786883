<template>
  <Card v-if="transcript">
    <template #title>
      <h3 class="card-title">
        {{ t('domains.academic.single-student.transcript-content.student-information') }}
      </h3>
    </template>
    <template
      #content
    >
      <div class="row">
        <div class="col-12 col-md-6">
          <h5>{{ t('common.student.id') }}: <span class="text-primary">{{ student.id }}</span></h5>
        </div>
        <div class="col-12 col-md-6">
          <h5>{{ t('common.student.name') }}: <span class="text-primary">{{ student.fullName }}</span></h5>
        </div>
        <div class="col-12 col-md-6">
          <h5>{{ t('common.student.level') }}: <span class="text-primary">{{ student.educationLevel }}</span></h5>
        </div>
        <div class="col-12 col-md-6">
          <h5>{{ t('common.student.entry-date') }}: <span class="text-primary">{{ student.entryDate }}</span></h5>
        </div>
      </div>
    </template>
  </Card>
</template>

<script setup>
import { computed, ref } from 'vue'
import Card from 'primevue/card'
import { useI18n } from 'vue-i18n'
import { useSingleStudent } from '@/domains/AcademicSingleStudent/store'
import { storeToRefs } from 'pinia'

const store = useSingleStudent()
const { transcript } = storeToRefs(store)
const { t } = useI18n()

const student = computed(() => {
  return {
    id: transcript.value?.information.id,
    fullName: transcript.value?.information.full_name,
    entryDate: transcript.value?.information.entry_date,
    educationLevel: transcript.value?.information.education_level
  }
})
</script>
<style scoped>
h5 {
  font-weight: 600;
}
.text-primary {
  color: #007bff; /* Можно настроить другой цвет, если нужно */
}
</style>
