import { useSectionManagement } from '@/domains/SectionManagement/store'
import { useMyCourses } from '@/domains/SectionManagement/features/MyCourses/store'

export default function UseCoursesFetcher () {
  const store = useMyCourses()
  const sectionManagementStore = useSectionManagement()

  const fetchCourses = ({ departmentCode }: {departmentCode: string}) => {
    store.fetchCourses({
      department_code: departmentCode,
      year: sectionManagementStore.currentYearTerm.year,
      term: sectionManagementStore.currentYearTerm.term
    })
  }
  return { fetchCourses }
}
