import type { StoreType } from '../types'
import type { StatusMessageResponse } from '@shared/types'

import { getServiceEsigns } from '@shared/api'
import { ServiceEsignGetRequest, ServiceEsignGetResponse } from '@/shared/swagger'
import { useAlert, usePagination } from '@/shared/composable'

export default async function fetchEsignStudents (this: StoreType, params: ServiceEsignGetRequest): Promise<StatusMessageResponse> {
  const { error } = useAlert()
  const { setPaginate, setTriggerFunction } = usePagination()

  const setData = (data: ServiceEsignGetResponse): void => {
    this.setEsignStudents(data)
    setPaginate(data.per_page, data.current_page, data.last_page, data.total)
  }

  setTriggerFunction(async (perPage: number, currentPage: number) => {
    const { ok, data } = await getServiceEsigns({ ...params, per_page: perPage, offset: currentPage })

    if (ok) {
      setData(data)
    }
  })

  const { ok, message, data } = await getServiceEsigns(params)

  if (ok) {
    setData(data)
  } else {
    error({ text: message })
  }

  return {
    status: ok,
    message
  }
}
