<template>
  <pms-card
    :title="$t('domains.administrative.gate-entry.search.title')"
    :description="$t('domains.administrative.gate-entry.search.description')"
    :options="{body:{class: 'pt-0'}}"
  >
    <pms-smart-form
      :fields="formFields"
      @handleChange="onHandleChange"
      @handleSubmit="onSubmitForm"
    >
      <template #department>
        <span class="mb-1 mt-2"> {{ `${t('common.department')}:` }}</span>
        <treeselect
          v-model="tempValues.department_code"
          :multiple="false"
          :options="sortedFac"
          placeholder=""
          class="mt-3 styled"
        />
      </template>
      <template #formControls>
        <div class="d-flex flex-end mt-6">
          <pms-button
            type="submit"
            class="w-150px"
            :disabled="loader"
            :loader="loader"
            :text="$t('common.search')"
          />
        </div>
      </template>
    </pms-smart-form>
  </pms-card>
</template>
<script setup lang="ts">
import { computed, ref, onMounted, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import moment from 'moment/moment'
import { user_data } from '../values'
import { FormGroup } from '@/shared/types'
import { useResource } from '@shared/stores'
import { useLoader, useAlert } from '@shared/composable'
import Treeselect from 'vue3-treeselect'
import { useGateEntryStore } from '../store'
import { entryTypeToOption, departmentsToOption, fromDueValues, organizeFaculties } from '../utils'

const { t } = useI18n()

const { error } = useAlert()
const { isActive } = useLoader()

const store = useGateEntryStore()
const resource = useResource()
const { fromValue, dueValue } = fromDueValues()

const loader = isActive('administrative/gate-entry')

const faculties = computed(() => resource.getAdministrativeGateEntryResourceFaculties)
const sortedFac = organizeFaculties(faculties.value)
const users = computed(() => resource.getAdministrativeGateEntryResourceUsers)

const tempValues = ref({
  user_id: null as number | null,
  department_code: null as string | null,
  reader_group: 1,
  from_date: null as string | null,
  due_date: null as string | null
})

const formFields = computed((): Array<FormGroup> => {
  const result:Array<FormGroup> = [
    {
      class: 'row',
      fields: [
        {
          label: {
            text: `${t('domains.administrative.gate-entry.search.reader_group')}`,
            class: 'mb-1 required'
          },
          name: 'reader_group',
          type: 'select',
          class: 'form-select form-select-solid',
          group: {
            class: 'mb-3 col-12'
          },
          value: tempValues.value.reader_group,
          options: entryTypeToOption(resource.getAdministrativeResourceTypes),
          validator: {
            schema: 'yup',
            rules: [
              'mixed',
              {
                func: 'required',
                args: ['An option must be selected']
              },
              {
                func: 'test',
                args: ['Validation: is null option', 'An option must be selected', (value) => value !== null && value !== 'common.not-selected']
              }
            ]
          }
        }
      ]
    },
    {
      class: 'row',
      fields: [
        {
          name: 'department',
          type: 'slot',
          group: {
            class: 'mb-3 col-6'
          }
        },
        {
          label: {
            text: `${t('common.username')}:`,
            class: 'mb-1 mt-2'
          },
          group: {
            class: 'mb-3 col-6'
          },
          name: 'user_id',
          type: 'select',
          value: tempValues.value.user_id || null,
          options: departmentsToOption(users.value)
        }
      ]
    },
    {
      class: 'd-flex row ',
      fields: [
        {
          label: {
            text: `${t('domains.administrative.gate-entry.search.from_date')}`,
            class: 'mb-1'
          },
          name: 'from_date',
          type: 'date',
          group: {
            class: 'mb-3 col-6'
          },
          value: tempValues.value.from_date,
          class: 'form-control form-control-lg form-control-solid',
          binding: {
            max: moment().add(1, 'days').format('YYYY-MM-DD')
          }
        },
        {
          label: {
            text: `${t('domains.administrative.gate-entry.search.due_date')}`,
            class: 'mb-1'
          },
          name: 'due_date',
          type: 'date',
          group: {
            class: 'mb-3 col-6'
          },
          value: tempValues.value.due_date,
          class: 'form-control form-control-lg form-control-solid',
          binding: {
            min: moment(tempValues.value.from_date).format('YYYY-MM-DD'),
            max: moment().add(1, 'days').format('YYYY-MM-DD')
          }
        }
      ]
    }
  ]

  return result
})

const onHandleChange = ({ name, newValue }) => {
  tempValues.value[name] = newValue
}

const onSubmitForm = async ({ values }) => {
  if (tempValues.value.user_id === -1) {
    error({ text: 'domains.administrative.gate-entry.search.error.student-id' })
    return
  }
  user_data.value = {
    ...tempValues.value,
    ...values,
    user_type: 'S',
    mailer: false
  }

  await store.fetchRecords(user_data.value)
}

watch(() => tempValues.value.department_code, async (newValue) => {
  newValue && await resource.fetchAdministrativeGateEntryResourceUsers({ department: newValue, user_type: 'S' })
})

watch(() => tempValues.value.user_id, async () => {
  tempValues.value.user_id ? tempValues.value.from_date = fromValue : tempValues.value.from_date = dueValue
})

onMounted(() => {
  tempValues.value.from_date = dueValue
  tempValues.value.due_date = dueValue
})
</script>
