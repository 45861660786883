import type { StoreType } from '../types'
import SectionManagementNewRequestCoreCoursesGetResponse from '../api/types/SectionManagementNewRequestCoreCoursesGetResponse'

export default function getMappedProgramCourses (this: StoreType): SectionManagementNewRequestCoreCoursesGetResponse {
  if (!this.requestCourses.length) return []
  const trackMap = this.requestCourses.reduce((acc, course) => {
    const track = course.track

    if (!acc.has(track)) {
      acc.set(track, [])
    }

    acc.get(track).push(course)

    return acc
  }, new Map())

  return Array.from(trackMap.values())
}
