<template>
  <h1>{{ $t('common.table.statistics') }}</h1>
  <pms-smart-table
    :items="quotaStatistics"
    :headers="tableHeader"
    :class="'mt-4'"
  >
    <template #class="{item}">
      {{ item.class }}
    </template>
    <template #enrolled="{item}">
      {{ item.enrolled }}
    </template>
    <template #passed="{item}">
      {{ item.passed }}
    </template>
    <template #failed="{item}">
      {{ item.failed }}
    </template>
    <template #will_take="{item}">
      {{ item.will_take }}
    </template>
  </pms-smart-table>
</template>
<script lang="ts" setup>
import { useReceivedRequest } from '@domains/SectionManagement/features/ReceivedRequests/store'
import { storeToRefs } from 'pinia'
import { tableHeader } from './values'

const store = useReceivedRequest()

const { quotaStatistics } = storeToRefs(store)
</script>
