import type { NestedListItem } from '@shared/types'
import type { AcademicManageCurriculumNavigationResponse } from '@shared/swagger'

export default (navItems: AcademicManageCurriculumNavigationResponse): Array<NestedListItem> => {
  return navItems.filter((item) => item.programs.length !== 0).map((item) => {
    return {
      ...item,
      title: `${item.department.title}`,
      nestedItems: item.programs.sort((a, b) => {
        const getOrder = (cipher) => {
          if (cipher === null) return 4
          if (cipher.includes('B')) return 1
          if (cipher.includes('M')) return 2
          if (cipher.includes('D')) return 3
          return 4
        }

        const orderA = getOrder(a.cipher)
        const orderB = getOrder(b.cipher)

        if (orderA < orderB) {
          return -1
        }
        if (orderA > orderB) {
          return 1
        }
        return 0
      }).map((program) => {
        return {
          ...program,
          title: `${program.cipher ? program.cipher : ''} ${program.title} (${program.duration_in_terms}) [${program.education.language.code}]`,
          nestedItems: program.tracks.sort((a, b) => {
            const aStartsWithMain = a.title.toLowerCase().startsWith('main')
            const bStartsWithMain = b.title.toLowerCase().startsWith('main')

            if (aStartsWithMain && !bStartsWithMain) {
              return -1
            } else if (!aStartsWithMain && bStartsWithMain) {
              return 1
            } else {
              return a.title.localeCompare(b.title)
            }
          }).map((track) => {
            return {
              ...track,
              title: `${track.title} (${track.type})`,
              params: {
                departmentId: item.department.code,
                programId: program.code,
                trackId: track.id
              }
            }
          })
        }
      })
    }
  })
}
