<template>
  <pms-card :header="false">
    <pms-smart-form
      :fields="formFields"
      :options="{
        controls: {class: 'w-100 mt-5'}
      }"
      :loader="loader"
      @handleSubmit="onSubmit"
      @handleChange="onChange"
    >
      <template #formControls>
        <pms-button
          :color="'primary'"
          class="px-4 py-2 mt-5"
          :text="'common.search'"
          type="submit"
        />
      </template>
    </pms-smart-form>
  </pms-card>
</template>
<script setup lang="ts">
import UseMyCoursesFilter from './composable/UseMyCoursesFilter/UseMyCoursesFilter'

const { formFields, isActive, onSubmit, onChange } = UseMyCoursesFilter()
const loader = isActive('get-academic/manage/sections/offers')
</script>
