import { useResource } from '@/shared/stores'
import type { StoreType } from '../types'

export default async function bootstrap (this: StoreType): Promise<void> {
  this.$reset()
  const resource = useResource()

  resource.fetchServiceEsignResource({
    modules:
    [
      'statuses', 'year-terms', 'types', 'entrance-years', 'programs', 'departments'
    ]
  }).then(() => {
    if (resource.serviceEsignResource) {
      resource.serviceEsignResource.year_terms = resource.serviceEsignResource.year_terms.sort((a, b) =>
        a.year < b.year
          ? 1
          : a.year === b.year
            ? a.term < b.term ? 1 : -1
            : -1)
    }
  })
}
