import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type {
  UnknownRequest
} from '@shared/swagger'
import SectionManagementNewRequestSearchCoursesGetRequest from '@/domains/SectionManagement/features/NewRequest/store/api/types/SectionManagementNewRequestSearchCoursesGetRequest'
import SectionManagementNewRequestSearchCoursesGetResponse from '@/domains/SectionManagement/features/NewRequest/store/api/types/SectionManagementNewRequestSearchCoursesGetResponse'

/**
 * Api path /1.0.0/academic/manage/sections/courses/search
 */
export default async function getSectionManagementNewRequestSearchCourses (params: SectionManagementNewRequestSearchCoursesGetRequest): Promise<AxiosResponse<SectionManagementNewRequestSearchCoursesGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, SectionManagementNewRequestSearchCoursesGetResponse>({
    url: 'academic/manage/sections/courses/search',
    body: { params: params },
    loader: 'get-academic/manage/sections/courses/search'
  })
  return response
}
