<template>
  <pms-loader v-if="loader" />
  <div
    v-else
    class="mt-3"
  >
    <h3
      v-if="newRequestStore.requestType === 'suggested'"
      class="d-flex justify-content-center mt-3"
    >
      {{ $t('domains.academic.manage.sections.suggested-courses') }}
    </h3>
    <h3
      v-else-if="newRequestStore.requestType === 'program' && newRequestStore.program_request_type === 'E'"
      class="d-flex justify-content-center mt-3"
    >
      {{ $t('domains.academic.manage.sections.elective-courses') }}
    </h3>
    <pms-smart-table
      :items="courses"
      :headers="requestElectiveCourseTable"
      :sortable="true"
    >
      <template #no="{ idx }">
        {{ idx + 1 }}
      </template>
      <template #title="{ item }">
        {{ item.title }}
      </template>
      <template #term="{ item }">
        {{ item.term }}
      </template>
      <template #courseCodeHeader="{ item }">
        <div class="d-flex gap-3 flex-wrap">
          {{ $t(item.title) }}
          <input
            v-model="courseCodeInput"
            name="CommonCourseTableInputCode"
            class="form-control"
            type="text"
          >
        </div>
      </template>
      <template #action="{ item }">
        <pms-button
          :size="'sm'"
          :variant="'tonal'"
          @click="addNewRequest(item)"
        >
          {{ $t('phrase.add-new-request') }}
          <pms-kt-icon
            :icon-name="'arrow-right'"
            class="m-0 p-0 ms-2"
          />
        </pms-button>
      </template>
    </pms-smart-table>
  </div>
  <NewRequestForm
    v-if="modifier && modifier.name === 'new-request-content'"
    :requested-by-type="ERequestedBy.Program"
  />
</template>

<script setup lang="ts">
import { requestElectiveCourseTable } from '@domains/SectionManagement/features/NewRequest/values'
import { useNewRequest } from '@domains/SectionManagement/features/NewRequest/store'
import { computed, ref, watch } from 'vue'
import { debounce } from '@shared/utils/optimize'
import { useLoader, useTeleportModal } from '@shared/composable'
import NewRequestForm from '@domains/SectionManagement/features/NewRequest/ui/NewRequestForm/NewRequestForm.vue'
import { ERequestedBy } from '../enums'
import SectionManagementNewRequestCoreCoursesGetResponse from '../store/api/types/SectionManagementNewRequestCoreCoursesGetResponse'

const { isActive } = useLoader()
const { open, modifier, setModifier } = useTeleportModal()
const loader = isActive('academic/manage/sections/courses')

const newRequestStore = useNewRequest()
const courseCodeInput = ref('')
const filteredCourses = ref<SectionManagementNewRequestCoreCoursesGetResponse | undefined>([])

const courses = computed(() => {
  if (courseCodeInput.value && (filteredCourses.value && filteredCourses.value.length > 0)) {
    return filteredCourses.value
  }
  return newRequestStore.elective_courses
})

watch(() => courseCodeInput.value, debounce(filterCourses))

function filterCourses () {
  filteredCourses.value = newRequestStore.requestCourses.filter((course) => course.course_code.includes(courseCodeInput.value))
}

async function addNewRequest (item: SectionManagementNewRequestCoreCoursesGetResponse[0]) {
  setModifier('new-request-content',
    { equicode: item.equicode, courseCode: item.course_code, courseYear: item.course_year },
    { title: 'new-request-title1', description: 'new-request-description' }
  )
  open({ name: 'new-request-modal' })
}
</script>
