import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type {
  SectionManagementRequestPostRequest,
  UnknownResponse
} from '@shared/swagger'
import SectionManagementReceivedRequestRejectRequestPutRequest from '@/domains/SectionManagement/features/ReceivedRequests/store/api/types/SectionManagementReceivedRequestRejectRequestPutRequest'

export default async function putSectionManagementRequest (id: number, params: SectionManagementReceivedRequestRejectRequestPutRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { put } = useRequests()

  const response = await put<UnknownResponse, UnknownResponse>({
    url: `academic/manage/sections/received/requests/${id}`,
    // academic/manage/sections/received/requests/{request}
    body: params,
    loader: 'put-academic/manage/sections/received/requests/{request}'
  })

  return response
}
