import { useRequests } from '@shared/composable'
import type { AxiosResponse } from '@shared/types'
import type { UnknownRequest } from '@shared/swagger'
import { AcademicSingleStudentInformationGetResponse } from './types'

export default async function getAcademicSingleStudentInformation (id: string): Promise<AxiosResponse<AcademicSingleStudentInformationGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, AcademicSingleStudentInformationGetResponse>({
    url: `academic/students/${id}/information`,
    body: {},
    loader: 'academic/students/information'
  })

  return response
}
