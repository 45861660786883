<template>
  <Teleport to="#new-request-modal-content">
    <pms-row>
      <pms-grid :col="props.requestedByType === ERequestedBy.Program ? 5 : 12">
        <pms-smart-form
          :fields="[formFields]"
          @handleChange="onChange"
        >
          <template #formControls>
            <div class="text-end mt-4" />
          </template>
        </pms-smart-form>
        <div class="d-flex gap-3">
          <pms-button
            :color="'light'"
            :text="'common.cancel'"
            data-bs-dismiss="modal"
            @click="closeModal()"
          />
          <pms-button
            :color="'primary'"
            :text="'common.send'"
            data-bs-dismiss="modal"
            @click="onSubmit()"
          />
        </div>
      </pms-grid>
      <pms-grid :col="7">
        <NewRequestFormCourseStatistics
          v-if="props.requestedByType === ERequestedBy.Program"
          :equicode="statistics.equicode"
        />
      </pms-grid>
    </pms-row>
  </Teleport>
</template>

<script setup lang="ts">
import NewRequestFormCourseStatistics from './ui/NewRequestFormCourseStatistics/NewRequestFormCourseStatistics.vue'
import { computed, watch, ref, Ref, onMounted, PropType } from 'vue'
import type { FormField } from '@shared/types'
import { useAlert, useTeleportModal } from '@shared/composable'
import { objectDeepCopy } from '@shared/utils'
import { useI18n } from 'vue-i18n'
import { ReferencesSelects, UserNewRequest } from '@domains/SectionManagement/features/NewRequest/types'
import { refsToRequestedCourseOptions } from '@domains/SectionManagement/features/NewRequest/utils'
import { useNewRequest } from '@domains/SectionManagement/features/NewRequest/store'
import { TNewRequestFormModalData } from './types'
import SectionManagementCourseSectionStatisticsGetRequest from '../../store/api/types/SectionManagementCourseSectionStatisticsGetRequest'
import { storeToRefs } from 'pinia'
import { ERequestedBy } from '../../enums'

const { t } = useI18n()
const { error, success } = useAlert()
const { close, show, modifier, unsetModifier } = useTeleportModal()
const modalData = (modifier.value?.data as TNewRequestFormModalData)
const newRequestStore = useNewRequest()
const { selectedProgram } = storeToRefs(newRequestStore)

const props = defineProps({
  requestedByType: {
    type: String as PropType<ERequestedBy>,
    required: true
  }
})
const statistics = ref({
  equicode: 0
})

onMounted(async () => {
  const modalData = modifier.value?.data as TNewRequestFormModalData

  const data: Partial<SectionManagementCourseSectionStatisticsGetRequest> = {}
  if (props.requestedByType === 'Program') {
    data.program_code = parseInt(selectedProgram.value?.code + '') || -1
    data.equicode = statistics.value.equicode
  }

  const params : SectionManagementCourseSectionStatisticsGetRequest = {
    course_code: modalData.courseCode,
    course_year: modalData.courseYear,
    section_request_id: modalData.sectionRequestId,
    ...data
  }

  await newRequestStore.fetchCourseStatistics(params)
})

const formFields = computed<Array<FormField>>(() => [
  {
    group: {
      class: 'fv-row mb-4 fv-plugins-icon-container'
    },
    label: {
      text: `${t('domains.academic.manage.sections.requested-course!!!')}`,
      class: 'fs-6 fw-semibold mb-2 required'
    },
    name: 'requested_course',
    type: 'select',
    value: tempValues.value.requested_course || null,
    options: [],
    complexOption: refsToRequestedCourseOptions(references.value)
  },
  {
    group: {
      class: 'fv-row mb-4 fv-plugins-icon-container'
    },
    label: {
      text: `${t('domains.academic.manage.sections.requested-course-quota')}:`,
      class: 'fs-6 fw-semibold mb-2 required'
    },
    name: 'quota',
    type: 'number',
    value: tempValues.value.quota,
    validator: {
      schema: 'yup',
      rules: [
        'string',
        'required',
        { func: 'label', value: t('domains.academic.manage.sections.error.requested-course-quota') },
        'trim'
      ]
    }
  },
  {
    group: {
      class: 'fv-row mb-4 fv-plugins-icon-container'
    },
    label: {
      text: `${t('domains.academic.manage.sections.requested-course-note')}`,
      class: 'fs-6 fw-semibold mb-2'
    },
    name: 'note',
    type: 'textarea',
    value: tempValues.value.note || ''
  }
])

const defaultValues = {
  cirriculum_id: -1,
  requested_course: null,
  quota: null,
  note: ''
}

watch(() => newRequestStore.requestedCourseStatisticResponse, () => {
  if (!newRequestStore.requestedCourseStatisticResponse) return

  if (newRequestStore.requestedCourseStatisticResponse.request_note) {
    tempValues.value.note = newRequestStore.requestedCourseStatisticResponse.request_note
  }

  if (newRequestStore.requestedCourseStatisticResponse.quota_count) {
    tempValues.value.quota = newRequestStore.requestedCourseStatisticResponse.quota_count
  }

  references.value = newRequestStore.requestedCourseStatisticResponse.requested_courses.map((course) => {
    return {
      course_code: course.code,
      course_title: course.title
    }
  })
})

const tempValues : Ref<UserNewRequest> = ref(objectDeepCopy(defaultValues))
const references : Ref<ReferencesSelects[]> = ref([])

const onChange = ({ name, newValue }) => {
  if (name === 'requested_course') {
    tempValues.value[name] = JSON.parse(newValue)
  } else tempValues.value[name] = newValue
}

const onSubmit = async () => {
  const { status, message } = await newRequestStore.sendNewRequest({
    request_section_id: modalData.sectionRequestId,
    course_code: tempValues.value.requested_course?.course_code || '', // program - requests reference or main
    course_year: modalData.courseYear,
    program_year: newRequestStore.program_request_year || 0, // program - year - search - selector (-1 used for search/ suggest)
    program_code: newRequestStore.selectedProgram?.code || '', // program - selected
    curriculum_id: modalData.curriculum_id, // get COURSES id references the same
    quota: Number(tempValues.value.quota) || -1, // error
    note: tempValues.value.note, // пустой стринг бола алады
    period_number: newRequestStore.program_request_term || 0 // term - search - selector (-1 used for suggest/search)
  })
  if (status) {
    success({ text: message, isToast: true })
  } else {
    error({ text: message })
  }
}

const closeModal = () => {
  close({ name: 'addresses-modal' })
  unsetModifier()
}

watch(
  () => modifier.value,
  (newValue) => {
    if (newValue && newValue.data && newValue.data.equicode && newValue.data.courseCode) {
      statistics.value = {
        equicode: newValue.data.equicode as number
      }
      tempValues.value = objectDeepCopy(defaultValues)
      tempValues.value.cirriculum_id = newValue.data.id as number
      show()
    }
  }, { immediate: true }
)
</script>
