import { AcademicSingleStudentCurriculumGetResponse } from '@/domains/AcademicSingleStudent/store/api/methods/getAcademicSingleStudentCurriculum/types'

export default (
  term: number,
  courses: AcademicSingleStudentCurriculumGetResponse[number]['groups']
): AcademicSingleStudentCurriculumGetResponse[number]['groups'][0]['items'] => {
  if (term === -1) {
    const allItems = courses.map(course => course.items).flat()
    // Sort items by course_code
    return allItems.sort((a, b) => a.course_code.localeCompare(b.course_code))
  }

  const matchedCourse = courses.find(course => course.term === term)
  if (!matchedCourse) return []

  const items = matchedCourse.items
  // Sort items by course_code
  return items.sort((a, b) => a.course_code.localeCompare(b.course_code))
}
