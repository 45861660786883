<template>
  <Card>
    <template #title>
      <h3 class="card-title">
        {{ t('common.table.program') }}: {{ program.title }}
      </h3>
    </template>
    <template
      #content
    >
      <div class="row">
        <div class="col-12 col-md-6">
          <h5>{{ t('common.table.faculty') }}: <span class="text-primary">{{ program.faculty }}</span></h5>
        </div>
        <div class="col-12 col-md-6">
          <h5>{{ t('domains.academic.single-course.overview.language') }}: <span class="text-primary">{{ program.language }}</span></h5>
        </div>
        <div class="col-12 col-md-6">
          <h5>{{ t('common.table.speciality') }}: <span class="text-primary">{{ program.speciality_title }}</span></h5>
        </div>
        <div class="col-12 col-md-6">
          <h5>{{ t('common.table.graduation-date') }}: <span class="text-primary">{{ program.graduation_date || '—' }}</span></h5>
        </div>
      </div>
    </template>
  </Card>
</template>

<script setup lang="ts">
import { AcademicSingleStudentTranscriptGetResponse } from '@/domains/AcademicSingleStudent/store/api/methods/getAcademicSingleStudentTranscript/types'
import { PropType } from 'vue'
import Card from 'primevue/card'
import { useI18n } from 'vue-i18n'

defineProps({
  program: {
    type: Object as PropType<AcademicSingleStudentTranscriptGetResponse['programs'][number]>,
    required: true
  }
})

const { t } = useI18n()
</script>

<style scoped>
h5 {
  font-weight: 600;
}
.text-primary {
  color: #007bff; /* Основной акцентный цвет */
}
</style>
