<template>
  <pms-row>
    <pms-grid id="language-switch">
      <template
        v-for="(view ,index) in views"
        :key="index"
      >
        <pms-button
          class="d-flex flex-stack cursor-pointer text-left ps-3 mb-3 w-100"
          :variant="'outlined-dashed'"
          :class="{'bg-body border-primary active': store.view === view.id}"
          @click="store.view = view.id"
        >
          <span class="d-flex align-items-center me-2 active">
            <span
              class="d-flex flex-rows symbol symbol-50px me-3"
            >
              <span
                class="symbol-label"
                :class="{'bg-secondary ': store.view !== view.id, 'bg-primary': store.view === view.id}"
              >
                <span
                  class="svg-icon svg-icon-1 m-0"
                  :class="{'text-black-50': store.view !== view.id, 'text-primary ': store.view === view.id}"
                >
                  <span
                    class="svg-icon svg-icon-1 m-0 text-dark"
                  >
                    <inline-svg :src="view.image" />
                  </span>
                </span>
              </span>
              <span class="d-flex flex-column align-items-start ms-4 mt-1">
                <span
                  class="fw-bold fs-4"
                  :class="{ 'text-dark': store.view !== view.id }"
                >{{ $t(view.title) }}</span><span class="fs-7 text-muted">{{ $t(view.description) }}</span>
              </span>
            </span>
            <span class="form-check form-check-custom form-check-solid">
              <input
                v-model="view.id"
                style="margin-left: -20px; display: none;"
                class="form-check-input"
                type="radio"
                name="category"
                :value="store.view"
              >
            </span>
          </span>
          <span class="form-check form-check-custom form-check-solid">
            <input
              v-model="store.view"
              style="margin-left: -20px;"
              class="form-check-input"
              type="radio"
              name="category"
              :value="view.id"
            >
          </span>
        </pms-button>
      </template>
    </pms-grid>
    <pms-grid
      id="search-form"
      :col="18"
    >
      <ApplicantsSearchForm
        v-if="store.view === 'applicant'"
      />
      <StudentSearchForm
        v-if="store.view === 'student'"
      />
    </pms-grid>
  </pms-row>
</template>
<script setup lang="ts">
import { useAllStudents } from './../store'
import ApplicantsSearchForm from './ApplicantsView/ApplicantsSearchForm.vue'
import StudentSearchForm from './StudentsView/StudentSearchForm.vue'
import { views } from '../values'

const store = useAllStudents()
</script>
