import type { StoreType } from '../types'
import type { StatusMessageResponse } from '@shared/types'
import { putSectionManagementOffer } from '@shared/api'
import SectionManagementEditOfferPutRequest from '../api/types/SectionManagementEditOfferPutRequest'

export default async function editCourseOffer (this: StoreType, id: number, params: SectionManagementEditOfferPutRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await putSectionManagementOffer(id, params)

  return {
    status: ok,
    message
  }
}
