<template>
  <div :style="'color: ' + getCourseColor(course.details)">
    <slot />
  </div>
</template>

<script setup lang="ts">
import { AcademicSingleStudentCurriculumGetResponse } from '@/domains/AcademicSingleStudent/store/api/methods/getAcademicSingleStudentCurriculum/types'
import { getCourseColor } from '../utils'

interface IColoredTextProps {
    course: (AcademicSingleStudentCurriculumGetResponse[number]['groups'][0])['items'][0]
}

const { course } = defineProps<IColoredTextProps>()
</script>
