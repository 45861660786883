import { useRequests } from '@shared/composable'
import type { AxiosResponse } from '@shared/types'
import type {
  UnknownRequest
} from '@shared/swagger'
import SectionManagementOffersGetRequest from '@/domains/SectionManagement/features/MyCourses/store/api/types/SectionManagementOffersGetRequest'
import SectionManagementOffersGetResponse from '@/domains/SectionManagement/features/MyCourses/store/api/types/SectionManagementOffersGetResponse'

/**
 * Api path /1.0.0/academic/manage/sections/offers
 */
export default async function getSectionManagementMyCourses (params: SectionManagementOffersGetRequest): Promise<AxiosResponse<SectionManagementOffersGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, SectionManagementOffersGetResponse>({
    url: 'academic/manage/sections/offers',
    body: { params: params },
    loader: 'get-academic/manage/sections/offers'
  })
  return response
}
