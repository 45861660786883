<template>
  <vue-resizable
    id="rules-and-regulations-widget"
    class="resizable-widget card"
    :width="widgetSize.width"
    :height="widgetSize.height"
    min-height="500"
    min-width="1000"
    @resize:end="(event) => onResize(event, widgetId)"
  >
    <div
      id="rules-and-regulations-widget"
      class="card-header border-0 pt-5"
    >
      <h3 class="card-title align-items-start flex-column">
        <span
          v-test-id="'title'"
          class="card-label fw-bolder fs-3 mb-1"
        >
          {{ $t('domains.dashboard.widgets.rules-and-regulations.title') }}
        </span>
        <span
          v-test-id="'description'"
          class="text-muted mt-1 fw-bold fs-7"
        > {{ $t('domains.dashboard.widgets.rules-and-regulations.description') }}
        </span>
      </h3>
      <div
        v-if="false"
        class="card-toolbar"
      >
        <span
          v-test-id="'view-all'"
          class="btn btn-sm btn-light-primary cursor-pointer"
        >
          {{ $t('domains.dashboard.widgets.rules-and-regulations.open-full-page') }}
        </span>
      </div>
    </div>
    <div class="card-body py-3">
      <pms-smart-table
        :items="rules"
        :headers="headers"
      >
        <template #title="{ row }">
          <div
            v-styler="{ 'padding-left': '10px'}"
            class="d-flex align-items-center ml-4"
          >
            <div class="d-flex justify-content-start flex-column">
              <div
                v-test-id="'tr-title'"
                class="text-dark fw-bolder mb-1fs-6"
              >
                {{ row.title }}
              </div>
              <span
                v-if="row.description"
                v-test-id="'tr-description'"
                class="text-muted fw-bold text-muted d-block fs-7"
              >
                {{ row.description.length > descriptionLength ? row.description.slice(0, descriptionLength) + ' ...' : row.description }}
              </span>
            </div>
          </div>
        </template>
        <template #categoryName="{ item }">
          <div
            class="text-dark fw-bolder fs-6 mt-1"
          >
            {{ item.categoryName }}
          </div>
        </template>
        <template #attachments="{ item }">
          <a
            v-for="(att, aindex) in item.attachments"
            :key="aindex"
            v-styler="{ 'margin-right': '8px' }"
            :href="`${ att.path }`"
            target="_blank"
            class="btn btn-link pt-0"
          >
            <span
              class="badge badge-light-primary fw-bolder my-2 ml-2"
            >
              <span class="svg-icon svg-icon-sm svg-icon-primary">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    opacity="0.3"
                    d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22Z"
                    fill="currentColor"
                  />
                  <path
                    d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z"
                    fill="currentColor"
                  />
                </svg>
              </span>
              {{ att.language }}
            </span>
          </a>
        </template>
        <template #action="{ row }">
          <router-link
            v-test-id="'redirect-link'"
            :to="{
              path: redirectLink,
              params: {
                id: row.uuid
              }
            }"
            class="btn btn-sm btn-light-primary d-inline-flex align-items-center"
          >
            {{ $t('common.open') }}
            <pms-kt-icon
              :icon-name="'arrow-right'"
              class="ms-2 p-0 fs-3"
            />
          </router-link>
        </template>
      </pms-smart-table>
    </div>
  </vue-resizable>
</template>
<script lang="ts" setup>
import { computed, ref, onMounted } from 'vue'
import VueResizable from 'vue-resizable'
import { useDashboard } from '../store'
import { rulesAndRegulationsTable as headers } from '../values'
import { onResize, getSavedWidgetSize } from '../utils'

const store = useDashboard()

const rules = computed(() => store.getRulesAndRegulations)
const redirectLink = '/administrative/rules-and-regulations'
const descriptionLength = 111

const widgetId = 'rules-and-requlations-widget'

const widgetSize = ref({ width: '100%', height: 'auto' })

onMounted(() => {
  const savedSize = getSavedWidgetSize(widgetId)
  if (savedSize) {
    widgetSize.value.width = savedSize.width
    widgetSize.value.height = savedSize.height
  }
})
</script>
