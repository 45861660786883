<template>
  <ProgramInformation :program="program" />
  <ProgramPeriodCourses
    v-for="(courses, key) in groupedCourses"
    :key="key"
    :courses="courses.courses"
    :period="key"
    :gpa="courses.gpa"
    class="mt-3"
  />
</template>
<script setup lang="ts">
import { AcademicSingleStudentTranscriptGetResponse } from '@/domains/AcademicSingleStudent/store/api/methods/getAcademicSingleStudentTranscript/types'
import ProgramInformation from './ui/ProgramInformation/ProgramInformation.vue'
import ProgramPeriodCourses from './ui/ProgramPeriodCourses/ProgramPeriodCourses.vue'
import { computed, PropType } from 'vue'

const props = defineProps({
  program: {
    type: Object as PropType<AcademicSingleStudentTranscriptGetResponse['programs'][number]>,
    required: true
  }
})

type groupedCourse = {
  courses: AcademicSingleStudentTranscriptGetResponse['programs'][number]['courses'],
  gpa: AcademicSingleStudentTranscriptGetResponse['programs'][number]['gpa'][number] | undefined
}

const groupedCourses = computed(() => {
  const result = props.program.courses.reduce((acc, course) => {
    const key = `${course.year} - ${course.year + 1}. ${course.term}`

    if (!acc[key]) {
      // Инициализируем объект с полями `courses` и `gpa`
      acc[key] = {
        courses: [] as unknown as AcademicSingleStudentTranscriptGetResponse['programs'][number]['courses'],
        gpa: undefined
      }
    }

    // Добавляем курс в массив `courses`
    acc[key].courses.push(course)

    return acc
  }, {} as Record<string, groupedCourse>)

  // Заполняем поле `gpa` для каждого ключа
  props.program.gpa.forEach(gpaEntry => {
    const key = `${gpaEntry.year} - ${gpaEntry.year + 1}. ${gpaEntry.term}`
    if (result[key]) {
      result[key].gpa = gpaEntry
    }
  })

  return result
})
</script>
