import { useResource } from '@/shared/stores'
import type { StoreType } from '../types'
import type{ ResourceResponse } from '@shared/swagger'

export default async function setCurrentYearTerm (this: StoreType, yearTerm: ResourceResponse['year_terms'][0]): Promise<void> {
  const resource = useResource()

  await Promise.all([
    this.currentYearTerm = yearTerm,
    resource.fetchSectionManagementResource(
      {
        modules: [
          'departments',
          'programs',
          'request-statuses',
          'attendance-types',
          'payment-methods',
          'section-types',
          'implementation-modes',
          'instructors',
          'delivery-modes'
        ],
        year: yearTerm.year,
        term: yearTerm.term
      }
    )
  ])
}
