<template>
  <Teleport to="#received-requests-content">
    <div class="d-flex flex-column gap-3 mb-5 w-50">
      <label class="required fs-5">
        {{ $t('domains.section-management.received-requests.program.title') }}
      </label>
      <treeselect
        id="select-department"
        v-model="selectedProgramCode"
        :disable-branch-nodes="true"
        :options="grouped_programs"
        @change="setProgramCode($event)"
      />
    </div>
    <pms-row>
      <template
        v-for="common in commons"
        :key="common.type"
      >
        <pms-grid :col="4">
          <p v-if="initiateType(common.type)">
            {{ $t(`common.section-type.${initiateType(common.type)}`) }}
          </p>
          <pms-smart-table
            :items="common.sections"
            :headers="sectionTable"
            :class="'mt-4'"
          >
            <template #checkbox="{ item }">
              <pms-checkbox
                class="d-flex justify-content-center"
                :state="isSelected(item)"
                @on-check="handleSelect(item)"
                @on-uncheck="handleUnselect(item)"
              />
            </template>
            <template #section="{ item }">
              <pms-badge
                v-if="item.name"
                :value="item.name"
              />
            </template>
            <template #teacher="{ item }">
              {{ item.instructor?.full_name }}
            </template>
            <template #note="{ item }">
              {{ item.note }}
            </template>
          </pms-smart-table>
        </pms-grid>
      </template>
    </pms-row>
    <div class="form-group d-flex">
      <input
        v-model="quotas"
        type="number"
        class="form-control w-150px"
        :placeholder="$t('phrase.enter-quotas')"
      >
      <pms-button
        class="ms-4"
        :text="'common.submit'"
        type="submit"
        @click="onSubmit"
      />
    </div>
  </teleport>
</template>
<script setup lang="ts">
import { watch, ref, shallowRef, computed } from 'vue'
import Treeselect from 'vue3-treeselect'

import { currentCourse, sectionTable } from '@domains/SectionManagement/features/ReceivedRequests/values'
import { useReceivedRequest } from '@domains/SectionManagement/features/ReceivedRequests/store'
import { useAlert, useTeleportModal } from '@shared/composable'
import { SectionManagementResourceGetResponse, SectionManagementSectionsGetResponse } from '@shared/swagger'
import { useResource } from '@shared/stores'
import { TreeType } from '@/shared/types'
import { useSectionManagement } from '@/domains/SectionManagement/store'
import { storeToRefs } from 'pinia'

const { show, modifier, close } = useTeleportModal()
const receivedRequestStore = useReceivedRequest()
const sectionManagementStore = useSectionManagement()
const resource = useResource()
const { success, error } = useAlert()

const { currentYearTerm } = storeToRefs(sectionManagementStore)
const theories = computed(() => receivedRequestStore.getRequestsTheorySections)
const labs = computed(() => receivedRequestStore.getRequestsLaboratorySections)
const practices = computed(() => receivedRequestStore.getRequestsPracticeSections)
const programs = computed(() => resource.getSectionManagementResourcePrograms)
const grouped_programs = ref<TreeType>([])

function setEmployees () {
  const departments:Record<string, {items:SectionManagementResourceGetResponse['programs']}> = {}
  programs.value.forEach(program => {
    if (departments[program.department.code]) {
      departments[program.department.code].items.push(program)
    } else {
      departments[program.department.code] = {
        items: [program]
      }
    }
  })
  for (const key in departments) {
    grouped_programs.value.push({
      label: key,
      id: key,
      children: departments[key].items.map(program => ({
        label: `${program.code} - ${program.language.code} - [${program.cipher}]: ${program.title} [${program.terms_count}]`,
        id: program.code
      }))
    })
  }
}

const quotas = shallowRef<number | null>(null)
const selectedProgramCode = shallowRef<string | null>(null)

const commons = [
  { type: 'N', sections: theories.value },
  { type: 'P', sections: practices.value },
  { type: 'L', sections: labs.value }
]

function initiateType (type: string) {
  switch (type) {
    case 'N':
      return 'theory'
    case 'L':
      return 'laboratory'
    case 'P':
      return 'practice'
    default:
      return 'theory'
  }
}

function setProgramCode ($event) {
  selectedProgramCode.value = $event.target.value
}

const selectedSections = ref<Record<string, SectionManagementSectionsGetResponse[0] | null>>(
  {
    N: null,
    L: null,
    P: null
  }
)

const handleSelect = (section) => {
  selectedSections.value[section.type.id] = section
}

const handleUnselect = (section) => {
  selectedSections.value[section.type.id] = null
}

const isSelected = (section) => {
  return selectedSections.value[section.type.id]?.id === section.id
}

async function onSubmit () {
  if (!selectedProgramCode.value) {
    error({
      text: 'Program is required'
    })
    return
  }// add error for submit or disable
  if (!selectedSections.value.N?.id) {
    error({
      text: 'Theory field is required'
    })
    return
  } // add error for submit
  if (typeof quotas.value !== 'number') {
    error({
      text: 'Quotas must be a nubmer'
    })
    return
  }

  const { status, message } = await receivedRequestStore.createReceivedRequestReservations({
    program_code: selectedProgramCode.value,
    theory_section_id: selectedSections.value.N?.id,
    lab_section_id: selectedSections.value.L?.id || undefined,
    practice_section_id: selectedSections.value.P?.id || undefined,
    quota: quotas.value
  })

  if (status) {
    if (currentCourse.value.course_code) {
      receivedRequestStore.fetchReceivedRequests({
        course_code: currentCourse.value.course_code,
        year: currentYearTerm.value.year,
        term: currentYearTerm.value.term
      })
    }

    close({ name: 'received-requests-modal' })
    success({ text: message, isToast: true })
  } else {
    error({ text: message })
  }
}

watch(
  () => modifier.value,
  (newValue) => {
    if (newValue && newValue.data) {
      setEmployees()
      show()
    }
  }, { immediate: true }
)
</script>
