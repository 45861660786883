import { useRequests } from '@/shared/composable'
import type {
  UnknownRequest
} from '@/shared/swagger'
import type { AxiosResponse } from '@shared/types'
import { ServiceSurveysGetResponse } from './types'

export default async function getServiceSurveys (): Promise<AxiosResponse<ServiceSurveysGetResponse>> {
  const { get } = useRequests()

  const response = get<UnknownRequest, ServiceSurveysGetResponse>({
    url: 'service/questionaries',
    body: { },
    loader: 'get-service/questionaries'
  })

  return response
}
