<template>
  <pms-card
    :header="false"
  >
    <div class="d-flex flex-column">
      <p class="mb-6 fs-5">
        {{ $t('domains.academic.manage.section.sent-requests-description') }}
      </p>

      <div class="d-flex flex-row">
        <pms-smart-form
          :fields="formFields"
          class="w-100"
          @handleSubmit="handleSubmit"
        >
          <template #formControls>
            <div class="mt-3 d-flex justify-content-end">
              <pms-button
                type="submit"
                class="mt-2"

                :text="$t('common.search')"
              />
              <!-- :disabled="loader"
                :loader="searchLoader" -->
            </div>
          </template>
          <template #program="{ setValue }">
            <div class="d-flex flex-column">
              <span class="mb-2 mt-1"> {{ t('common.search-field.select-speciality') }}:</span>
              <Select
                v-model="selectedProgram"
                filter
                :options="groupedPrograms"
                option-label="label"
                option-group-label="label"
                option-group-children="items"
                @select="(value)=> setValue(value.id)"
              >
                <template #optiongroup="slotProps">
                  <div class="flex items-center">
                    <div> {{ slotProps.option.label }}</div>
                  </div>
                </template>
              </Select>
            </div>
          </template>

          <!--Status select  -->
          <template #status>
            <div class="d-flex flex-column">
              <span class="mb-2 mt-1"> {{ t('common.select-status') }}:</span>
              <Select
                v-model="store.status"
                option-label="title"
                option-value="id"
                :options="statuses"
              />
            </div>
          </template>
        </pms-smart-form>
      </div>
    </div>
  </pms-card>
</template>
<script setup lang="ts">
import { onMounted, ref, watch } from 'vue'
import { SectionManagementRequestGetRequest } from '@/shared/swagger'
import { objectDeepCopy } from '@/shared/utils'
import { useRoute } from 'vue-router'
import { sentRequest } from '../../values'
import Select from 'primevue/select'
import { useI18n } from 'vue-i18n'
import { useSentRequests } from '../../store'
import useFormDataGenerator from './composable/useFormDataGenerator'
import { useSectionManagement } from '@/domains/SectionManagement/store'

const { t } = useI18n()
const store = useSentRequests()
const sectionManagementStore = useSectionManagement()
const { query } = useRoute()
const { formFields, statuses, onSubmit, groupedPrograms, defaultOption } = useFormDataGenerator()

const handleSubmit = () => {
  onSubmit({
    year: sectionManagementStore.currentYearTerm.year,
    status: store.status,
    program_code: selectedProgram.value?.value ?? undefined,
    term: sectionManagementStore.currentYearTerm.term
  })
}

const defaultSentRequest: SectionManagementRequestGetRequest = {
  type: 'sent',
  year: parseInt(query.year + '') ? parseInt(query.year + '') : undefined,
  term: parseInt(query.term + '') ? parseInt(query.term + '') : undefined
}

const selectedProgram = ref<{value: string | null, label: string} | null>(defaultOption.value)

watch(selectedProgram, () => {
  if (selectedProgram.value?.value) {
    store.setCurrentProgram(selectedProgram.value.label)
  } else { store.setCurrentProgram('common.all-programs') }
})

onMounted(() => {
  sentRequest.value = objectDeepCopy(defaultSentRequest)
  selectedProgram.value = groupedPrograms.value[0].items[0][0]
  store.setCurrentProgram(selectedProgram.value ? selectedProgram.value.label : '')
})
</script>
