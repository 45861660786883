<template>
  <div>
    <div
      :key="'information.requisites'"
      class="fs-2 fw-bold text-danger text-center mb-6 mt-10"
    >
      {{ getMessage('information.requisites') }}
    </div>
  </div>

  <pms-smart-table
    :headers="requisitesTable"
    :items="requisites"
    :sortable="true"
    :options="{ design: 'bordered-dashed' }"
  />
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { getMessage } from '../utils'

import { requisitesTable } from '../values'
import { useStore } from '../features/EditorSyllabus/store'

const { requisites } = storeToRefs(useStore())
</script>
