import { StatusMessageResponse } from '@/shared/types'
import type { StoreType } from '../types'
import { ServiceQuestionaryDepartmentInstructorSectionsGetRequest } from '../api/methods/getSections/types'
import getSections from '../api/methods/getSections'

export default async function fetchSections (this: StoreType, surveyId: number, params: ServiceQuestionaryDepartmentInstructorSectionsGetRequest): Promise<StatusMessageResponse> {
  const { ok, data, message } = await getSections(surveyId, params)

  if (ok) {
    this.sections = data
  }

  return {
    status: ok,
    message
  }
}
