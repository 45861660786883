import getSectionManagementSentRequestQuotas from '@/shared/api/SectionManagement/getSectionManagementSentRequestQuotas'
import type { StoreType } from '../types'

export default async function fetchSentRequestQuotas (this: StoreType, id: number): Promise<void> {
  const { data, ok } = await getSectionManagementSentRequestQuotas({ request_id: id })

  if (ok) {
    this.setSentRequestQuotas(data)
  } else {
    this.setSentRequestQuotas([])
  }
}
