
import { useLoader } from '@/shared/composable'
import { useResource } from '@/shared/stores'
import { FormGroup } from '@/shared/types'
import { computed } from 'vue'
import { useMyCourses } from '@domains/SectionManagement/features/MyCourses/store'
import { useSectionManagement } from '@/domains/SectionManagement/store'
import HandleChangeData from '@/shared/ui/shared/form/types/handleChange'
import UseCoursesFetcher from '../UseCoursesFetcher/UseCoursesFetcher'

export default function UseMyCoursesFilter () {
  const store = useMyCourses()
  const sectionManagementStore = useSectionManagement()
  const { selectedDepartment, setSelectedDepartment } = useMyCourses()
  const { isActive } = useLoader()
  const { fetchCourses } = UseCoursesFetcher()

  const resource = useResource()

  const formFields = computed<Array<FormGroup>>(() => [
    {
      class: 'row',
      fields: [
        {
          name: 'department',
          type: 'select',
          required: true,
          value: selectedDepartment?.code,
          options: departmentOptions.value
        }
      ]
    }
  ])

  const departmentOptions = computed(() => resource.getSectionManagementResourceDepartments.map(i => {
    return { value: i.code, label: i.code }
  }))

  const onSubmit = ({ values }) => {
    fetchCourses({ departmentCode: values.department as string })
  }

  const onChange = (data: HandleChangeData) => {
    if (data.name === 'department' && typeof data.newValue === 'string') {
      const found = resource.getSectionManagementResourceDepartments.find(e => e.code === data.newValue)
      if (found) setSelectedDepartment(found)
      else setSelectedDepartment(null)
    }
  }

  return {
    selectedDepartment, formFields, isActive, onSubmit, onChange
  }
}
