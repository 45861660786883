import { useRequests } from '@shared/composable'
import type { AxiosResponse } from '@shared/types'
import { ForgotPasswordPostRequest, ForgotPasswordPostResponse } from './types'

export default async function forgotPassword (credentials: ForgotPasswordPostRequest): Promise<AxiosResponse<ForgotPasswordPostResponse>> {
  const { post } = useRequests()

  const response = post<ForgotPasswordPostRequest, ForgotPasswordPostResponse>({
    url: 'forgot-password',
    body: credentials,
    configs: { token: false },
    loader: 'forgot-password'
  })
  return response
}
