import type { StoreType } from '../types'
import type { AdministrativeGateEntryMyRequest } from '@shared/swagger'

import { postAdministrativeGateEntryMy } from '@shared/api'
import { useAlert } from '@shared/composable'

export default async function fetchMyRecords (this: StoreType, body: AdministrativeGateEntryMyRequest): Promise<void> {
  const { error } = useAlert()

  const { ok, message, data } = await postAdministrativeGateEntryMy(body)
  if (ok) {
    this.setRecords(data)
  } else {
    error({ text: message })
  }
}
