import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type {
  UnknownRequest
} from '@shared/swagger'
import SectionManagementSectionsReceivedRequestsQuotasReserveGetResponse from '@/domains/SectionManagement/features/ReceivedRequests/store/api/types/SectionManagementSectionsReceivedRequestsQuotasReserveGetResponse'
import SectionManagementSectionsReceivedRequestsQuotasReserveGetRequest from '@/domains/SectionManagement/features/ReceivedRequests/store/api/types/SectionManagementSectionsReceivedRequestsQuotasReserveGetRequest'

export default async function getSectionManagementSectionsQuotas (params: SectionManagementSectionsReceivedRequestsQuotasReserveGetRequest): Promise<AxiosResponse<SectionManagementSectionsReceivedRequestsQuotasReserveGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, SectionManagementSectionsReceivedRequestsQuotasReserveGetResponse>({
    url: 'academic/manage/sections/received/requests/quotas/reserve',
    body: { params },
    loader: 'get-academic/manage/sections/received/requests/quotas/reserve'
  })

  return response
}
